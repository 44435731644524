import React from 'react'
import styled from 'styled-components'

const InputComponent = styled.input`
  width: ${props => props.dw || '100%'};
  height: 50px;
  padding: 15px 24px;
  border-radius: 4px;
  border: solid 1px #dddddd;
  
  font-family: Montserrat;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 18px;
  letter-spacing: normal;
  text-align: left;
  color: #333333;
  
  :focus {
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.08);
    border: solid 2px #0069ff;
    opacity: 1;  
    outline: 0;
  }
  ::placeholder {
    opacity: 0.5;
  }
  
  @media (max-width: 934px) {
    width: ${props => props.mw || '100%'};
    height: 60px;
    padding: 20px 21px;
  }
`

export default InputComponent;


import React, {useEffect, useState} from "react";
import Modal from './Modal';
import Languages from "../../../values/Languages";
import {Col, FormGroup, Label} from "reactstrap";
import {Input} from "@thumbtack/thumbprint-react";
import Radio from "../InterpreterProfile/components/Radio";
import axios from "axios";


export default (props) => {
  const [ form, setForm ] = useState({})

  useEffect(() => {
    setForm(props.industry)
  }, [])

  const onChange = (name, value) => {
    const next = {
      ...form,
      [name]: value
    }
    setForm(next)
  }

  const onFileSelected = event => {
    const file = event.target.files[0];

    const formData = new FormData();
    formData.append("file", file);

    axios.post(`/api/upload/INDUSTRY_ICON`, formData).then(res => {
      const url = res.data
      const next = { ...form, imgUrl: url }
      setForm(next)
    });
  }

  const onClickSave = () => {
    props.onClickSave(form)
  }

  return (
    <Modal
      renderChildren={(setRef) => (
        <>
          <div style={{ width: "100%" }}>
            <FormGroup row>
              <Label md={4}>분야 종류</Label>
              <Col md={8}>
                <select
                  name={"industryType"}
                  value={form.industryType}
                  onChange={(e) => onChange(e.target.name, e.target.value)}
                  className="tp-select tp-select--small"
                >
                  <option value="">---</option>
                  <option value="INTERPRET">통역</option>
                  <option value="TRANSLATE">번역</option>
                </select>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col md={4}>
                <Label>분야명</Label>
              </Col>
              <Col md={6}>
                <Input
                  type="text"
                  name="industryName"
                  value={form.industryName}
                  size="small"
                  onChange={(_, e) => onChange(e.target.name, e.target.value)}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col md={4}>
                <Label>분야KEY</Label>
              </Col>
              <Col md={6}>
                <Input
                  type="text"
                  name="industryKey"
                  value={form.industryKey}
                  size="small"
                  onChange={(_, e) => onChange(e.target.name, e.target.value)}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col md={4}>
                <Label>활성화여부</Label>
              </Col>
              <Col md={2}>
                <Radio
                  isChecked={form.status === 'ACTIVATED'}
                  onChange={_ => onChange("status", "ACTIVATED")}
                >
                  활성
                </Radio>
              </Col>
              <Col md={2}>
                <Radio
                  isChecked={form.status === 'DEACTIVATED'}
                  onChange={_ => onChange("status", "DEACTIVATED")}
                >
                  비활성
                </Radio>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col md={4}>
                <Label>노출순서</Label>
              </Col>
              <Col md={6}>
                <Input
                  type="text"
                  name="seq"
                  value={form.seq}
                  size="small"
                  onChange={(_, e) => onChange(e.target.name, e.target.value)}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col md={4}>
                <Label>아이콘</Label>
              </Col>
              <Col md={6}>
                { form.imgUrl && (
                  <img src={form.imgUrl} style={{ height: "30px" }}/>
                )}
                <input
                  // ref={setRef}
                  type="file"
                  onChange={onFileSelected}
                />
              </Col>
            </FormGroup>
          </div>
        </>
      )}
      {...props}
      onConfirm={() => onClickSave(form)}
    />
  )
};
import React from 'react';
import {observer} from "mobx-react";
import {Link} from "react-router-dom";
import moment from "moment";
import styled from 'styled-components'
import {Button, ButtonRow, Title} from '@thumbtack/thumbprint-react';
import {numberWithCommas} from "../../../utils";
import "./ProjectView.scss";
import ProjectApplyModal from "./ProjectApplyModal";
import LanguageCheckModal from "./LanguageCheckModal";
import LevelCheckModal from "./LevelCheckModal";
import CancelApplyModal from "./CancelApplyModal";
import {PROJECT_STATUS} from "../../../domains/Project";
import {Col, Row} from "reactstrap";
import '@toast-ui/editor/dist/toastui-editor.css';
import {Viewer} from '@toast-ui/react-editor';

const RowCol = styled.div`
  position: relative;
  display: flex;

  @media (max-width: 934px) {
    flex-direction: column;
  }
`

const Space = styled.div`
  width: ${props => props.dw || 0}px;
  height: ${props => props.dh || 0}px;
  
  @media (max-width: 768px) {
    width: ${props => props.mw || 0}px;
    height: ${props => props.mh || 0}px;
  }
`

const Btn = styled.button`
  width: ${props => props.dw || 0};
  height: 52px;
  border-radius: 4px;
  border: ${props => props.border || "solid 1px #0069ff"};
  background-color: ${props => props.backgroundColor || "#ffffff"};

  color: ${props => props.color || "#0069ff"};
  font-family: Noto Sans KR;
  font-size: 16px;
  font-weight: bold;

  @media (max-width: 934px) {
    width: ${props => props.mw || 0};
  }
`

const ShareBtn = styled.button`
  margin: 10px;
  border: none;
  border-radius: 5px;
  height: 35px;
  font-size: 18px;
  background-color: #F7E600;
  color: #3A1D1D;
  :hover{
      background-color: #99c6f5;
  }
`

const Btn2 = styled.button`
  width: ${props => props.dw || 0};
  height: 52px;
  border-radius: 4px;
  border: ${props => props.border || "solid 1px #ff1212"};
  background-color: ${props => props.backgroundColor || "#ffffff"};

  color: ${props => props.color || "#ff1212"};
  font-family: Noto Sans KR;
  font-size: 16px;
  font-weight: bold;

  @media (max-width: 934px) {
    width: ${props => props.mw || 0};
  }
`

@observer
class ProjectViewInterpret2 extends React.Component {
    
  componentDidMount() {   

  }

  render() {
    if (!this.props.projectViewStore.project)
      return null;

    const {project} = this.props.projectViewStore;
    const {
      idx, owner, companyName, companyDept, introduce, interpretLanguage,sourceLanguage, targetLanguage, serviceType,
      workSchedule,workPlace, price, matchingFee, taxRequire, address1, address2, address3, address4, translateAmount, translateType,interpreterLevel,
      industry, people, dueDate, dueTime, myApply, applicable, posterUrl, title, career, depositDate,
      depositTypeKorean, additionalFee, status, isEditableStatus, name, phone, email
    } = project;

    const schedules = project.schedules;
    const {member} = this.props.memberStore;

    const isMyProject = member && member.idx === owner;
    const amIInterpreter = member && member.type === "INTERPRETER";
    const applicantsExists = this.props.projectViewStore.project.applicantsCount > 0;
    const isIncuitNow = this.props.projectViewStore.project.status === "RECRUIT_INPROGRESS";
    const isWaiting = this.props.projectViewStore.project.status === PROJECT_STATUS.RECRUIT_WAITING;
    const isIncruitClosed = this.props.projectViewStore.project.status === "RECRUIT_CLOSED";
    const isInprogress = this.props.projectViewStore.project.status === "PROJECT_INPROGRESS";
    const isClosedRecruit = this.props.projectViewStore.project.status === PROJECT_STATUS.RECRUIT_CLOSED;
    const isRequesting = this.props.projectViewStore.project.status === "RECRUIT_REQUEST"  

    const shareKakao = () => {
      const url = window.location.href; //현재 url가져오기
      
      const kakaoShareImage = project.posterUrl;

      if (kakaoShareImage ===""){
        kakaoShareImage = 'https://wable-public.s3.ap-northeast-2.amazonaws.com/project-poster/default_thumbnail.jpg'
      } 

      //이부분이 매우 헷갈림 여러 사이트를 참고했는데 이 sendDefault부분을 잘 봐야한다.
          window.Kakao.Link.sendDefault({ 
            objectType: 'feed',
            content: {
              title: '와블에 등록된 프로젝트를 공유합니다.\n프로젝트명 : ' + project.title,
              description: '- 요율 : '+ numberWithCommas(parseInt(price*(1-matchingFee/100)))+'원\n- 접수마감 :'+moment(project.dueDate).format('YYYY-MM-DD') +'/'+ project.dueTime + '까지',
              imageUrl: kakaoShareImage,
              link: {
                mobileWebUrl: url,
                webUrl: url,
              },
            },            
            buttons: [
              {
                title: '자세히 보기',
                link: {
                  mobileWebUrl: url,
                  webUrl: url,
                },
              },              
            ],      
          });
        }   

    return (
      <div className="inner projectViewContainer">
        <div className="ProjectView contents-body">
          <Col>            
            <Col>
              { posterUrl && (
                <>
                  <Row style={{justifyContent: "center"}}>
                    <Col md={12}>
                      <img src={posterUrl} alt="" className="poster"/>
                    </Col>
                  </Row>
                  <Space dh={40} mh={40}></Space>
                </>
              )}

              <Row className="mb3">
                <Col sm={9} className="font-weight-bold">                  
                </Col>
                <Col sm={3} className="Kakao">
                  <a id="kakao-link-btn" href="javascript:;" onClick={shareKakao}>
                   <img src="https://wable-public.s3.ap-northeast-2.amazonaws.com/static/kakaosharebtn.png"/>
                  </a>
                </Col>
              </Row>

              <Col md={{size: 12}}>
              <div className="mt5 mb5">
                <Title size={3}>
                  {title}
                </Title>
              </div>
              </Col>            

              
              <Row className="mb3">
                <Col sm={3} className="font-weight-bold">
                  통역언어
                </Col>
                <Col sm={9}>
                  {project.interpretLanguage === 'ENGLISH' ? "영어" : project.interpretLanguage === 'CHINESE' ? "중국어" : project.interpretLanguage === 'JAPANESE' ? "일본어" : project.interpretLanguage}
                </Col>
                
              </Row>

              <Row className="mb3">
                <Col sm={3} className="font-weight-bold">
                  통역일정
                </Col>
                <Col sm={9}>
                  {project.workSchedule}
                </Col>
              </Row>

              <Row className="mb3">
                <Col sm={3} className="font-weight-bold">
                  통역장소
                </Col>
                <Col sm={9}>
                  {project.workPlace}
                </Col>
              </Row>

              <Row className="mb3">
                <Col sm={3} className="font-weight-bold">
                  통역인원
                </Col>
                <Col sm={9}>
                  {project.people}{"명"}
                </Col>
              </Row>

              { isMyProject && ( //내(클라이언트)가 만든 프로젝트이면
              <Row className="mb3">
                <Col sm={3} className="font-weight-bold">
                  프로젝트요율
                </Col>
                <Col sm={9}>
                  {numberWithCommas(parseInt(price))}
                  {price < 15000 ? translateType === "VIDEO" ? "원/분 (VAT별도)" : "원/단어 (VAT별도)" : "원 (VAT별도)"}
                </Col>
              </Row>
              )}
              {amIInterpreter && ( //전문가이면
              <Row className="mb3">
              <Col sm={3} className="font-weight-bold">
                기준요율
              </Col>
              <Col sm={9}>
                {numberWithCommas(parseInt(price*(1-matchingFee/100)/people))}
                {price < 15000 ? translateType === "VIDEO" ? "원/분 (3.3% 세전)" : "원/단어 (3.3% 세전)" : "원 (3.3% 세전)"}
              </Col>
              </Row>
              )}
              {amIInterpreter && ( //전문가이면
              <Row className="mb3">
              <Col sm={3} className="font-weight-bold">
                최저요율
              </Col>
              <Col sm={9}>
                {numberWithCommas(parseInt(price*(1-matchingFee/100)*0.8/people))}
                {price < 15000 ? translateType === "VIDEO" ? "원/분 (3.3% 세전)" : "원/단어 (3.3% 세전)" : "원 (3.3% 세전)"}
              </Col>
              </Row>
              )}
              
              <Row className="mb3">
                <Col sm={3} className="font-weight-bold">
                  지원자격
                </Col>
                <Col sm={9}>
                  {project.interpreterLevel === "EXPERT" ? "전문통역사" : "일반통역사"}
                </Col>
              </Row>

              <Row>
                <Col sm={12}>
                  <div>
                    <Viewer
                      height='600px'
                      initialValue={introduce}/>
                  </div>
                </Col>
              </Row>

              
              <Row className="mb3">
                <Col sm={3} className="font-weight-bold">
                  지원마감
                </Col>
                <Col sm={9}>
                {moment(project.dueDate).format('YYYY-MM-DD')} / {project.dueTime} 까지
                </Col>
              </Row>

              {amIInterpreter && (project.status === "RECRUIT_INPROGRESS") && myApply && (myApply.status === "APPLIED") && (
                      <>
                        <Row className="mb3">
                <Col sm={3} className="font-weight-bold">
                  내 지원요율
                </Col>
                <Col sm={9}>
                {numberWithCommas(parseInt(myApply.bidAmount))}
                {price < 15000 ? translateType === "VIDEO" ? "원/분 (3.3% 세전)" : "원/단어 (3.3% 세전)" : "원 (3.3% 세전)"}
                </Col>
              </Row>
                      </>
              )}                    

              

              <Space dh={40} mh={40}></Space>
              { isMyProject && (
                <Row>
                  {(isIncuitNow || isClosedRecruit ||isInprogress) && (
                    <Col>
                      <Link to={`/projects/${idx}/applicants`}>
                        <Btn dw={'130px'} mw={'100px'}>지원자 보기</Btn>
                      </Link>
                    </Col>
                  )}
                  {isEditableStatus && !applicantsExists && (
                    <RowCol style={{width: '100%', justifyContent: 'space-between'}}>
                      <Col>
                        <Btn onClick={this.props.admitRequest} dw={'130px'} mw={'100%'}>모집승인요청</Btn>
                      </Col>
                      <Space mh={10}/>
                      <Row style={{width: 'auto'}}>
                        <Col>
                          <Link to={`/projects/${idx}/edit`}>
                            <Btn dw={'92px'} mw={'100px'} backgroundColor={"#eeeeee"} color={"#999999"} border={"none"}>수정</Btn>
                          </Link>
                        </Col>
                        <Space dw={20}/>
                        <Col>
                          <Btn onClick={this.props.deleteProject} dw={'92px'} mw={'220px'} backgroundColor={"#29304A"} color={"#ffffff"} border={"none"}>삭제</Btn>
                        </Col>
                      </Row>
                    </RowCol>
                  )}
                  {isRequesting && !applicantsExists && (
                    <RowCol style={{width: '100%', justifyContent: 'space-between'}}>
                      <Col>
                        <Btn onClick={this.props.cancelRequest} dw={'150px'} mw={'100%'}>모집승인요청 취소</Btn>
                      </Col>
                      <Space mh={10}/>
                      <Col>
                        <Btn onClick={this.props.deleteProject} dw={'92px'} mw={'100%'} backgroundColor={"#29304A"} color={"#ffffff"} border={"none"}>삭제</Btn>
                      </Col>
                    </RowCol>
                  )}
                  <Space dw={20}></Space>
                  {isIncuitNow && (
                    <Col>
                      <Btn dw={'360px'} mw={'220px'} backgroundColor={"#29304A"} color={"#ffffff"} border={"none"} onClick={this.props.closeRecruit}>마감하기</Btn>
                    </Col>
                  )}
                  {isClosedRecruit && (
                    <Col>
                      <Btn dw={'360px'} mw={'220px'} backgroundColor={"#0069ff"} color={"#ffffff"} border={"none"} onClick={this.props.inprogressProject}>프로젝트 시작</Btn>
                    </Col>
                  )}                  
                  {isInprogress && (
                    <Col>
                      <Button size="small" width="full-below-small" onClick={this.props.completeProject} className="mb-2">
                        정상종료 확인
                      </Button>
                    </Col>
                  )}
                </Row>
              )}
              {amIInterpreter && (
                <Row>
                  <Col md={12}>
                  { myApply && (myApply.status === "APPLIED")&& (project.status==="RECRUIT_INPROGRESS")  && (
                      <>
                        <ButtonRow>
                          <Btn dw={'130px'} mw={'100px'}>지원됨</Btn>
                          <Space dw={20} mw={10}></Space>
                          <Btn onClick={() => this.props.toggleModal("cancelApply")} dw={'130px'} mw={'100px'} backgroundColor={"#29304A"} color={"#ffffff"} border={"none"}>지원 취소</Btn>
                        </ButtonRow>
                      </>
                    )}
                    {myApply && (myApply.status === "DENIED") && (
                      <>
                        <ButtonRow>
                          <Btn2 dw={'500px'} mw={'100%'}>고객사의 요청에 의해 채용이 취소되었습니다.</Btn2>
                        </ButtonRow>
                      </>
                    )}
                    { applicable && (
                      <Row>
                        <Link to={`/projects/recruits`}>
                          <Btn dw={'130px'} mw={'100px'}>목록으로</Btn>
                        </Link>
                        <Space dw={20} mw={10}></Space>
                        <Btn onClick={() => this.props.toggleModal("apply")} dw={'360px'} mw={'220px'} backgroundColor={"#29304A"} color={"#ffffff"} border={"none"}>업무 지원하기</Btn>
                      </Row>
                    )}
                    {myApply && (myApply.status === "APPLIED") && (project.status==="RECRUIT_CLOSED") && (
                      <>
                      <Row>
                        <Link to={`/projects/recruits`}>
                          <Btn dw={'130px'} mw={'100px'} backgroundColor={"#29304A"} color={"#ffffff"} border={"none"}>목록으로</Btn>
                        </Link>                        
                        <Space dw={20} mw={10}></Space>
                        <Btn dw={'500px'} mw={'100%'}>고객사에서 최종 진행자 확정 검토중입니다.</Btn>
                      </Row>                                    
                      </>
                    )}
                     {myApply && (myApply.status === "APPLIED") && (project.status==="PROJECT_INPROGRESS") && (
                      <>
                      <Row>
                        <Link to={`/projects/recruits`}>
                          <Btn dw={'130px'} mw={'100px'} backgroundColor={"#29304A"} color={"#ffffff"} border={"none"}>목록으로</Btn>
                        </Link>                        
                        <Space dw={20} mw={10}></Space>
                        <Btn2 dw={'500px'} mw={'100%'}>업무에 매칭되지 못하셨습니다.</Btn2>
                      </Row>                        
                      </>
                    )}
                  </Col>
                </Row>
              )}
            </Col>
          </Col>
        </div>
        <ProjectApplyModal
          isOpen={this.props.modal.apply.get()}
          toggle={() => this.props.toggleModal("apply")}
          onConfirm={this.props.applyProject}
          project={project}
          applyRequest={this.props.applyRequest}
        />
        <CancelApplyModal
          isOpen={this.props.modal.cancelApply.get()}
          toggle={() => this.props.toggleModal("cancelApply")}
          onConfirm={this.props.cancelApply}
          project={project}
          applyRequest={this.props.applyRequest}
        />
        <LanguageCheckModal
          isOpen={this.props.modal.languageCheck.get()}
          toggle={() => this.props.toggleModal("languageCheck")}
          project={project}
          applyRequest={this.props.applyRequest}
        />
        <LevelCheckModal
          isOpen={this.props.modal.levelCheck.get()}
          toggle={() => this.props.toggleModal("levelCheck")}
          project={project}
          applyRequest={this.props.applyRequest}
        />
        
        
        
      </div>
    )
  }

}


export default ProjectViewInterpret2;
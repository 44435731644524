import React from "react";
import "./ProjectApplyModal.scss";
import Modal from './Modal3';
import styled from 'styled-components';


const Container = styled.div`
  height: 36px;
  padding: 9px 26px 8px;
  border-radius: 20px;
  background-color: #27314c;

  font-family: Noto Sans KR;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
`

const Label = styled.label`
  font-family: Noto Sans KR;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  margin: 0;
  text-align: left;
  color: #333333;
  
  @media (max-width: 934px) {
  }
  
`

const ContentLabel = styled.label`
  width: auto;
  font-family: Noto Sans KR;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  margin: 0;
  color: #333333;
  
  @media (max-width: 934px) {
  }
`

const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  
  @media (max-width: 934px) {
    height: 60px;
  }
`


const Col = styled.div`
  position: relative;
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: stretch;
  flex-wrap: wrap;
  background-color: #ffffff;
  
  @media (max-width: 934px) {
  }
`

const ModalRowCol = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: flex-start;

  @media (max-width: 934px) {
    flex-direction: column;
  }
`

const Space = styled.div`
  width: ${props => props.dw || 0}px;
  height: ${props => props.dh || 0}px;
  
  @media (max-width: 768px) {
    width: ${props => props.mw || 0}px;
    height: ${props => props.mh || 0}px;
  }
`



export default (props) => {


  return (
    <Modal
      renderChildren={(setRef) => (
        <>
          <Space dh={10} mh={10} />
          <Col>
            <Label>지원을 취소하시겠습니까?</Label>
            <Space dh={30} mh={30} />
            <ContentLabel>요율 수정을 원하실 경우</ContentLabel>
            <Space dh={5} mh={5} />
            <ContentLabel>취소 후 재지원해주시면 됩니다.</ContentLabel>
            <Space dh={30} mh={30} />
          </Col>
        </>
      )}
    {...props} />
  )

  
};
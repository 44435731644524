import React from 'react'
import styled from 'styled-components'

const Label = styled.label`
  height: 19px;
  font-family: Noto Sans KR;
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.27;
  letter-spacing: normal;
  margin-bottom: 5px;
  text-align: left;
  color: #666666;
  
  @media (max-width: 934px) {
    margin-bottom: 10px;
  }
  
`

export default Label;


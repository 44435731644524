import React from 'react'
import styled from 'styled-components'

const Text = styled.span`
  display: block;
  font-family: Noto Sans KR;
  font-size: ${props => props.df || 40}px;
  height: ${props => props.dh || 48}px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: center;
  color: #333333;

  @media (max-width: 934px) {
    font-size: ${props => props.mf || 30}px;
    height: ${props => props.mh || 36}px;
  }
`

export default Text;

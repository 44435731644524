import {action, observable} from "mobx";
import axios from "axios";
import Project from "../domains/Project";

class ProjectEditStore {

  @observable step = 1;
  @observable project = undefined;
  @observable errors = {};
  @observable needWriteCompany = false;

  constructor(root) {
    this.root = root;
  }

  @action
  createEmpty = () => {
    const { member } = this.root.memberStore;
    this.needWriteCompany = !member.companyName;

    this.project = new Project({
      status: "RECRUIT_WAITING",
      serviceType: "INTERPRET",
      title: '',
      introduce: "",
      industry: "",
      workSchedule: "",
      workPlace: "",
      contents: "",
      name: member.name || "",
      phone: member.phone || "",
      email: member.email || "",
      companyName: member.companyName || "",
      taxRequire: true,
      companyNum: "",
      taxEmail: "",
      address1: "",
      address2: "",
      address3: "",
      address4: "",
      dueDate: "",
      dueTime: "",
      additionalFee: false,
      career: "2",
      companyDept: "",
      depositType: "PRE",
      options: "",
      price: 0,
      people: 1,
      matchingFee: 20,
      posterUrl: null,
      totalSchedule: "",
      translateAmount: "",
      translateHasScript: false,
      translateFileUrl: null,
      translateType: "DOCUMENT",
    });

    this.step = 1;
  };

  @action
  update = (name, value) => {
    this.project[name] = value;
  };

  @action
  toggle = name => {

    this.project[name] = !this.project[name];
  };

  @action
  updateTemp = (name, value) => {
    this[name] = value;
  };

  // 안쓰는듯..?
  @action
  updateSchedule = (i, name, value) => {
    i = Number(i);

    const schedule = { ...this.project.schedules[i] };
    schedule[name] = value;

    this.project.schedules[i] = schedule;
  };

  // 안쓰는듯..?
  @action
  addSchedule = () => {
    const newSchedule = { date: "", startTime: "09:00", endTime: "18:00", lunch: true };
    this.project.schedules.push(newSchedule);
  };

  // 안쓰는듯..?
  @action
  deleteSchedule = index => {
    this.project.schedules.splice(index, 1)
  };

  @action
  nextStep = () => {
    const { serviceType, interpretLanguage, interpretType, interpreterLevel, sourceLanguage, targetLanguage, translateType, duration, businessHours, people, translateAmount, title, industry, introduce, address1, address4, schedules, depositType, dueDate, dueTime } = this.project;

    const errors = {};

    if(this.step === 1) { //step1일 때
      if(!serviceType) { //통역 vs 번역 안 선택하면
        errors.serviceType = "업무 종류를 선택해주세요.";
      } else {
        if(serviceType == "INTERPRET") {
          if(!interpretLanguage) 
            errors.interpretLanguage = "언어를 선택해주세요."
          
          if(!interpretType) {
            errors.interpretType = "통역 종류를 선택해주세요."
          } else {
            if(!interpreterLevel)
              errors.interpreterLevel = "통역사 수준을 선택해주세요."
          }
          
        } else if(serviceType == "TRANSLATE") {
          if(!sourceLanguage)
            errors.sourceLanguage = "출발어를 선택해주세요."

          if(targetLanguage.split('/').length == 0)
            errors.targetLanguage = "도착어를 선택해주세요."

          if(!translateType)
            errors.translateType = "콘텐츠 종류를 선택해주세요."
        }
      }

    } else if(this.step === 2) {
      if(serviceType == "INTERPRET") {
        if(!duration)
          errors.duration = "진행 기간을 입력해주세요.";
        if(!businessHours)
          errors.businessHours = "통역업무 시간을 입력해주세요."
        if(!people) {
          errors.people = "필요인원수를 입력해주세요.";
        }
      } else if(serviceType == "TRANSLATE") {
        if((translateType == "DOCUMENT") || (translateType == "WEBTOON") || (translateType == "NOVEL")) {
          if(!translateAmount) {
            errors.translateAmount = "총 단어 수를 입력해주세요.";
          }
        } else if(translateType == "VIDEO") {
          if(!translateAmount) {
            errors.translateAmount = "영상 시간을 입력해주세요.";
          }
        } else if(translateType == "REFERENCE") {
          if(!translateAmount) {
            errors.translateAmount = "총 페이지 수를 입력해주세요.";
          }
        }
      }


    } else if(this.step === 3) {
      if(!title || title.length < 5) {
        errors.title = "제목을 5자 이상 입력해주세요.";
    }
      else if(title.length > 30)
        errors.title = "제목을 30자 이하로 입력해주세요.";

      if(industry.split(',').length == 0) {
          errors.industry = "분야를 선택해주세요."
      }

    }

    const allClean = Object.keys(errors).length === 0;

    if(allClean && this.step < 4)
      this.step += 1;

    this.errors = errors;
    return allClean;
  };

  @action
  prevStep = () => {
    if(this.step > 1)
      this.step -= 1;

    return true;
  };

  @action //프로젝트 등록
  post = () => {
    return this.save(false).then(res => {
      const { member } = this.root.memberStore;

      return res;
    })
  };

  @action //프로젝트 임시저장
  postTemp = () => {
    return this.save(true);
  };

  save = temp => {


    const params = {
      ...this.project
    };
  
    const url = temp ? "/api/projects/temp" : "/api/projects";

    return axios.post(url, params).then(res => {
      const project = res.data;
      this.project.idx = project.idx;
      return res;
    })
  };

  @action
  load = idx => {
    this.project = undefined;
    this.step = 1;

    axios.get(`/api/projects/${idx}/edit`).then(response => {
      const { data } = response;
      this.project = new Project(data);
    })
  }
}

export default ProjectEditStore;

import React from 'react'
import styled from 'styled-components'

const Arrow = styled.img`
  position: absolute;
  width: 10.6px;
  height: 5.3px;
  object-fit: contain;
  top: 0;
  right: 0; 
  
  @media (max-width: 768px) {
    top: 23px;
    right: 22px;
  } 
`

const Select = styled.select`
  width: 204px;
  position: relative;
  height: 50px;
  padding: 15px;
  border-radius: 4px;
  border: solid 1px #dddddd;
  background-color: #ffffff;
  font-family: Montserrat;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: left;
  color: #333333;
  appearance: none;
  
  background-image: url('/images/arrow-down.svg');
  background-position: 90% 50%;
  background-repeat: no-repeat;
  background-size: auto;
  :focus {
    border: solid 1px #0069ff;
    outline: 0;
  }
   
  @media (max-width: 934px) {
    width: 100%;
    height: 60px;
    font-size: 14px;
    line-height: 50px;
    padding: 0 20px;
    
  }
`

export default ({
                  children, setRef = () => {
}, ...props
                }) => (
  <Select
    ref={ref => {(ref && ref.name) == 'industry' || (ref && ref.name) == 'targetLanguage' ? setRef(props) : setRef(ref)}}
    {...props}>
    {children}
  </Select>
  );

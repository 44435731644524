import React, {useEffect, useState} from "react";
import _ from "lodash";
import axios from "axios";
import {CheckBox} from "./components";

const KEY = "never-banner-ids"

const HomePopupBanners = () => {
  const [banners, setBanners] = useState([]);
  const [checkedIds, setCheckedIds] = useState({});

  useEffect(() => {
    axios.get(`/api/popups`).then(res => {
      const neverObjectsString = localStorage.getItem(KEY) || "[]"
      const neverObjects = JSON.parse(neverObjectsString) || []
      const dateFiltered = _.filter(neverObjects, obj => (obj.until > Date.now()))
      const dateFilteredIds = _.map(dateFiltered, obj => obj.popupIdx)

      const popups = res.data;
      const filtered = _.filter(popups, (banner) => !_.includes(dateFilteredIds, banner.popupIdx))

      setBanners(filtered);
    })
  }, []);

  const onClickClose = (id) => {
    const never = checkedIds[id]
    if(never) {

      const prevString = localStorage.getItem(KEY) || "[]"
      const prev = JSON.parse(prevString) || []
      const until = (Date.now() + 1000 * 60 * 60 * 24)
      const next = [ ...prev, { popupIdx: id, until} ]

      const nextString = JSON.stringify(next)
      localStorage.setItem(KEY, nextString)
    }

    const next = _.filter(banners, (item) => item.popupIdx !== id)
    setBanners(next)
  }

  if(!banners)
    return null

  return (
    <div className="banners">
      { banners.map(banner => (
        <div className="banner">
          <a href={banner.linkUrl} target="_blank">
            <img className="banner-img" src={banner.fileUrl} />
          </a>
          <div className="buttons">
            <div>
              <CheckBox
                value={checkedIds[banner.popupIdx]}
                onChange={() => {
                  const next = {
                    ...checkedIds,
                    [banner.popupIdx]: !checkedIds[banner.popupIdx]
                  }
                  setCheckedIds(next);
                }}
              >
                오늘은 더 이상 보지 않기
              </CheckBox>
            </div>
            <div onClick={() => onClickClose(banner.popupIdx)} style={{ cursor: "pointer" }}>
              닫기
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default HomePopupBanners;
import React from 'react'
import styled from 'styled-components'
import Col from '../../../atoms/Col'
import Space from '../../../atoms/Space'

export const ButtonContainer = styled.button`
  width: 100%;
  height: 111px;
  padding: 15px;
  border-radius: 4px;
  border: solid ${props => props.selected ? 2 : 1}px ${props => props.selected ? '#0069ff' : '#dddddd'};
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  :focus {outline:0;}
  
  @media (max-width: 934px) {
    width: 100%;
  } 
`

const OuterCircle = styled.div`
  width: 21px;
  height: 21px;
  border-radius: 11px;
  padding: 4px;
  border: solid 1px #dddddd;
  background-color: #ffffff;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
const InnerCircle = styled.div`
  width: 11px;
  height: 11px;
  border-radius: 5px;
  background-color: #0069ff;
`
const Text = styled.span`
  height: 19px;
  font-family: Noto Sans KR;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: left;
  color: #333333;
`

const Desc = styled.li`
  font-family: Noto Sans KR;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: -0.3px;
  text-align: left;
  color: #333333;
  
  :before {
    content: "·"; 
    color: #333333;
    display: inline-block; 
    width: 1em;
    margin-left: -1em;
  }
`

const RadioOption = ({ isChecked, onChange, title, desc }) => (
  <ButtonContainer selected={isChecked} onClick={(e) => {
    e.preventDefault();
    onChange()
  }}>
    <OuterCircle>
      {isChecked && <InnerCircle />}
    </OuterCircle>
    <Space dw={5} mw={5} />
    <Col style={{ alignItems: 'stretch' }}>
      <Text>{title}</Text>
      <Space dh={10} mh={10} />
      <ul style={{
        listStyle: 'none',
      }}>
        {
          desc.map(item => (<Desc>{item}</Desc>))
        }
      </ul>
    </Col>
  </ButtonContainer>
)

export default RadioOption;


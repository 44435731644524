import {action, observable} from "mobx";
import axios from "axios";

class InterpreterProfileStore {
  @observable member = undefined;
  @observable applyStatus = undefined;

  constructor(root) {
    this.root = root;
  }

  @action
  load = (id,applyIdx) => {
    return axios.get(`/api/interpreters/${id}/${applyIdx}`).then(response => {

      const {member, applyStatus} = response.data;
      this.member = member;
      this.applyStatus = applyStatus;
    });
  }



}

export default InterpreterProfileStore;

import React from 'react'
import styled from 'styled-components'

const Button = styled.button`
  border: none;
  outline:none;
  padding: 0;
  background: none;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  
  :focus {
    border: none;
    outline:none;
    padding: 0;
  }
`
const Label = styled.span`
  padding-left: 10px;
  height: 20px;
  font-family: Montserrat;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #333333;
`

export default ({ id, value, onChange, children }) => (
  <>
    <Button
      id={id}
      name={id}
      value={value}
      onClick={(e) => {
        e.preventDefault();
        onChange(!value);
      }}
    >
      {
        value ?
          <img
            src="/images/checking-square_on.png" /> :
          <img
            src="/images/checking-square_off.png" />
      }
      <Label>
        {children}
      </Label>
    </Button>
  </>
)

import {action, observable} from "mobx";
import axios from "axios";
import Project from "../domains/Project";

class ProjectViewStore {
  @observable project = undefined;

  constructor(root) {
    this.root = root;
  }

  @action
  load = idx => {
    axios.get(`/api/projects/${idx}`).then(response => {
      const json = response.data;
      this.project = new Project(json);
    }).then(()=>{
    })
  };

  @action
  unmount = () => {
    this.project = undefined;
  }
  //전문가가 지원한 프로젝트 지원 취소
  @action
  cancelApply = () => {
    const params = { status: "CANCELED" };

    return axios.patch(`/api/applies/${this.project.myApply.idx}`, params).then(res => {
      this.project.cancelApply();
      return res;
    });
  }

  //클라이언트가 프로젝트 모집 마감
  @action
  closeRecruit = () => {
    const idx = this.project.idx;
    return axios.patch(`/api/projects/${idx}/close`).then(res => {
      this.project.status = "RECRUIT_CLOSED";
      return res;
    });
  }

  @action
  inprogressProject = () => {
    const idx = this.project.idx;
    return axios.patch(`/api/projects/${idx}/inprogress`).then(res => {
      this.project.status = "PROJECT_INPROGRESS";
      return res;
    })
  }

  //전문가가 프로젝트 지원 + 지원시 지원가능 여부 판별
  @action
  applyProject = (bidAmount, extraAmount) => {
    const idx = this.project.idx;
    bidAmount = bidAmount.replace(/[^0-9]/g, '');
    if(!!extraAmount)
      extraAmount = extraAmount.replace(/[^0-9]/g, '');

    const params = { bidAmount, extraAmount };
    return axios.post(`/api/projects/${idx}/apply`, params).then(res => {

      return res;
    })
  }

  //클라이언트가 프로젝트 삭제
  @action
  deleteProject = () => {
    const idx = this.project.idx;

    return axios.patch(`/api/projects/${idx}/`).then(res => {
      return res;
    })
  }

  //클라이언트가 프로젝트 승인 요청
  @action
  admitRequest = () => {
    const idx = this.project.idx;
    return axios.patch(`/api/projects/${idx}/request`).then(res => {
      window.location.reload();
      return res;
    })
  }

  //클라이언트가 프로젝트 승인 취소 요청
  @action
  cancelRequest = () => {
    const idx = this.project.idx;

    return axios.patch(`/api/projects/${idx}/cancel-request`).then(res => {
      window.location.reload();
      return res;
    })
  }
}


export default ProjectViewStore;

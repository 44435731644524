import React from 'react'
import styled from 'styled-components'

const A = styled.a`
  height: 18px;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #0069ff;
  text-decoration: none;
  vertical-align: center;
  
  :hover {
   color: #0069ff;
   text-decoration: none;
  }
`

export default A;

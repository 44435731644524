import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import "string.prototype.repeat";
import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from "mobx-react";
import * as Sentry from '@sentry/browser';
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App';
import RootStore from "./stores";

Sentry.init({dsn: "https://b43e81ceea7648698923839f59aebbf5@o4504343417716736.ingest.sentry.io/4504343493017600"});

const rootStore = new RootStore();

const render = Component => {
  return ReactDOM.render(
    <Provider {...rootStore}>
      <App />
    </Provider>,
    document.getElementById('root')
  );
};

render(App);

if (module.hot) {
  module.hot.accept('./App', () => {
    const NextApp = require('./App').default;
    render(NextApp);
  });
}
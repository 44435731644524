import React from 'react'
import styled from 'styled-components'

export const RequestCodeButton = styled.button`
  height: 30px;
  padding: 6px 13px;
  border-radius: 4px;
  border-width: 0;
  background-color: #0069ff;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  align-self: center;
  text-decoration: none;
  
  @media (max-width: 768px) {
    height: 40px;
    padding: 11px 13px;
  } 
`

export const SubmitButton = styled.button`
  width: 463px;
  height: 52px;
  padding: 17px 0px 16px 0px;
  border-radius: 4px;
  background-color: #0069ff;
  border-width: 0;
  font-family: Noto Sans KR;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: center;
  align-self: center;
  text-decoration: none;
  color: #ffffff;
  
  @media (max-width: 768px) {
    width: 360px;
    height: 60px;
    padding: 20px 19px;
    border-radius: 4px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.08);
    background-color: #0069ff;
    align-self: center;
    text-align: center;
    
    font-family: Noto Sans KR;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
  } 
`

export const SaveButton = styled.button`
  width: 92px;
  height: 52px;
  border-radius: 4px;
  border: solid 1px #0069ff;
  font-family: Noto Sans KR;
  text-decoration: none;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 52px;
  letter-spacing: normal;
  text-align: center;
  color: #0069ff;
  background-color: #ffffff;
  
  @media (max-width: 934px) {
    width: 100%;
    height: 44px;
    font-size: 16px;
    line-height: 44px;
  } 
`

export const PrevButton = styled.button`
  width: 92px;
  height: 52px;
  border-width: 0;
  border-radius: 4px;
  font-family: Noto Sans KR;
  text-decoration: none;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 52px;
  letter-spacing: normal;
  text-align: center;
  color: #999999; 
  background-color: #eeeeee;
  
  @media (max-width: 934px) {
    flex: 1;
    height: 52px;
  } 
`

export const NextButton = styled.button`
  width: 92px;
  height: 52px;
  border-width: 0;
  text-decoration: none;
  border-radius: 4px;
  font-family: Noto Sans KR;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 52px;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff; 
  background-color: #0069ff;
  
  @media (max-width: 934px) {
    flex: 1;
    height: 52px;
  } 
`
export const HelpButton = styled.a`
  width: 80px;
  height: 26px;
  border-width: 0;
  text-decoration: none;
  border-radius: 4px;
  font-family: Noto Sans KR;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 26px;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff; 
  background-color: #0069ff;
  &:hover{
    color: #ffffff; 
  }
`

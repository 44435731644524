import React from "react";
import classnames from "classnames"
import "./TertiaryButton.scss";

const TertiaryButton = ({onClick, children, active, small, className}) => {
  return (
    <div className={classnames({
      TertiaryButton: true,
      active,
      small,
      [className]: className
    })} onClick={onClick}>
      {children}
    </div>
  )
};

export default TertiaryButton;
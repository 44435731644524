import React, {useEffect, useState} from "react";
import {useParams} from 'react-router-dom';
import axios from "axios";
import {Table,} from 'antd';
import {Col, Row} from "reactstrap";
import moment from "moment";
import Input from "../Admin/components/Input";
import {KorLanguages} from "../InterpreterProfile/components/TranslateCareerUpdateModal";
import Button from "../Admin/components/Button";
import AdminForm from "../Admin/AdminForm";
import {hasBlank} from "../../../utils";
import {inject, observer} from "mobx-react";

const AdminMemberDetail = ({memberStore, history}) => {
  const { memberIdx } = useParams()
  const [ member, setMember ] = useState({});
  const [ editMode, setEditMode ] = useState(false)

  const { id } = memberStore.member;
  if(id !== "sprofile616@naver.com" && id !== "interpreter2@naver.com") {
    window.alert("비정상적인 접근입니다.")
    memberStore.logout()
    window.location.href = "/login"
  }

  useEffect(() => {
    axios.get(`/api/admin/members/${memberIdx}`).then(res => {
      setMember(res.data);
    })
  }, [])

  const inputs = [
    {
      label: 'IDX',
      dataIndex: 'idx',
      type: 'static',
    },
    {
      label: '회원유형',
      dataIndex: 'type',
      type: 'select',
      options: [
        { value: "CLIENT", text: "고객사" },
        { value: "INTERPRETER", text: "통역사" },
        { value: "ADMIN", text: "관리자" },
      ],
    },
    {
      label: '이름',
      dataIndex: 'name',
      type: 'text',
      required: true,
    },
    {
      label: '아이디',
      dataIndex: 'id',
      type: 'static',
    },
    {
      label: '전화번호',
      dataIndex: 'phone',
      type: 'text',
      required: true,
    },
    {
      label: '이력서',
      dataIndex: 'resumeUrl',
      type: 'custom',
      render: (member) => {
        return (
          <div>
            { member.resumeUrl ? (<a href={member.resumeUrl}>다운</a>) : "-" }
            { editMode && (
              <input type="file" onChange={onResumeFileSelected} style={{ width: "200px" }} className="ml-3" />
            )}
          </div>
        )
      },
    },
    {
      label: '적립포인트',
      dataIndex: 'point',
      type: 'text',
    },
    {
      label: '가산점',
      dataIndex: 'rating',
      type: 'text',
    },
    {
      label: '주민번호',
      dataIndex: 'idCardUrl',
      type: 'text',
    },
    {
      label: '계좌번호',
      dataIndex: 'bankUrl',
      type: 'text',
    },
    {
      label: '가입일자',
      dataIndex: 'createDate',
      type: 'static',
      getValueFunc: (member) => member.createDate && moment(member.createDate).format("YYYY-MM-DD")
    },
    {
      type: 'blanc',
    },
    {
      label: '메인언어',
      dataIndex: '',
      type: 'custom',
      expand: true,
      render: (member) => renderLanguage1(member)
    },
    {
      label: '서브언어',
      dataIndex: '',
      type: 'custom',
      expand: true,
      render: (member) => renderLanguage2(member)
    },
  ]

  const onChangeInput = (name, value) => {
    const next = {
      ...member,
      [name]: value
    }
    setMember(next)
  }

  const onResumeFileSelected = event => {
    const file = event.target.files[0];

    const formData = new FormData();
    formData.append("file", file);

    axios.post(`/api/upload/PROJECT_POSTER`, formData).then(res => {
      const resumeUrl = res.data
      const next = {
        ...member,
        resumeUrl
      }
      setMember(next)
    });
  }

  const renderLanguage1 = (member) => {
    if(!member.languages || member.languages.length === 0)
      return null

    const lang = member.languages[0]
    const { language1, certUrl1, status1 } = lang
    if(!language1)
      return null
    return renderLanguageItem(language1, certUrl1, status1)
  }

  const renderLanguage2 = (member) => {
    if(!member.languages || member.languages.length === 0)
      return null

    const lang = member.languages[0]
    const { language2, certUrl2, status2 } = lang
    if(!language2)
      return null
    return renderLanguageItem(language2, certUrl2, status2)
  }

  const renderLanguageItem = (language, certUrl, status) => {
    return (
      <Row>
        <Col md={3}>
          <Input
            value={KorLanguages[language]}
            readOnly
          />
        </Col>
        <Col md={3}>
          { !certUrl && "인증 증빙 이미지 없음" }
          { certUrl && (<a href={certUrl} target="_blank">다운</a>) }
        </Col>
        <Col md={3}>
          { status !== "EXPERT" && (
            <button onClick={() => onSubmitApproveCert(language)}>승인</button>
          )}
        </Col>
      </Row>
    )
  }

  const onSubmitApproveCert = language => {
    if(!window.confirm("승인하시겠습니까?"))
      return

    const { idx } = member;
    const params = {
      language
    }

    axios.patch(`/api/admin/interpreters/${idx}/approve-certification`, params).then(res => {
      if (res.data.available === false) {
        window.alert("입력값을 확인해주세요!");
        return;
      }

      const languagePrev = member.languages[0]
      const next = {
        ...member,
        languages: [
          {
            ...languagePrev,
            status1: (languagePrev.language1 === language ? "EXPERT" : languagePrev.status1),
            status2: (languagePrev.language2 === language ? "EXPERT" : languagePrev.status2),
          }
        ]
      }
      setMember(next)
      window.alert("성공");
    }).catch(error => {
      window.alert("실패");
    });
  };

  const onSubmitMemberUpdate = () => {
    if(!window.confirm("저장하시겠습니까?"))
      return;

    const { name, id, phone } = member
    if(hasBlank([ name, id, phone ])) {
      window.alert("필수값이 입력되지 않았습니다.")
      return
    }

    const { idx } = member;
    axios.patch(`/api/admin/members/${idx}`, { ...member }).then(res => {
      window.alert("저장되었습니다.")
      window.close()
    }).catch(err => {
      console.log(err);
      window.alert("저장 실패")
    })
  }

  return (
    <div className="admin-contents">
      <Row>
        { editMode && (
          <>
            <Col md={2} style={{
              "display": "flex",
              "flexDirection": "column",
              "justifyContent": "flex-end"}}>
              <Button onClick={onSubmitMemberUpdate} style={{width: "100%"}}>저장</Button>
            </Col>
          </>
        )}
        { !editMode && (
          <Col md={2} style={{
            "display": "flex",
            "flexDirection": "column",
            "justifyContent": "flex-end"}}>
            <Button onClick={() => setEditMode(true)} style={{width: "100%"}}>수정</Button>
          </Col>
        )}
      </Row>
      <AdminForm
        data={member}
        editMode={editMode}
        inputs={inputs}
        onChangeInput={onChangeInput}
      />
    </div>
  )
};

export default inject(stores => ({
  memberStore: stores.memberStore
}))(observer(AdminMemberDetail));

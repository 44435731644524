import React from "react";
import {inject, observer} from "mobx-react";
import HomeSectionPartners from "./HomeSectionPartners";
import HomeSection4 from "./HomeSection4";
import HomeSection5 from "./HomeSection5";
import HomeSection6 from "./HomeSection6";
import HomeSection7 from "./HeroSection7";
import HomeNewHeroB from "./HomeNewHeroB";
import HomeNewSystem from "./HomeNewSystem";
import HomeNewLastSection from "./HomeNewLastSection";

import "./Home.scss";
import {FadeProvider} from "../../../context/fadeContext";
import HomePopupBanners from "./HomePopupBanners";

class Home extends React.Component {

  render() {
    const { member } = this.props.memberStore;
    const isClient = member && member.type === "CLIENT";
    const isInterpreter = member && member.type === "INTERPRETER";   
    return (
      <div className="Home contents-body-v2 css2">
        <FadeProvider>
          <div className="animated animatedFadeInUp fadeInUp">
            <HomeNewHeroB isClient={isClient} isInterpreter={isInterpreter} />
            <HomeSectionPartners/>   
          </div>
          <HomeSection5/>
          {/* <HomeSection7/>  */}
          <HomeSection4/>                
          <HomeNewSystem/>
          <HomeSection6/>
          <HomeNewLastSection isClient={isClient} isInterpreter={isInterpreter} />
        </FadeProvider>
        <HomePopupBanners />
      </div>
    )
    
  }
}


export default inject(stores => ({
  memberStore: stores.memberStore
}))(observer(Home));

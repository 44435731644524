import {action, observable} from "mobx";

class UpdateModalStore {
  @observable eduIdx = 0;
  @observable edu = {
    school: "",
    status: "",
    major: "",
    field: "",
    startDate:"",
    endDate: ""
  };
  @observable companyIdx = 0;
  @observable companyCareer = {
    name: "",
    language: "",
    interpretType : "",
    industry: "",
    startDate: "",
    endDate: ""
  };
  @observable projectIdx = 0;
  @observable projectCareer ={
    company: "",
    industry: "",
    interpretType: "",
    language: "",
    name: "",
    selectedCareer: false,
    startDate: "",
  };
  @observable translateIdx = 0;
  @observable translateCareer = {
    name: "",
    selectedCareer: false,
    company: "",
    sourceLanguage: "",
    targetLanguage: "",
    industry: "",
    startDate: "",
  };
  
  @observable certificationIdx = 0;
  @observable certificationCareer = {
    name: "",
    score: "",
    institution: "",
    industry: "",
    acquisitionYear: "",
  }
  

  constructer(root) {
    this.root = root;
  }

  @action
  setEduIdx(idx) {
    this.eduIdx = idx;
  }
  @action
  setEducation({school, status, major, field, startDate, endDate}) {
    this.edu.school = school;
    this.edu.status = status;
    this.edu.major = major;
    this.edu.field = field;
    this.edu.startDate = startDate;
    this.edu.endDate = endDate;
  }

  @action
  setCompanyIdx(idx) {
    this.companyIdx = idx;
  }
  @action
  setCompanyCareer({name, language, interpretType, industry, startDate, endDate}) {
    this.companyCareer.name = name;
    this.companyCareer.language = language;
    this.companyCareer.interpretType = interpretType;
    this.companyCareer.industry = industry;
    this.companyCareer.startDate = startDate;
    this.companyCareer.endDate = endDate;
  }

  @action
  setProjectIdx(idx) {
    this.projectIdx = idx;
  }
  @action
  setProjectCareer({name, selectedCareer, company, language, interpretType, industry, startDate}) {
    this.projectCareer.name = name;
    this.projectCareer.selectedCareer = selectedCareer;
    this.projectCareer.company = company;
    this.projectCareer.language = language;
    this.projectCareer.interpretType = interpretType;
    this.projectCareer.industry = industry;
    this.projectCareer.startDate = startDate;
  }
  
  @action
  setTranslateIdx(idx) {
    this.translateIdx = idx;
  }

  @action
  setTranslateCareer({name, selectedCareer, company, sourceLanguage, targetLanguage, industry, startDate }) {
    this.translateCareer.name= name;
    this.translateCareer.selectedCareer= selectedCareer ;
    this.translateCareer.company= company ;
    this.translateCareer.sourceLanguage= sourceLanguage ;
    this.translateCareer.targetLanguage= targetLanguage ;
    this.translateCareer.industry=industry ;
    this.translateCareer.startDate= startDate ;
  }

  @action
  setCertificationIdx(idx) {
    this.certificationIdx = idx;
  }

  @action
  setCertificationCareer({name, score, institution, industry, acquisitionYear}) {
    this.certificationCareer.name = name===null ? "" : name;
    this.certificationCareer.score = score===null ? "" : score;
    this.certificationCareer.institution = institution===null ? "" : institution;
    this.certificationCareer.industry = industry===null ? "" : industry;
    this.certificationCareer.acquisitionYear = acquisitionYear===null ? "" : acquisitionYear.toString();
  }

}

export default UpdateModalStore;
import React from 'react'
import styled from 'styled-components'

const Title = styled.div`
  font-family: Noto Sans KR;
  font-size: 40px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #333333;
  
  @media (max-width: 934px) {
    text-align: center;
    font-size: 30px;
    line-height: 1.2;
  }
`

export default Title;

import React from 'react'
import styled from 'styled-components'

const CareerBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 4px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.08);
  background-color: #ffffff;
  margin-bottom: 24px; 
  
  @media (max-width: 934px) {
     width: 100%;
     margin-bottom: 30px;
  }
`

export default ({ children, ...props }) => (<CareerBox {...props}>{children}</CareerBox>)

import {action, observable} from "mobx";
import memberRepository from "../repository/MemberRepository";
import axios from "axios";
import _ from "lodash";
import Member from "../domains/Member";

class MemberStore {
  @observable member = localStorage.getItem("x-auth-token") ? undefined : null;

  
  constructor(root) {
    this.root = root;
  }

  @action
  login(id, password) {
    return memberRepository
    .login(id, password)
    .then(response => {
      const { token, member } = response.data;
      this.processLogin(token, member);

      return response;
    }).catch(error => {
      return error;
    })
  }

  @action
  ping() {
    memberRepository
    .ping()
    .then(response => {
      const { token, member} = response.data;
      this.processLogin(token, member);
    })
    .catch(() => {
      this.processLogin(undefined, undefined);
    });
  }

  @action
  logout() {
    this.processLogin(undefined, undefined);
  }

  @action.bound
  processLogin(token, member) {

    if (token) {
      localStorage.setItem("x-auth-token", token);
      axios.defaults.headers.common["x-auth-token"] = token;
    } else {
      localStorage.removeItem("x-auth-token");
      delete axios.defaults.headers.common["x-auth-token"];
    }

    this.member = member ? new Member(member) : null;
  }

  @action
  patchProfileLink = (profileIdContent) => {
    const params = { profileId: profileIdContent };

    return axios.patch(`/api/members`, params).then(res => {
      memberRepository
    .ping()
    .then(response => {
      const { token, member} = response.data;
      if(member.profileId === profileIdContent)
        this.member.profileId = profileIdContent;
    })
      return res;
    });
  };
  
  @action
  patchIntroduce = (introduceContent) => {
    const params = { introduce: introduceContent };

    return axios.patch(`/api/members`, params).then(res => {
      this.member.introduce = introduceContent;

      return res;
    });
  };

  @action
  update = (name, value) => {
    this.member[name] = value;
  };

  @action
  patchProfilePhoto = url => {
    const params = { photoUrl: url };
    return axios.patch(`/api/members`, params).then(res => {
      this.member.photoUrl = url;

      return res;
    })
  };

  @action
  deleteLanguage = idx => {
    return axios.delete(`/api/members/languages/${idx}`).then(() => {
      this.member.languages = this.member.languages.filter(lang => { return lang.idx !== idx });
    });
  };

  @action
  patchLanguage = (languageContent) => {
    const params = { native: languageContent.native,
                    language1: languageContent.language1,
                    language2: languageContent.language2,
                    certUrl1: languageContent.certUrl1,
                    certUrl2: languageContent.certUrl2}
  
    return axios.patch(`/api/members/languages`, params).then(res => {
      this.member.native = languageContent.native;
      this.member.language1 = languageContent.language1;
      this.member.language2 = languageContent.language2;
      this.member.certUrl1 = languageContent.certUrl1;
      this.member.certUrl2 = languageContent.certUrl2;

      window.location.reload();
      
      return res;
    });
  };

  @action
  saveLanguage = params => {
    return axios.post("/api/members/languages", params).then(response => {
      const language = response.data;
      if(!this.member.languages)
        this.member.languages = [];

      this.member.languages.push(language);
    });
  };

  @action
  saveEducation = params => {
    return axios.post("/api/members/educations", params).then(response => {
      const education = response.data;
      this.member.educations.push(education);
    });
  };

  @action
  editEducation = (idx, params) => {
    return axios.patch(`/api/members/educations/${idx}`, params).then(response => {
      const education = response.data;
      this.member.educations = this.member.educations.forEach((edu) => {
        if(edu.idx === education.idx) {
          edu = {...edu, education};
        }
      })
    });
  };

  @action
  deleteEducation = idx => {
    return axios.delete(`/api/members/educations/${idx}`).then(() => {
      this.member.educations = this.member.educations.filter(edu => { return edu.idx !== idx });
    });
  };

  @action
  saveCertification = params => {
    return axios.post("/api/members/certifications", params).then(response => {
      const cert = response.data;
      this.member.certifications.push(cert);
    });
  };

  @action
  editCertification= (idx, params) => {
    return axios.patch(`/api/members/certifications/${idx}`, params).then(response => {
      const certification = response.data;

      window.location.reload();

    });
  };


  @action
  deleteCertification = idx => {
    return axios.delete(`/api/members/certifications/${idx}`).then(() => {

      this.member.certifications = this.member.certifications.filter(cert => { return cert.idx !== idx });
    });
  };


  @action
  saveCompany = params => {
    return axios.post("/api/members/companies", params).then(response => {
      const company = response.data;
      this.member.companyCareers.push(company);
    });
  };
  
  @action
  editCompany = (idx, params) => {
    return axios.patch(`/api/members/companies/${idx}`, params).then(response => {
      const company = response.data;
      this.member.companyCareers = this.member.companyCareers.forEach((comp) => {
        if(comp.idx === company.idx) {
          comp = {...comp, company};
        }
      })
    });
  };
  
  @action
  deleteCompany = idx => {
    return axios.delete(`/api/members/companies/${idx}`).then(() => {
      this.member.companyCareers = this.member.companyCareers.filter(company => { return company.idx !== idx });
    });
  };

  @action
  saveProject = params => {
    return axios.post("/api/members/projects", params).then(response => {

      const project = response.data;
      const next = [ ...this.member.projectCareers ];
      next.push(project);

      const sorted = _.sortBy(next, ["startDate"]);
      const reversed = _.reverse(sorted);

      this.member.projectCareers = reversed;

      window.location.reload();
    });
  };

  @action
  editProject= (idx, params) => {
    return axios.patch(`/api/members/projects/${idx}`, params).then(response => {
      const project = response.data;

      window.location.reload();

    });
  };

  @action
  deleteProject = idx => {
    return axios.delete(`/api/members/projects/${idx}`).then(() => {
      this.member.projectCareers = this.member.projectCareers.filter(project => { return project.idx !== idx });

      window.location.reload();
    });
  };

  @action
  saveTranslate = params => {
    return axios.post("/api/members/translate-projects", params).then(response => {
      const project = response.data;
      this.member.translateCareers.push(project);

      window.location.reload();
    });
  };

  @action
  editTranslate= (idx, params) => {
    return axios.patch(`/api/members/translate-projects/${idx}`, params).then(response => {
      const project = response.data;

      window.location.reload();

    });
  };

  @action
  deleteTranslateProject = idx => {
    return axios.delete(`/api/members/translate-projects/${idx}`).then(() => {
      this.member.translateCareers = this.member.translateCareers.filter(project => { return project.idx !== idx });

      window.location.reload();
    });
  };

  @action
  deleteInterpretMajorCareer = idx => {
    return axios.patch(`/api/members/major-projects/${idx}`).then(() => {
      window.location.reload();
    });
  };

  @action
  deleteTranslateMajorCareer = idx => {
    return axios.patch(`/api/members/major-translate-projects/${idx}`).then(() => {
      window.location.reload();
    });
  };


  @action
  saveMemberFields = fields => {
    const wraped = _.map(fields, value => ({ name: value }));
    return axios.put(`/api/members/fields`, fields).then(res => {
      this.member.fields = observable(wraped);
      return res;
    });
  }


}

export default MemberStore;

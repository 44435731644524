import React from 'react';
import styled from 'styled-components';
import Space from '../../atoms/Space';
import Modal from 'react-modal';
import "./Modal2.scss";

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)'
  }
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  z-index: 9999;
  
  @media (max-width: 934px) {

  }
`
const CloseButton = styled.img`
  position: absolute;
  top: 0px;
  right: 0px;
  width: 58px;
  height: 58px;
  object-fit: contain;
`
const Bottom = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  
  @media (max-width: 934px) {
  }
`

const SaveButton = styled.button`
  width: 274px;
  height: 52px;
  line-height: 52px;
  font-family: Noto Sans KR;
  text-decoration: none;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  background-color: #0069ff;
  border-radius: 4px;
  border: none;
  :focus {
    border: none;
    outline: 0;
  }
  
  @media (max-width: 934px) {
    width: 140px;
    font-size: 16px;
    height: 48px;
  } 
`
const CancelButton = styled(SaveButton)`
  color: #ffffff;
  background-color: #0069ff;
`
const ExtraButton = styled(SaveButton)`
  color: #0069ff;
  background-color: #ffffff;
  border: 1px solid #0069ff;
`
const Title = styled.div`
  font-family: Noto Sans KR;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #333333;
`
const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #dddddd;
`

class EditModal extends React.Component {
  constructor(props) {
    super(props);
    this.inputRefs = [];
  }

  setRef = ref => { // 모달이 생성될 때

    if (ref === null) return;
    this.inputRefs.push(ref);
  };

  onConfirm = () => { // 저장 버튼 클릭
    const params = {};

    this.inputRefs.forEach(ref => {
      const { name, value } = ref;

      params[name] = value;
    });
    this.props.onConfirm(params);
  };

  toggle = () => {
    this.clearInputs();
    this.props.toggle();
  };

  clearInputs = () => {
    this.inputRefs.forEach(ref => {
      ref.value = "";
    });
  }

  render() {
    const { isOpen, title, renderChildren, renderButton } = this.props
    return (
      <Modal
        isOpen={isOpen}
        preventScroll={true}
        shouldFocusAfterRender={false}
        onRequestClose={this.toggle}
        className="Modal2"
        overlayClassName="Overlay"
      >
        <Container>
          {/* <CloseButton src={"/images/icon-close-profile-modal.svg"} onClick={this.toggle} /> */}
          {renderChildren(this.setRef)}
          <Space dh={16} mh={16} />
          <Bottom>
            {
              renderButton && renderButton(({ children, ...props }) => (
                <>
                  <ExtraButton {...props}>{children}</ExtraButton>
                  <Space dw={20} mw={0} />
                </>
              ))
            }
            <CancelButton onClick={this.toggle}>닫기</CancelButton>
          </Bottom>
          <Space mh={23} />
        </Container>
      </Modal>
    )
  }
};

export default EditModal;

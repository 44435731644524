const ProjectStatus = [
  {name:"TEMP", kor:"임시저장"},
  {name:"RECRUIT_WAITING", kor:"모집 대기중"},
  {name:"RECRUIT_INPROGRESS", kor:"모집중"},
  {name:"RECRUIT_CLOSED", kor:"진행 예정"},
  {name:"PROJECT_INPROGRESS", kor:"진행중"},
  {name:"DONE", kor:"진행 완료"},
  {name:"DELETED", kor:"삭제됨"},
  {name:"CANCELED", kor:"취소됨"},
];
export default ProjectStatus;
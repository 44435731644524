import React from 'react';
import {inject, observer} from "mobx-react";
import "./AdminHeader.scss";
import {Link} from 'react-router-dom';
import CircleBorderButton from "../atoms/CircleBorderButton";

import 'antd/dist/antd.css'

const AdminHeader = ({ location }) => {
  const onPopup = !!window.name
  if(onPopup)
    return null

  return (
    <div className="AdminHeader">
      <div className="adminHeaderinner">
        <div className="bi">
          <a href="/admin" className="ga-gnb-bi">
            <img src="/images/wable-bi.png" srcSet="/images/wable-bi.png, /images/wable-bi@2x.png 2x" />
          </a>
        </div>
        <div className="mt-4">
          <Link to="/admin/members">
            <CircleBorderButton
              active={location.pathname === "/admin/members"}
              className="ga-gnb-aboutinterpret"
            >
              회원관리
            </CircleBorderButton>
          </Link>
          <Link to="/admin/projects">
            <CircleBorderButton
              active={location.pathname === "/admin/projects"}
              className="ga-gnb-aboutinterpret"
            >
              프로젝트 관리
            </CircleBorderButton>
          </Link>
          <Link to="/admin/popup">
            <CircleBorderButton
              active={location.pathname === "/admin/popup"}
              className="ga-gnb-aboutinterpret"
            >
              팝업 관리
            </CircleBorderButton>
          </Link>
          <Link to="/admin/industries">
            <CircleBorderButton
              active={location.pathname === "/admin/industries"}
              className="ga-gnb-aboutinterpret"
            >
              분야 관리
            </CircleBorderButton>
          </Link>
        </div>

      </div>
    </div>
  )
};

export default inject(stores => ({
  memberStore: stores.memberStore
}))(observer(AdminHeader));

import React from "react";

const HomeSectionPartners = () => {

  return (
    <div>
      <section className="partners">
        <div className="section-title">
            {"와블과 함께하는 "}
            <br className="only-mobile"/>
            320여개 파트너사
        </div>
        <br/>
        <div className="section-title"> 
          <img src="/images/kia.png"/>{"  "}            
          <img src="/images/lguplus.png"/>{"  "}
          <br className="only-mobile"/>
          <img src="/images/ollehkt.png"/>{"  "}            
          <img src="/images/ces.png"/>{"  "}          
          <br className="only-mobile"/>            
          <img src="/images/wooa.png"/>{"  "}
          <img src="/images/yonhap.png"/>{"  "}
          <br className="only-mobile"/>
          <br className="only-pc"/>
          <br className="only-pc"/>
          <img src="/images/yonsei.png"/>{"  "}                        
          <img src="/images/dl_logo.png"/>{"  "}            
          <br className="only-mobile"/>
          <img src="/images/bespin.png"/>{"  "}            
          <img src="/images/ocn.png"/>{"  "}
          <br className="only-mobile"/>
          <img src="/images/lxpantos_logo.png"/>{" "}
          <img src="/images/wikipedia.png"/>                    
        </div>
        <br className="only-pc"/>
        <div className="only-pc"> <div className="section-title"> 
          <img src="/images/inno.png"/>{"  "}            
          <img src="/images/young.png"/>{"  "}
          <br className="only-mobile"/>
          <img src="/images/kendall.png"/>{"  "}            
          <img src="/images/audi.png"/>{"  "}
          <br className="only-mobile"/>            
          <img src="/images/lunit.png"/>{"  "}
          <img src="/images/coway_logo.png"/>{"  "}
          <br className="only-mobile"/>
          <br className="only-pc"/>
          <br className="only-pc"/>
          <img src="/images/sm.png"/>{"  "}                        
          <img src="/images/koef.png"/>{"  "}            
          <br className="only-mobile"/>
          <img src="/images/hang.png"/>{"  "}            
          <img src="/images/rsquare.png"/>{"  "}
          <br className="only-mobile"/>
          <img src="/images/cafe24.png"/>{" "}
          <img src="/images/myroutine.png"/>          
        </div>
        </div>
        <br></br>
        <br></br>
        {/* <div className="section-title-black">
          <img src="/images/icn_logo.png"/>{"와 "}
          <br className="only-mobile"/>
          <img src="/images/ito_logo.png"/>{"의 "}
          <br className="only-mobile"/>
          지원을 받고 있습니다.
        </div> */}
      </section>            
    </div>
  )      

};

export default HomeSectionPartners;


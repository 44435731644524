import React, {useContext, useEffect, useRef} from "react";
import classNames from "classnames";
import {FadeContext} from "../../../context/fadeContext";

const HomeSection4 = () => {
  const ref = useRef(null);
  const {addRef, fadeInfo} = useContext(FadeContext);

  useEffect(() => {
    if(ref && ref.current) {
      addRef(ref);
    }
  }, [ref]);

  return (
    <div ref={ref} className={classNames('fade', {show: fadeInfo.get(ref)})}>
      <section className="inner reviews">
        <div className="numbers-wrap">
            <div className="numbers">
              <div className="title">현재 활동 중인 통역사</div>
              <div className="number">
                873
                <span className="unit">명</span>
              </div>
            </div>
            <div className="numbers">
              <div className="title">고객 만족도</div>
              <div className="number">
                98.3
                <span className="unit">%</span>
              </div>
            </div>
          </div>
      </section>            
      
    </div>
  )      
      
};

export default HomeSection4;

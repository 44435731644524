import React from 'react'
import styled from 'styled-components'
import AddButton from './AddButton';
import Space from '../../../atoms/Space';

const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0;
`
const Col = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0;
`
const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #dddddd;
`
const Container = styled.div`
  width: 100%;
  padding: 30px 30px 20px 30px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  
  @media (max-width: 934px) {
    padding: 30px 20px 20px 20px;
  }
`
const Title = styled.div`
  font-family: Noto Sans KR;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #333333;
`

const ItemTitle = styled.div`
  flex: 1;
  font-family: Noto Sans KR;
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.27;
  letter-spacing: normal;
  text-align: left;
  color: #0069ff;
`
const RightItemText = styled.div`
  font-family: Noto Sans KR;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
  text-align: left;
  color: #333333;
  white-space: pre;
  flex: 1;

  @media (max-width: 934px) {
    white-space: normal;
  }
`

const LeftItemText = styled.div`
  opacity: 0.5;
  font-family: Noto Sans KR;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
  text-align: left;
  color: #333333;
  width : 110px;
  white-space: pre;
`
const LanguageIcon = styled.img`
  width: 9.5px;
  height: 9.5px;
  object-fit: contain;
  margin-top: 4px;
  margin-right: 3px;
`
const EditButton = styled.button`
  font-family: Noto Sans KR;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: center;
  color: #666666;
  text-decoration: underline;
  border: none;
  background: none;
  :focus {outline:0;}
  
  @media (max-width: 934px) {
    font-size: 13px;
    width: 72px;
  }
`
const TitleImage = styled.img`
  width: 17.8px;
  height: 18.9px;
  object-fit: contain;
`
const TitleDesc = styled.div`
  font-family: Noto Sans KR;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.27;
  letter-spacing: normal;
  text-align: left;
  color: #333333;
`
const TitleCount = styled.div`
  font-family: Montserrat;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  text-align: left;
  color: #0069ff;
`
const RowToCol = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center; 
  
  @media (max-width: 934px) {
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
  }
`
export default ({ canDelete, canEdit, title, icon, desc, count, onClickEdit, onClickAdd, items = [], onClickDelete }) => (
  <Container>
    <Row style={{ alignItems: 'center' }}>
      <Col>
        <Title>{title}</Title>
        <Row style={{ alignItems: 'flex-start', marginTop: 8 }}>
          <TitleImage src={icon} />
          <div style={{ width: 8 }} />
          <TitleDesc>{desc}</TitleDesc>
          <div style={{ width: 4 }} />
          <TitleDesc>{'총'}</TitleDesc>
          <div style={{ width: 4 }} />
          <TitleCount>{count}</TitleCount>
          <TitleDesc>{'개'}</TitleDesc>
        </Row>
      </Col>
      <div style={{ flex: 1 }} />
      <RowToCol>
        {onClickEdit && canEdit && desc !== "자격증" && (<EditButton onClick={onClickEdit}>{'일괄편집'}</EditButton>)}
        <Space dw={21} mw={0} mh={21} />
        {onClickEdit && canEdit && (<AddButton onClick={onClickAdd} />)}
      </RowToCol>
    </Row>
    <Space dh={10} mh={10} />
    <Divider />
    {
      items.length === 0 && (
        <>
          <Space mh={10} dh={21} />
          <Title>등록 안됨</Title>
          <Space mh={10} dh={21} />
        </>
      )
    }
    {
      items.map((item, idx) => (
        <>
          {idx > 0 && (<Divider />)}
          <Row style={{ width: '100%', alignItems: 'center', paddingTop: 10, paddingBottom: 8 }}>
            <Col style={{ flex: 1 }}>
              <Row>
                <LeftItemText>{item.period}</LeftItemText>
                <ItemTitle>{item.title}</ItemTitle>
              </Row>
              <Row style={{ paddingTop: 8 }}>
                <LeftItemText>
                  <Row>
                    { item.language !== "undefined" && (
                        <>
                          <LanguageIcon src={"/images/chat-gray.svg"} />
                          {item.language}
                        </>
                      )
                    }
                  </Row>
                </LeftItemText>
                <RightItemText>{item.company ? item.company : '_'}</RightItemText>
              </Row>
              <Row style={{ paddingTop: 5 }}>
                <LeftItemText>{item.jobType}</LeftItemText>
                <RightItemText>{item.industry}</RightItemText>
              </Row>
            </Col>
            {
              canDelete && (
                <Col style={{ justifyContent: 'center', paddingLeft: 8, paddingRight: 10 }}>
                </Col>
              )
            }
          </Row>
        </>
      ))
    }
  </Container>
)

import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #f7f9fc;
`

export const Wrapper = styled.div`
  width: 934px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 51px;
  
  @media (max-width: 934px) {
    width: 100%;
    padding-top: 97px;
    padding-left: 20px;
    padding-right: 20px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
  }
`

export const ProfileContainer = styled.div`
  width: 298px;
  margin-top: 71px;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.08);

  @media (max-width: 934px) {
    display: ${props => props.active ? 'flex' : 'none'};
    position: relative;
    margin-top: 0;
    width: 100%;
  }
`

export const CareerContainer = styled.div`
  width: 596px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch; 
  margin-left: 40px;
  padding-top: 71px;
  
  @media (max-width: 934px) {
    display: ${props => props.active ? 'flex' : 'none'};
    width: 100%;
    margin-left: 0;
    padding-top: 0;
  }
`





import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import React, {useContext, useEffect, useRef, useState} from "react";
import classNames from "classnames";
import {FadeContext} from "../../../context/fadeContext";
import Swiper, {Navigation} from 'swiper';

import 'swiper/swiper-bundle.css';

const reviews = [
  { writer: "장*훈님 후기", service: "비즈니스회의통역 진행", content: "이전에 통역에이전시를 이용했을때는 견적을 뽑는대도 오래걸렸고, 통역사분도 선택할 수 없어서 불편했는데 이번에 이 서비스를 활용해서 쉽고 편하게 일처리를 했습니다. 다음에 또 이용하고 싶어요! 건승하세요!" },
  { writer: "정*호님 후기", service: "화상회의통역 진행", content: "우수한 통역사분들 중에서 직접 저희와 맞는 분을 택해서 진행할 수 있어서 편리했습니다. 프로젝트 관련 영역 경험이 많으셔서 용어나 배경설명 없이 진행했어요." },
  { writer: "박*주님 후기", service: "영상번역 진행", content: "다른 업체 견적 받고 너무 놀랐는데 와블 통해서 합리적인 금액으로 만족스러운 자막번역 진행할 수 있었습니다." },
  { writer: "유*라님 후기", service: "웹소설번역 진행", content: "최근에 새로나온 단어나 유행하는 말들을 영어로 센스있게 잘 구성해주셔서 자연스러운 번역이 되었습니다. 덕분에 만족스럽게 영문판 낼 수 있었어요~ 감사합니다." },
  { writer: "오*완님 후기", service: "웹툰번역 진행", content: "영상도 아니고 문서도 아닌 웹툰의 특징에 맞게 잘 작업해주셔서 대사가 많지 않음에도 불구하고 표현하고자 하는 내용들이 잘 담긴 것 같아요. 다음 편도 잘 부탁드립니다." },  
];

const HomeSection6 = () => {
  const ref = useRef(null);
  const {addRef, fadeInfo} = useContext(FadeContext);
  const [ swiper, setSwiper ] = useState(undefined)
  const [ realIndex, setRealIndex ] = useState(0)
  const [ isFirstSlide, setIsFirstSlide ] = useState(true)
  const [ isLastSlide, setIsLastSlide ] = useState(reviews.length <= 3)


  useEffect(() => {
    const swiper = new Swiper('.swiper', {
      modules: [ Navigation ],
      slidesPerView: 1,
      spaceBetween: 10,
      breakpoints: {
        1200: {
          slidesPerView: 3,
        }
      },
      allowTouchMove: false
    });

    swiper.on()

    setSwiper(swiper)
  }, [])

  useEffect(() => {
    if(ref && ref.current) {
      addRef(ref);
    }
  }, [ref]);

  useEffect(() => {
    const slidesPerView = window.innerWidth >= 1200 ? 3 : 1
    setIsFirstSlide(realIndex === 0)
    setIsLastSlide(realIndex >= reviews.length - slidesPerView)
  }, [realIndex])


  const onClickArrow = (direction) => {
    if(direction === "PREV") {
      swiper.slidePrev()
    } else {
      swiper.slideNext()
    }

    setRealIndex(swiper.realIndex)
  }

  return (
    <div ref={ref} className={classNames('fade', {show: fadeInfo.get(ref)})}>
      <section className="inner reviews">
        <div >
          <div className="review-wrap inner">
            <div className="title">
              <img src="/images/wable-bi.png" srcSet="/images/wable-bi.png, /images/wable-bi@2x.png 2x" />
              이용 후기
            </div>
            <div className="swiper review-swiper">
              <div className="swiper-wrapper">
                { reviews.map((review,idx) => (
                  <div className="swiper-slide">
                    <div className="review" key={idx}>
                      <div className="writer">{review.writer}</div>
                      <div className="service">{review.service}</div>
                      <div className="content">{review.content}</div>
                    </div>
                  </div>
                ))}
              </div>
              { !isFirstSlide && (
                <div className="swiper-button-prev" onClick={() => { onClickArrow("PREV") }} />
              )}
              { !isLastSlide && (
                <div className="swiper-button-next" onClick={() => { onClickArrow("NEXT") }} />
              )}
            </div>
          </div>
        </div>
      </section>  
    </div>
  )      
      
};

export default HomeSection6;

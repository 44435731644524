const Languages = [
  { code: "ENGLISH", eng: "English", kor: "영어", id: "ENGLISH" },
  { code: "KOREAN", eng: "Korean", kor: "한국어", id:"KOREAN" },  
  { code: "CHINESE", eng: "Chinese", kor: "중국어", id:"CHINESE" },
  { code: "JAPANESE", eng: "Japanese", kor: "일본어", id:"JAPANESE" },
  { code: "GERMAN", eng: "German", kor: "독일어", id:"GERMAN" },
  { code: "RUSSIAN", eng: "Russian", kor: "러시아어", id:"RUSSIAN" },
  { code: "VIETNAMESE", eng: "Vietnamese", kor: "베트남어", id:"VIETNAMESE" },
  { code: "SPANISH", eng: "Spanish", kor: "스페인어", id:"SPANISH" },
  { code: "SWEDISH", eng: "Swedish", kor: "스웨덴어", id:"SWEDISH" },
  { code: "ARABIC", eng: "Arabic", kor: "아랍어", id:"ARABIC" },
  { code: "ITALIAN", eng: "Italian", kor: "이탈리아어", id:"ITALIAN" },
  { code: "THAI", eng: "Thai", kor: "태국어", id:"THAI" },
  { code: "TURKISH", eng: "Turkish", kor: "터키어", id:"TURKISH" },
  { code: "FRENCH", eng: "French", kor: "프랑스어", id:"FRENCH" },
  { code: "PORTGUESE", eng: "Portguese", kor: "포르투갈어", id:"PORTGUESE" },
  { code: "HINDI", eng: "hindi", kor: "힌디어", id:"HINDI" },
  { code: "INDONESIAN", eng: "Indonesian", kor: "인도네시아어", id:"INDONESIAN" },
  { code: "OTHER", eng: "OTHER", kor: "기타언어", id:"OTHER" },
];

export default Languages
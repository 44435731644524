import React from 'react'
import styled from 'styled-components'

const TextArea = styled.textarea`
  width: 434px;
  height: 132px;
  resize: none;
  scrollbar-base-color: #ededed;
  scrollbar-arrow-color: #0069ff;
  padding: 15px 24px;
  border-radius: 4px;
  border: solid 1px #dddddd;
  font-family: Montserrat;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 18px;
  letter-spacing: normal;
  text-align: left;
  color: #333333;
  
  :focus {
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.08);
    border: solid 2px #0069ff;
    opacity: 1;  
  }
  ::placeholder {
    opacity: 0.5;
  }
  
  @media (max-width: 934px) {
    width: 100%;
    height: 150px;
    font-size: 14px;
    padding: 20px 21px;
  }
`

export default TextArea;

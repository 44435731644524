import axios from "axios";
import o2f from "object-to-formdata";

class MemberRepository {

  login(id, password) {
    return axios.post("/api/login", o2f({id, password}));
  }

  ping() {
    return axios.get("/api/ping");
  }

}

export default new MemberRepository();
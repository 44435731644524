import React from "react";
import {action, observable} from "mobx";
import {inject, observer} from "mobx-react";
import axios from "axios";
import "./ProjectView.scss";
import ProjectViewInterpret2 from "./ProjectViewInterpret2";
import ProjectViewTranslate from "./ProjectViewTranslate";


@inject(stores => ({
  memberStore: stores.memberStore,
  projectViewStore: stores.projectViewStore
}))
@observer
class ProjectView extends React.Component {
  
  @observable showApplyModal = false;
  @observable applyRequest = {
    bidAmount: 0,
    extraAmount: 0
  };

  @observable modal = {
    apply: observable.box(false),
    cancelApply: observable.box(false),
    languageCheck: observable.box(false),
    levelCheck: observable.box(false),
  };

  @action toggleModal = modalName => {
    const next = !this.modal[modalName].get();

    this.modal[modalName].set(next);
  }

  componentDidMount() {
    const {idx} = this.props.match.params;  //url: /projects/:idx
    const {projectViewStore} = this.props;
    projectViewStore.load(idx);

  }


  componentWillUnmount() {
    this.props.projectViewStore.unmount();
  }

  @action
  applyThisProject = () => { //전문가가 프로젝트애 지원
    const {idx} = this.props.match.params;  //url: /projects/:idx
    if (window.confirm("지원하시겠습니까?")) {
      axios.post(`/api/projects/${idx}/apply`).then(response => {
        const apply = response.data;
        this.props.projectViewStore.project.myApply = apply;

        window.alert("지원이 완료되었습니다!");
      })
    }
  };

  closeRecruit = () => {  //클라이언트가 프로젝트 모집 마감
    if (window.confirm("모집을 마감하시겠습니까?")) {
      this.props.projectViewStore.closeRecruit().then(() => {
        window.alert("모집이 마감되었습니다.");
      })
    }
  };
  
  inprogressProject = () => {
    if (window.confirm("프로젝트를 진행하시겠습니까?")) {
      this.props.projectViewStore.inprogressProject().then(() => {
        window.alert("프로젝트 진행 시작");
      })
    }
    
  }

  @action
  completeProject = () => { //클라이언트가 프로젝트 정상 종료
    const {idx} = this.props.match.params;

    if (window.confirm("프로젝트가 정상 종료되었습니까?")) {
      axios.patch(`/api/projects/${idx}/complete`).then(() => {
        window.alert("정상 종료처리 됨.");
        this.props.projectViewStore.project.status = "DONE";
        window.location.href = "/projects/completed";
      })
    }
  };

  @action
  cancelApply = () => { //전문가가 지원한 프로젝트 지원 취소
 
    this.props.projectViewStore.cancelApply().then(() => {
      window.alert("지원이 취소되었습니다.");
      window.location.reload();
    })
  };

  @action
  onClickStar = (value, prev, name) => {
    this[name] = value;
  };

  @action
  closeApplyModal = () => {
    this.showApplyModal = false;
  };

  //전문가가 프로젝트 지원 + 지원시 지원가능 여부 판별
  @action
  applyProject = () => {
    const { bidAmount, extraAmount } = this.applyRequest;

    this.props.projectViewStore.applyProject(bidAmount, extraAmount).then(res => {
      if(res.data.score == 1) {
        this.toggleModal("apply")

        this.toggleModal("languageCheck");
      } else if(res.data.score == 2) {
        this.toggleModal("apply")

        this.toggleModal("levelCheck");
      } else {
        window.alert("정상적으로 지원되었습니다.");
        window.location.reload();
      }
    }).catch(() => {
      alert("지원에 실패하였습니다. 고객센터로 문의 바랍니다.");
    })
  };

  //클라이언트가 프로젝트 삭제
  @action
  deleteProject = () => {
    if (window.confirm("프로젝트를 삭제하시겠습니까?")) {
      this.props.projectViewStore.deleteProject().then(() => {
        window.location.href = "/projects/recruits";
      })
    }
  };

  //클라이언트가 프로젝트 승인 요청
  @action
  admitRequest = () => {
    this.props.projectViewStore.admitRequest().then(() => {
      window.alert("프로젝트 승인 요청이 담당자에게 전달되었습니다.");
    })
  };

  //클라이언트가 프로젝트 승인 취소 요청
  @action
  cancelRequest = () => {
    this.props.projectViewStore.cancelRequest().then(() => {
      window.alert("프로젝트 승인 취소 요청이 담당자에게 전달되었습니다.");
    })
  };


  @action
  setShowApplyModal = value => {
    this.showApplyModal = value
  };

  render() {
    if (!this.props.projectViewStore.project)
      return null;

    const { project } = this.props.projectViewStore;
    const { isTranslateType } = project; //true: 번역 / false:통역

    return (      

      <div className="inner">
        { isTranslateType && ( //번역 프로젝트인 경우
          <ProjectViewTranslate          
            modal={this.modal}
            toggleModal={this.toggleModal}
            projectViewStore={this.props.projectViewStore}
            memberStore={this.props.memberStore}
            closeRecruit={this.closeRecruit}            
            completeProject={this.completeProject}
            inprogressProject={this.inprogressProject}
            cancelApply={this.cancelApply}
            showApplyModal={this.showApplyModal}
            setShowApplyModal={this.setShowApplyModal}
            applyRequest={this.applyRequest}
            applyProject={this.applyProject}
            deleteProject={this.deleteProject}
            admitRequest={this.admitRequest}
            cancelRequest={this.cancelRequest}
          />
        )}
        { !isTranslateType && ( //통역 프로젝트인 경우
          <ProjectViewInterpret2
            modal={this.modal}
            toggleModal={this.toggleModal}
            projectViewStore={this.props.projectViewStore}
            memberStore={this.props.memberStore}
            closeRecruit={this.closeRecruit}      
            completeProject={this.completeProject}
            inprogressProject={this.inprogressProject}
            cancelApply={this.cancelApply}
            showApplyModal={this.showApplyModal}
            setShowApplyModal={this.setShowApplyModal}
            applyRequest={this.applyRequest}
            applyProject={this.applyProject}
            deleteProject={this.deleteProject}
            admitRequest={this.admitRequest}
            cancelRequest={this.cancelRequest}
          />
        )}
      </div>
    )
  }

}

export default ProjectView;
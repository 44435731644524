import React, {useRef, useState} from "react";
import styled from 'styled-components';
import axios from "axios";
import Languages from "../../../values/Languages";
import HalfHours from "../../../values/HalfHours";
import '@toast-ui/editor/dist/toastui-editor.css';
import Space from "../../atoms/Space";
import {inject, observer} from "mobx-react";

const Row = styled.div`
  position: relative;
  width: 800px;
  display: flex;
  flex-wrap: wrap;
  
  @media (max-width: 768px) {
  }
`


const Item = styled.div`
  display: flex;
  flex-direction: row;
  width: 925px;
  align-items: flex-start;

  @media (max-width: 934px) {
    width: 100%;
    flex-direction: column;
  }
`
const Label = styled.label`
  width: 125px;
  height: auto;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  text-align: left;
  color: #333333;
  
  margin-bottom: 0px;

  @media (max-width: 934px) {
    font-size: 16px;
    margin-right: 5px;
  }
`

const Label2 = styled.label`
  width: 300px;
  height: auto;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  text-align: left;
  color: #333333;
  
  margin-bottom: 0px;

  @media (max-width: 934px) {
    font-size: 16px;
    margin-right: 5px;
  }
`

const Input = styled.input`
  width: ${props => props.dw || '100%'};
  height: 50px;
  padding: 15px 20px;
  border-radius: 4px;
  border: solid 1px #dddddd;
  
  font-family: Montserrat;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 18px;
  letter-spacing: normal;
  text-align: left;
  color: #333333;
  
  :focus {
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.08);
    border: solid 2px #0069ff;
    opacity: 1;  
  }
  ::placeholder {
    opacity: 0.5;
  }
  
  @media (max-width: 934px) {
    width: ${props => props.mw || '100%'};
    height: 60px;
    padding: 20px 21px;
  }
`

const Input2 = styled.input`
  width: ${props => props.dw || '100%'};
  height: 50px;
  padding: 15px 20px;
  border-radius: 4px;
  border: solid 1px #dddddd;
  
  font-family: Montserrat;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 18px;
  letter-spacing: normal;
  text-align: left;
  color: #333333;
  
  :focus {
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.08);
    border: solid 2px #0069ff;
    opacity: 1;  
  }
  ::placeholder {
    opacity: 0.5;
  }
  
  @media (max-width: 934px) {
    width: ${props => props.mw || '100%'};
    height: 60px;
    padding: 20px 21px;
  }
`

const Text = styled.span`
  display: block;
  font-family: Noto Sans KR;
  font-size: ${props => props.df || 18}px;
  height: ${props => props.dh || 28}px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: center;
  color: #333333;

  @media (max-width: 934px) {
    font-size: ${props => props.mf || 10}px;
    height: ${props => props.mh || 16}px;
  }
`

const InputMaskDiv = styled.div`
  position: relative;
  width: 443px;

  @media (max-width: 934px) {
    width: 100%;
  }
`

const EditorContainer = styled.div`
  width: 800px;
  @media (max-width: 934px) {
    width: 100%;
    align-items: flex-start;
    flex-direction: column;
  }
`;

const Button = styled.button`
  width: 92px;
  height: 52px;
  border-radius: 4px;
  border: solid 1px #000000;
  font-family: Noto Sans KR;
  text-decoration: none;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 52px;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
  background-color: #cccccc;
  
  @media (max-width: 934px) {
    width: 100%;
    height: 44px;
    font-size: 16px;
    line-height: 44px;
  } 
`
const ProjectDataTemplate = {
  title: "", //프로젝트 명
  workSchedule: "", //일정
  workPlace: "", //장소
  price : 0, //프로젝트비용
  translateAmount: "", //번역분량
  translateType : "", //번역종류
  matchingFee : 0, //프로젝트비용
  people : 0, //필요인원수
  interpreterLevel : "", //지원자격
  status: "", //상태,
  introduce: "", //상세설명
  dueDate : "",
  dueTime: ""
};

const dueTimeList = HalfHours.slice(16,43);

const AdminCertificate = ({memberStore, history}) => {
  const {id} = memberStore.member;
  if(id !== "sprofile616@naver.com") {
    window.alert("비정상적인 접근입니다.")
    memberStore.logout()
    window.location.href = "/login"
  }
  const [memberIdx, setMemberIdx] = useState("");
  const [certiLang, setCertiLang] = useState("");
  const [projectIdx, setProjectIdx] = useState("");
  const [projectData, setProjectData] = useState(ProjectDataTemplate);
  
  const editorRef = useRef();

  const {title, workSchedule, workPlace, price, translateAmount, translateType, matchingFee, people, interpreterLevel, status, introduce, dueDate, dueTime} = projectData; 


  const onSubmitSendNoti = (event) => {
    event.preventDefault();

    const params = {};
    let empty = false;

    event.target.elements.forEach(ele => {
      if(ele.name && !ele.value) {
        empty = true;
      }

      params[ele.name] = ele.value;
    });

    if(empty) {
      window.alert("빈 칸이 있습니다.");
      return;
    }
    axios.post("/api/admin/send-noti", params).then(() => {
      window.alert("발송 성공");
    }).catch(() => {
      window.alert("실패");
    });
  };


  const onSubmitApproveInterpreter = event => {
    event.preventDefault();
    if (certiLang === "") {
      window.alert("언어를 선택하세요!");
      return;
    }
    const params = {
      "language" : certiLang
    }
    axios.patch(`/api/admin/interpreters/${memberIdx}/approve-certification`, params).then(res => {
      if (res.data.available === false) {
        window.alert("입력값을 확인해주세요!");
        return;
      }
      window.alert("성공");
      setMemberIdx("");
      setCertiLang("");
    }).catch(error => {
      window.alert("실패");
      setMemberIdx("");
    });
  };

 

  const onSubmitGetProject = event => {
    event.preventDefault();
    axios.get(`/api/admin/project-update/${projectIdx}`).then(res => {
      setProjectData(res.data)
      editorRef.current.getInstance().setHTML(res.data.introduce, true);
      window.alert("성공");

    })
    .catch(error => {
      window.alert("실패");
      setProjectIdx("");
    })
  }

  const onSubmitUpdateProject = event => {
    event.preventDefault();
    const params = projectData;
    axios.patch(`/api/admin/project-update/${projectIdx}`, params).then((res)=>{
      window.alert("성공");
      setProjectIdx("");
      setProjectData(ProjectDataTemplate);
    }).catch(error => {
      window.alert("실패");
      setProjectIdx("");
      setProjectData(ProjectDataTemplate);
    });
  }

  const onChangeInput = (e) => {
    const { value, name } = e.target; 
    setProjectData({
      ...projectData, 
      [name]: value 
    });
  };
  const onChangeEditor = (e) => {
    setProjectData({
      ...projectData, 
      "introduce": editorRef.current.getInstance().getHTML()
    });
  };

  const onChangeSelect = (e) => {
    const { value, name } = e.target; 
    setProjectData({
      ...projectData, 
      [name]: value 
    });
  }

  const onChangeProjectIdx = (e) => {
    setProjectIdx(e.target.value);
  }

  return (
    <div className="contents-body inner" >
      <div>
        <div>
          <form onSubmit={onSubmitApproveInterpreter}>
            <h4>전문가 언어별 전문레벨 승인</h4>
            <select
              name="certiLang"
              value={certiLang}
              onChange={e => setCertiLang(e.target.value)} 
            >
              <option value="" disabled style={{ color: "#ccc" }}> ---옵션선택--- </option>
              {Languages.map((lang) =>
                <option value={lang.code} key={lang.code}>{lang.kor}</option>)}
            </select>
            <input
              name="memberIdx"
              placeholder="memberIdx"
              value={memberIdx}
              onChange={e => setMemberIdx(e.target.value)}
            />
            <button>승인</button>
          </form>
        </div>
        <hr />
                
      </div>
    </div>
  )
};




const Form = ({template, templateName, fields, onSubmit, description}) => {
  return (
    <div>
      <h4>{templateName}</h4>
      <form onSubmit={onSubmit}>
        <div
          className="pa3 bg-gray"
          style={{maxWidth: "400px"}}
        >
          {template.split("\n").map((line, i) => <div key={i}>{line}</div>)}
        </div>
        <input type="hidden" name="templateName" value={templateName} />
        {fields.map((name, i) => (
          <div key={i} style={{marginBottom: "8px"}}>
            <input name={name} placeholder={name} key={name} />
          </div>
        ))}
        <div>{description}</div>
        <button>발송</button>
      </form>
    </div>
  )
};

const ProjectGetForm = ({onSubmit, projectIdx, onChange}) => {
  return(
    <Row>
      <Input style={{width: "200px"}}
        name="projectIdx" 
        placeholder="projectIdx"
        value={projectIdx}
        onChange={onChange}/>
        <Space mw={20} dw={20}/>
      <Button onClick={onSubmit}>조회</Button>
    </Row>
  );
}
export default inject(stores => ({
  memberStore: stores.memberStore
}))(observer(AdminCertificate));

import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center; 
  padding-right: 30px;
  padding-bottom: 30px;
  
  @media (max-width: 934px) {
    padding: 0 20px 20px 20px;
  }
`
const Button = styled.button`
  flex: 1;
  max-width: 481px;
  height: 36px;
  border-radius: 4px;
  border: solid 1px #dddddd;
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center; 
  :focus {outline:0;}
`
const Text = styled.span`
  opacity: 0.5;
  font-family: Noto Sans KR;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: left;
  color: #333333;
`
const Icon = styled.img`
  width: 10.6px;
  height: 5.3px;
  object-fit: contain;
`
export default ({ showAll, onClick }) => (
  <Wrapper>
    <Button onClick={() => onClick(!showAll)}>
      <Icon src={showAll ? "/images/" : "/images/icon-underarrow.svg"} />
      <div style={{ width: 6 }} />
      <Text>{showAll ? '접기' : '전체보기'}</Text>
    </Button>
  </Wrapper>
)

import React, {lazy} from "react";

export const Login = lazy(() => import("./components/pages/Login/Login"));
export const Join = lazy(() => import("./components/pages/Join/Join"));
export const Settings = lazy(() => import("./components/pages/Settings/Settings"));
export const FindAccount = lazy( () => import("./components/pages/FindAccount/FindAccount"));
export const ProjectList = lazy( () => import("./components/pages/ProjectList/ProjectList"));
export const ProjectEdit = lazy( () => import("./components/pages/ProjectEdit/ProjectEdit"));
export const ProjectView = lazy( () => import("./components/pages/ProjectView/ProjectView"));
export const ProjectApplicantsList = lazy( () => import("./components/pages/ProjectApplicantsList/ProjectApplicantsList"));
export const InterpreterProfile = lazy( () => import("./components/pages/InterpreterProfile/InterpreterProfile"));
export const ProjectFinder = lazy( () => import("./components/pages/ProjectFinder/ProjectFinder"));
export const About = lazy( () => import("./components/pages/About/About"));
export const Pricing = lazy( () => import("./components/pages/Pricing/Pricing"));
export const Welcome = lazy( () => import("./components/pages/Welcome/Welcome"));
export const Quotes = lazy( () => import("./components/pages/Quotes/Quotes"));
export const RequestQuote = lazy( () => import("./components/pages/RequestQuote/RequestQuote"));
export const TestTranslate = lazy( () => import("./components/pages/TestTranslate/TestTranslate"));
export const AboutTranslate = lazy(() => import('./components/pages/AboutTranslate/AboutTranslate'));
export const AboutHire = lazy(() => import('./components/pages/AboutHire/AboutHire'));
export const ProfileViewOnly = lazy(() => import("./components/pages/ProfileViewOnly/ProfileViewOnly"));

export const AdminProjectList = lazy(() => import("./components/pages/AdminProjectList/AdminProjectList"));


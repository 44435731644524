export default [
  {eng: '1', kor: '경영전략'},
  {eng: '2', kor: '마케팅'},
  {eng: '3', kor: '금융'},
  {eng: '4', kor: 'IR·투자'},
  {eng: '5', kor: '법률'},
  {eng: '6', kor: '소프트웨어'},
  {eng: '7', kor: '건설'},
  {eng: '8', kor: '부동산'},
  {eng: '9', kor: '게임'},
  {eng: '10', kor: '디자인'},
  {eng: '11', kor: '기계'},
  {eng: '12', kor: '교통'},
  {eng: '13', kor: '에너지'},
  {eng: '14', kor: '환경'},
  {eng: '15', kor: '의학'},
  {eng: '16', kor: '농업'},
  {eng: '17', kor: '화장품'},
  {eng: '18', kor: '패션'},
  {eng: '19', kor: '유통'},
  {eng: '20', kor: '교육'},
  {eng: '21', kor: '사회복지'},
  {eng: '22', kor: '엔터테인먼트'},
  {eng: '23', kor: '영상·음악'},
  {eng: '24', kor: '문학'},
  {eng: '25', kor: '만화'},
  {eng: '26', kor: '종교'},
  {eng: '27', kor: '운동·헬스케어'},
  {eng: '28', kor: '관광'},
  {eng: '29', kor: '기타'},
  {eng: '49', kor: '경영전략'},
  {eng: '50', kor: '마케팅'},
  {eng: '51', kor: '금융'},
  {eng: '52', kor: 'IR·투자'},
  {eng: '53', kor: '법률'},
  {eng: '54', kor: '소프트웨어'},
  {eng: '55', kor: '건설'},
  {eng: '56', kor: '부동산'},
  {eng: '57', kor: '게임'},
  {eng: '58', kor: '디자인'},
  {eng: '59', kor: '기계'},
  {eng: '60', kor: '교통'},
  {eng: '61', kor: '에너지'},
  {eng: '62', kor: '환경'},
  {eng: '63', kor: '의학'},
  {eng: '64', kor: '농업'},
  {eng: '65', kor: '화장품'},
  {eng: '66', kor: '패션'},
  {eng: '67', kor: '유통'},
  {eng: '68', kor: '교육'},
  {eng: '69', kor: '사회복지'},
  {eng: '70', kor: '엔터테인먼트'},
  {eng: '71', kor: '영상·음악'},
  {eng: '72', kor: '문학'},
  {eng: '73', kor: '만화'},
  {eng: '74', kor: '종교'},
  {eng: '75', kor: '운동·헬스케어'},
  {eng: '76', kor: '관광'},
  {eng: '77', kor: '기타'},
  {eng: '102', kor: '언론/미디어'},
  {eng: '103', kor: '언론/미디어'},
  {eng: '104', kor: 'IT'},
  {eng: '105', kor: 'IT'},
  {eng: '106', kor: '방위산업'},
  {eng: '107', kor: '방위산업'},
];
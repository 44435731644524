import React from "react";
import {computed, observable, set} from "mobx";
import _ from "lodash";
import styled from 'styled-components';
import * as Utils from "../utils";
import {projectStatusKorean} from "../utils";


const IndustryBox = styled.div`
  display: block;
  position: relative;
  width: auto;
  height: 50px;
  margin-right: 10px;
  margin-bottom: 5px;
  padding: 16px 20px;
  border-radius: 4px;
  background-color: #f2f3f6;

  font-family: Noto Sans KR;
  font-weight: bold;
  font-size: 15px;
  color: #333333;
  text-align: left;
  line-height: 1.27;

  @media (max-width: 934px) {
    height: 45px;
    padding: 10px 20px;
  }
`

const IndustryText = styled.label`
  position: relative;
  font-family: Noto Sans KR;
  font-size: 15px;
  font-weight: bold;
  color: #333333;
  text-align: left;
  line-height: 1.27;
  margin: 0;

  @media (max-width: 934px) {
    font-size: 13px;
  }
`

const CancelImg = styled.img`
  position: relative;
  width: 12px;
  height: 12px;
  top: -2px;
  left: 11px;
  cursor: pointer;
`


const DEFAULT_POST_IMAGE_URL = "https://wable-public.s3.ap-northeast-2.amazonaws.com/project-poster/default_thumbnail.jpg";

export const PROJECT_STATUS = {
  TEMP: 'TEMP',
  RECRUIT_WAITING: 'RECRUIT_WAITING',
  RECRUIT_INPROGRESS: 'RECRUIT_INPROGRESS',
  RECRUIT_CLOSED: 'RECRUIT_CLOSED',
  PROJECT_INPROGRESS: 'PROJECT_INPROGRESS',
  DONE: 'DONE',
  DELETED: 'DELETED',
  CANCELED: 'CANCELED',
}

class Project {
  @observable idx;
  @observable owner;
  @observable status;
  @observable language;
  @observable type;
  @observable industry;
  @observable serviceType;
  @observable interpretLanguage;
  @observable interpretType;
  @observable interpreterLevel;
  @observable sourceLanguage;
  @observable targetLanguage;
  @observable translateType;
  @observable duration;
  @observable businessHours;
  @observable people;
  @observable translateAmount;
  @observable translateHasScript;
  @observable translateFileUrl;
  @observable contents;
  @observable workSchedule;
  @observable workScheduleUrl;
  @observable workPlace;
  @observable workPlaceUrl;
  @observable title;
  @observable introduce;
  @observable posterUrl;
  @observable options;
  @observable price;
  @observable name;
  @observable phone;
  @observable email;
  @observable companyName;
  @observable companyDept;
  @observable companyNum; // 사업자등록번호
  @observable depositType;
  @observable taxRequire;
  @observable taxEmail;
  @observable createDate;
  @observable updateDate;
  @observable dueDate;
  @observable dueTime;
  @observable depositDate;
  @observable applicants;
  @observable applicantsCount;
  @observable applicable;
  @observable myApply;
  @observable totalSchedule;
  @observable matchingFee;

  constructor(props) {
    set(this, props);

  }

  get statusKorean() {
    return projectStatusKorean(this.status, this.myApply);
  }
  get languageKorean() {
    return Utils.languageKorean(this.language);
  }
  get typeKorean() {
    return Utils.interpretTypeKorean(this.type);
  }

  get depositTypeKorean() {
    return Utils.depositTypeKorean(this.depositType);
  }
  get isApplied() {
    if(!this.myApply)
      return false;

    return this.status === "RECRUIT_INPROGRESS";
  }
  get isCompleted() {
    return this.status === "DONE" || this.status === "CANCELED"
  }
  get isRecruiting() {
    return this.status === "RECRUIT_WAITING" || this.status === "RECRUIT_REQUEST" || this.status === "RECRUIT_INPROGRESS" || this.status === "TEMP"
  }

  get isEditableStatus() {
    return this.status === "TEMP"
      || this.status === "RECRUIT_WAITING"
  }

  get isTranslateType() {
    return this.serviceType === "TRANSLATE";
  }

  cancelApply = () => {
    this.myApply.status = "CANCELED";
  };

  SetSourceLanguage = (e) => {
    document.getElementById("TargetLanguage").disabled = false;
    this.sourceLanguage = e;
    this.targetLanguage = "";

    if(e != "KOREAN") {
      this.DeleteALL();
      this.targetLanguage = "KOREAN";
      document.getElementById("TargetLanguage").value = "KOREAN";
      document.getElementById("TargetLanguage").disabled = true;
      document.getElementById("KOREAN").hidden = false;
    } else {
      document.getElementById("TargetLanguage").value = "";
      document.getElementById("TargetLanguage").disabled = false;
      document.getElementById("KOREAN").hidden = true;
    }
  }

  AddTargetLanguage = (e) => {
    var tempTL = this.targetLanguage.split('/');

    if(tempTL[0] == "") tempTL.shift();
    tempTL.push(e.value);

    this.targetLanguage = tempTL.join('/');

    document.getElementById(e.value).hidden = true;
  }

  DeleteTargetLanguage = (e) => {

    var tempTL = this.targetLanguage.split('/');
    var deleteLanguage = tempTL[e.getAttribute('value')-1];

    tempTL = tempTL.slice(0, Number(e.getAttribute('value'))-1).concat(tempTL.slice(Number(e.getAttribute('value')), tempTL.length));

    this.targetLanguage = tempTL.join('/');

    for(var i = Number(e.getAttribute('value')); i < tempTL.length+1; i++) {
      document.getElementById("TLText" + String(i)).innerText = document.getElementById("TLText" + String(i+1)).innerText;
    }

    document.getElementById("TargetLanguage" + (tempTL.length+1)).style.display = "none";
    document.getElementById(deleteLanguage).hidden = false;
  }

  DeleteALL = () => {
    for(var i = 1; i <= 10; i++) {
      document.getElementById("TLText" + String(i)).innerText = "";
      document.getElementById("TargetLanguage" + String(i)).style.display = "none";
    }
  }

  AddIndustry = (e) => {
    var tempIndustry = this.industry.split(',');

    if(tempIndustry[0] == "") tempIndustry.shift();

    if(!tempIndustry.includes(e.value)) {
      tempIndustry.push(e.value);

      this.industry = tempIndustry.join(',');

    }
    
    e.value = "";
  }

  DeleteIndustry = (e) => {
    var tempIndustry = this.industry.split(',');
    tempIndustry = tempIndustry.slice(0, Number(e.getAttribute('value'))-1).concat(tempIndustry.slice(Number(e.getAttribute('value')), tempIndustry.length));

    this.industry = tempIndustry.join('/');


    for(var i = Number(e.getAttribute('value')); i < tempIndustry.length+1; i++) {
      document.getElementById("IndustryText" + String(i)).innerText = document.getElementById("IndustryText" + String(i+1)).innerText;
    }

    document.getElementById("Industry" + (tempIndustry.length+1)).style.display = "none";
  }


  setTranslateHasScript = (e) => {
    this.translateHasScript = e;
  }

  get image(){
    return _.isNil(this.posterUrl) ? DEFAULT_POST_IMAGE_URL : this.posterUrl;
  }

  @computed
  get workScheduleToDates() {
    // 2019-05-23 09~18,2019-05-24 09~18
    const splited = this.schedules;
    if(!splited || splited.length === 0)
      return undefined;

    if(splited.length === 1) {
      return splited[0].date;
    } else {
      const first = splited[0];
      const last = splited[splited.length - 1];

      return `${first.date} ~ ${last.date}`;
    }
  }

  calcPrice = (hours) => {

    hours = Number(hours);
    if(hours === 0)
      return 0;

    const career = Number(this.career);


    if(career === 0) {
      return 300000;
    } else if(career === 1) {
      return 400000;
    }

    const priceStandard = {
      ENGLISH: {
        2: {base: 500000, hour: 50000, overBase: 750000, overHour: 150000},
        3: {base: 600000, hour: 60000, overBase: 900000, overHour: 150000}
      },
      CHINESE: {
        2: {base: 500000, hour: 50000, overBase: 750000, overHour: 100000},
        3: {base: 600000, hour: 50000, overBase: 850000, overHour: 100000}
      },
      JAPANESE: {
        2: {base: 500000, hour: 50000, overBase: 750000, overHour: 100000},
        3: {base: 600000, hour: 50000, overBase: 850000, overHour: 100000}
      }
    };

    let price = priceStandard[this.language][Number(this.career)];

    if(!price)
      return -1;

    if(hours <= 6)
      return price.base + price.hour * (hours - 1);
    else
      return price.overBase + price.overHour * (hours - 6);
  }
}

export default Project;

export default [
  { value: "00:00", kor:"00시"},
  { value: "00:30", kor:"00시 30분"},
  { value: "01:00", kor:"01시"},
  { value: "01:30", kor:"01시 30분"},
  { value: "02:00", kor:"02시"},
  { value: "02:30", kor:"02시 30분"},
  { value: "03:00", kor:"03시"},
  { value: "03:30", kor:"03시 30분"},
  { value: "04:00", kor:"04시"},
  { value: "04:30", kor:"04시 30분"},
  { value: "05:00", kor:"05시"},
  { value: "05:30", kor:"05시 30분"},
  { value: "06:00", kor:"06시"},
  { value: "06:30", kor:"06시 30분"},
  { value: "07:00", kor:"07시"},
  { value: "07:30", kor:"07시 30분"},
  { value: "08:00", kor:"08시"},
  { value: "08:30", kor:"08시 30분"},
  { value: "09:00", kor:"09시"},
  { value: "09:30", kor:"09시 30분"},
  { value: "10:00", kor:"10시"},
  { value: "10:30", kor:"10시 30분"},
  { value: "11:00", kor:"11시"},
  { value: "11:30", kor:"11시 30분"},
  { value: "12:00", kor:"12시"},
  { value: "12:30", kor:"12시 30분"},
  { value: "13:00", kor:"13시"},
  { value: "13:30", kor:"13시 30분"},
  { value: "14:00", kor:"14시"},
  { value: "14:30", kor:"14시 30분"},
  { value: "15:00", kor:"15시"},
  { value: "15:30", kor:"15시 30분"},
  { value: "16:00", kor:"16시"},
  { value: "16:30", kor:"16시 30분"},
  { value: "17:00", kor:"17시"},
  { value: "17:30", kor:"17시 30분"},
  { value: "18:00", kor:"18시"},
  { value: "18:30", kor:"18시 30분"},
  { value: "19:00", kor:"19시"},
  { value: "19:30", kor:"19시 30분"},
  { value: "20:00", kor:"20시"},
  { value: "20:30", kor:"20시 30분"},
  { value: "21:00", kor:"21시"},
  { value: "21:30", kor:"21시 30분"},
  { value: "22:00", kor:"22시"},
  { value: "22:30", kor:"22시 30분"},
  { value: "23:00", kor:"23시"},
  { value: "23:30", kor:"23시 30분"},
]
import React from 'react'
import styled from 'styled-components'

const Row = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  
  @media (max-width: 768px) {
  }
`

export default Row;


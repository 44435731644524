import React from 'react'
import styled from 'styled-components'

const FileInput = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  color: ${props => props.active ? '#ffffff' : '#999999'};
  width: 443px;
  height: 50px;
  padding: 15px 20px;
  border-radius: 4px;
  border: solid 1px #dddddd;
  background-color: #ffffff;
  
  font-family: Montserrat;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 18px;
  letter-spacing: normal;
  text-align: left;
  color: #333333;
  
  
  ::placeholder {
    opacity: 0.5;
  }
  
  @media (max-width: 934px) {
    width: 100%;
    height: 60px;
    padding: 20px 21px;
  }
`

const FileUploadButton = styled.button`
    position: absolute;
    top: 4px;
    right: 4px;
    width: 76px;
    height: 40px;
    border-radius: 4px;
    background-color: #333333;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 35px;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;

    @media (max-width: 934px) {
      height: 50px;
    }
`

const FileName = styled.label`
  opacity: 0.5;
  font-family: Montserrat;
  font-size: 14px;
  text-align: left;
  margin: 0;
  color: #333333;
`


const FileUploader = ({ name, width, buttonwidth, placeholder, onChange }) => {
  const hiddenFileInput = React.useRef(null);

  const handleClick = event => {
    event.preventDefault();
    hiddenFileInput.current.click();
  };

  return (
    <>
      <FileInput style={{width: Number(width)}}>
        <FileName id={name}>{placeholder}</FileName>
        <FileUploadButton style={{width: Number(buttonwidth)}} onClick={handleClick}>파일선택</FileUploadButton>
      </FileInput>
      <input type="file"
        name={name}
        ref={hiddenFileInput}
        onChange={onChange}
        style={{ display: 'none' }}
      />
    </>
  );
};
export default FileUploader;

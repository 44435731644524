import React from "react";
import {Col, Row} from "reactstrap";
import _ from "lodash";
import "./BusinessEnglish.scss";
import {inject, observer} from "mobx-react";
import {action, observable} from "mobx";
import {Title} from "@thumbtack/thumbprint-react";
import {BUSINESSENGLISH_ETC} from "../../../utils/Texts";
import {Link} from "react-router-dom";


@inject(stores => ({
  memberStore: stores.memberStore
}))
@observer
class BusinessEnglish extends React.Component {

  @observable tab = "1";

  @action
  onClickTab = tabId => {
    this.tab = tabId;
  };

  render() {
    const createProjectUrl = "/request-quote";

    return (
      <>
      <div className="BusinessEnglish">
        <div className="header">
          <div className="inner">
            <div className="texts">
              <div className="catchphrase">
                생활 회화가 아닌<br />
                비즈니스에 필요한 외국어는-<br />                
                전문가에게 배워야합니다.
              </div>
              <div className="subtext">
                <div>
                  와블은 국내 주요 통번역 대학원 출신의 전문 통번역사님들과 함께하는 통번역플랫폼입니다.
                </div>
                <div>
                  비즈니스 미팅, 행사에 필요한 언어 스킬을 보유한 전문가에게 배워보세요.
                </div>
                <div>
                  당신의 비즈니스를 훨씬 가볍게 만들어 드립니다.
                </div>
              </div>
              <div>
                <Link to={createProjectUrl}>
                  <button className="ga-BusinessEnglish-checkprice">
                    Biz회화 멘토링 문의
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="tab-content">
          <Title size={3} className="mb3">
            기타 요율 기준
          </Title>
          { BUSINESSENGLISH_ETC.map(row => (
            <Row className="etc-item" key={row.name}>
              <Col md={2} className="title">{row.name}</Col>
              <Col md={10} className="text">
                {_.split(row.desc, "\n").map(line => (<div>{line}</div>))}
              </Col>
            </Row>
          ))}
        </div>

        <div className="only-mobile">
          <Link to={createProjectUrl}>
            <div className="yellow-button">
              예상 견적 확인
            </div>
          </Link>
        </div>
      </div>
        </>
    )
  }
}

export default BusinessEnglish;

import React, {Component, Suspense, useEffect} from 'react';
import {hot} from 'react-hot-loader'
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import {inject, observer} from "mobx-react";
import axios from "axios";
import moment from "moment";
import ScrollMemory from "react-router-scroll-memory";
import Home from "./components/pages/Home/Home";
import Header from "./components/layout/Header";
import AdminHeader from "./components/layout/AdminHeader";
import AuthDaemon from "./daemons/AuthDaemon";
import Footer from "./components/layout/Footer";
import KakaoFloating from "./components/molecules/KakaoFloating/KakaoFloating";
import {
  About,
  AboutHire,
  AboutTranslate, AdminProjectList,
  FindAccount,
  InterpreterProfile,
  Join,
  Login,
  Pricing,
  ProjectApplicantsList,
  ProjectEdit,
  ProjectFinder,
  ProjectList,
  ProjectView,
  Quotes,
  RequestQuote,
  Settings,
  TestTranslate,
  Welcome,
} from "./lazy";
import "./styles/index.scss";
import "@thumbtack/thumbprint-atomic"
import "@thumbtack/thumbprint-scss"
import BusinessEnglish from './components/pages/BusinessEnglish/BusinessEnglish';
import ProfileViewOnly from './components/pages/ProfileViewOnly/ProfileViewOnly';
import Loading from './components/atoms/Loading';
import AdminMemberList from "./components/pages/AdminMemberList/AdminMemberList";
import AdminMemberDetail from "./components/pages/AdminMemberDetail/AdminMemberDetail";
import AdminPopupDetail from "./components/pages/AdminPopupDetail/AdminPopupDetail";
import AdminIndustryList from "./components/pages/AdminIndustryList/AdminIndustryList";
import AdminCertificate from "./components/pages/AdminCertificate/AdminCertificate";
import AdminProject from "./components/pages/AdminProject/AdminProject";

export const endpoint = process.env.NODE_ENV === "production" ? "https://wable.io/" : "http://wabledev.com/"
// export const endpoint = process.env.NODE_ENV === "production" ? "http://wabledev.com/" : "http://localhost:8080/"

@inject(stores => ({
  memberStore: stores.memberStore
}))
@observer
class App extends Component {

  constructor(props) {
    super(props);
    // 개발서버&운영서버
    axios.defaults.baseURL = endpoint;

    const token = localStorage.getItem("x-auth-token");
    if(token !== null) {
      axios.defaults.headers.common["x-auth-token"] = token;
    }

    axios.interceptors.response.use(response => {

      if(response.data.status !== 203) {
        const { message, error } = response.data;

        if(message) {
          window.alert(message);
          return Promise.reject(undefined);
        } else if(error) {
          window.alert(error);
          return Promise.reject(undefined);
        }
      }

      return response;
    }, error => {
      const response = error.response;
      const responseData = response && response.data;
      const status = responseData && responseData.status;

      console.error(error);

      if(status === 401) {
        return Promise.reject(401);
      } else {
        // const errorMessage = response.data.message || response.data.error || error.message;
        const errorMessage = response.data.message || response.data.error || error.message;

        if(errorMessage) {
          window.alert(errorMessage);
        } else {
          if(process.env.NODE_ENV === "production") {
            window.alert("죄송합니다. 요청에 실패하였습니다. 문제가 계속되면 고객센터로 문의해 주세요.")
          } else {
            window.alert(JSON.stringify(error));
          }
        }

        return Promise.reject(error);
      }
    });

    window.moment = moment;
  }

  componentDidMount() {
    if(axios.defaults.headers.common["x-auth-token"]) {
      this.props.memberStore.ping();
    }

    if(process.env.NODE_ENV === "production") {
      window.Kakao.init('b273df35d91f28eedd2e9a4657a5c8b1');
    }
  }


  render() {
    if(this.props.memberStore.member === undefined) {
      return null;
    }

    if(window.location.pathname.substring(0,6) === '/admin') {
      return (
        <BrowserRouter>
          <Suspense fallback={<Loading />}>
            <ScrollMemory />
            <Route path="" component={AuthDaemon} />
            <div className="admin-root">
              <Route path="" component={AdminHeader} />
              <Switch>
                <Route path="/admin/members" exact component={AdminMemberList}/>
                <Route path="/admin/members/:memberIdx" exact component={AdminMemberDetail}/>
                <Route path="/admin/projects" exact component={AdminProjectList}/>
                <Route path="/admin/projects/:projectIdx" exact component={AdminProject}/>
                <Route path="/admin/popup" exact component={AdminPopupDetail}/>
                <Route path="/admin/industries" exact component={AdminIndustryList}/>
                <Route path="/admin/certificate" exact component={AdminCertificate}/>
                <Route path="/admin/project" exact component={AdminProject}/>
              </Switch>
            </div>
          </Suspense>
        </BrowserRouter>
      );
    } else {
      return (
        <BrowserRouter>
          <Suspense fallback={<Loading />}>
            <ScrollMemory />            
            <Route path="" component={AuthDaemon} />
            <Route path="" component={Header} />
            
            <Switch>
              <Route path="/" exact component={Home} />
              <Route path="/login" exact component={Login} />
              <Route path="/join" exact component={Join} />
              <Route path="/about" exact component={About} />
              <Route path="/find-account" exact component={FindAccount} />
              <Route path="/welcome" exact component={Welcome} />
              <Route path="/about-translate" exact component={AboutTranslate} />
              <Route path="/about-hire" exact component={AboutHire} />
              <Route path="/test-translate" exact component={TestTranslate} />
              <Route path="/pricing" exact component={Pricing} />
              <Route path="/businessenglish" exact component={BusinessEnglish} />
              <Route path="/projects" exact component={ProjectFinder} />
              <Route path="/projects/recruits" exact component={ProjectList} />
              <Route path="/projects/inprogress" exact component={ProjectList} />
              <Route path="/projects/completed" exact component={ProjectList} />
              <Route path="/projects/applied" exact component={ProjectList} />
              <Route path="/projects/create" exact component={ProjectEdit} />
              <Route path="/projects/:idx/edit" exact component={ProjectEdit} />
              <Route path="/projects/:idx/applicants" exact component={ProjectApplicantsList} />
              <Route path="/projects/:idx" exact component={ProjectView} />
              <Route path="/quotes/:idx" exact component={Quotes} />
              <Route path="/request-quote" exact component={RequestQuote} />
              <Route path="/settings" exact component={Settings} />
              <Route path="/me" exact component={InterpreterProfile} />
              <Route path="/applicant/:interpreterID/:applyIdx" exact component={InterpreterProfile} />
              <Route path="/admin/*" component={ReloadComponent} />
            </Switch>
            <Route path="/profile/:profileId" exact component={ProfileViewOnly}/>  
            <Route path="" component={Footer}/>
          </Suspense>
          <KakaoFloating/>
        </BrowserRouter>
      );}
  }
}

const ReloadComponent = () => {
  useEffect(() => {
    window.location.reload();
  }, [])
  return null
}

export default hot(module)(App);

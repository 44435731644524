import _ from "lodash";
import Industries from "../values/Industries";

export const memberTypeKorean = type => {
  switch (type) {
    case "CLIENT": return "고객사";
    case "INTERPRETER": return "통역사";
    case "ADMIN": return "관리자";
  }
}

export const languageKorean = lang => {
  switch (lang) {
    case "KOREAN": return "한국어";
    case "ENGLISH": return "영어";
    case "CHINESE": return "중국어";
    case "JAPANESE": return "일본어";
    case "GERMAN": return "독일어";
    case "RUSSIAN": return "러시아어";
    case "VIETNAMESE": return "베트남어";
    case "SPANISH": return "스페인어";
    case "SWEDISH": return "스웨덴어";
    case "ARABIC": return "아랍어";
    case "ITALIAN": return "이탈리아어";
    case "THAI": return "태국어";
    case "TURKISH": return "터키어";
    case "FRENCH": return "프랑스어 (불어)";
    case "PORTGUESE": return "포르투갈어";
    case "HINDI": return "힌디어";
    case "INDONESIAN": return "인도네시아어";
    case "OTHER": return "기타 언어";
    default: return lang;
  }
};

export const interpretTypeKorean = type => {
  switch (type) {
    case "SIMUL": return "동시통역";
    case "SEQ": return "순차통역";
    case "TRANSLATE": return "번역";
    default: return type;
  }
};

export const industryKorean = industry => {
  const find = _.find(Industries, { eng: industry });
  if(find)
    return find.kor;

  if(industry === "VIDEO")
    return "영상";
  else if(industry === "COMICS")
    return "만화";
  else if(industry === "NOVEL")
    return "소설";
  else if(industry === "GAME")
    return "게임";

  return industry;
};

export const industryStatusKorean = industyStatus => {
  switch (industyStatus) {
    case "ACTIVATED": return "활성";
    case "DEACTIVATED": return "비활성";
    default: return industyStatus;
  }
}

export const serviceTypeKorean = serviceType => {
  switch (serviceType) {
    case "INTERPRET": return "통역";
    case "TRANSLATE": return "번역";
    default: return serviceType;
  }
}

export const depositTypeKorean = depositType => {
  switch (depositType) {
    case "PRE": return "사전입금";
    case "POST": return "사후입금";
    default: return depositType;
  }
};

export const numberWithCommas = (x, postfix) => {
  const price = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return postfix ? price + "원" : price;
};

export const isNumber = s => {
  s += "";
  s = s.replace(/^\s*|\s*$/g, "");
  return !(s === '' || isNaN(s));
};

export const projectStatusKorean = (status, myApply) => {
  if(status === "DONE")
    return "진행 완료";

  if(myApply) {
    switch (myApply.status) {
      case "APPLIED": return "지원됨";
      case "ACCEPTED": return "채용됨";
      case "DENIED": return "미진행";
      case "CANCELED": return "취소됨";
    }
  } else {
    switch (status) {
      case "TEMP": return "임시저장";
      case "RECRUIT_WAITING": return "모집 대기중";
      case "RECRUIT_INPROGRESS": return "모집중";
      case "RECRUIT_CLOSED": return "진행 예정";
      case "PROJECT_INPROGRESS": return "진행중";
      // case "DONE": return "진행 완료";
      case "DELETED": return "삭제됨";
      case "CANCELED": return "취소됨";
      default: return status;
    }
  }
};

export const download = url => {
  const downloadTag = document.createElement("a");
  downloadTag.setAttribute("target", "_blank");
  downloadTag.style.display = "none";

  document.body.appendChild(downloadTag);
  downloadTag.setAttribute("href", url);
  downloadTag.click();

  document.body.removeChild(downloadTag);
};

export const parseFileNameFromUrl = url => {
  if(!url) return undefined;
  const urlEncoded = url.substring(url.lastIndexOf('/')+1);
  return decodeURI(urlEncoded);
}

export const wordCount = text => {
  let s = text;
  s = s.replace(/\n/g, ' ');
  s = s.replace(/(^\s*)|(\s*$)/gi, ""); //exclude  start and end white-space
  s = s.replace(/[ ]{2,}/gi," "); //2 or more space to 1
  return s.split(' ').filter(function(str){return str !== "";}).length;
};

export const hasBlank = (values) => {
  return _.some(values, v => !v)
}
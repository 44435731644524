import React, {useState, useEffect} from "react";
import axios from "axios";
import {Col, FormGroup, Label, Row} from "reactstrap";
import Button, {PrimaryButton} from "../Admin/components/Button";
import {Input} from "@thumbtack/thumbprint-react";
import {inject, observer} from "mobx-react";

const AdminPopupDetail = ({memberStore, history}) => {
  const { id } = memberStore.member;
  if(id !== "sprofile616@naver.com" && id !== "interpreter2@naver.com") {
    window.alert("비정상적인 접근입니다.")
    memberStore.logout()
    window.location.href = "/login"
  }

  const [ form, setForm ] = useState({})

  useEffect(() => {
    axios.get(`/api/popups`).then(res => {
      const popups = res.data;
      if(popups.length) {
        const last = popups[popups.length - 1]
        setForm(last)
      } else {
        setForm({})
      }
    })
  }, [])

  const onChange = (name, value) => {
    const next = {
      ...form,
      [name]: value
    }
    setForm(next)
  }

  const onClickRemove = () => {
    if(!window.confirm("팝업을 제거 하시겠습니까?"))
      return

    axios.delete("/api/admin/popups").then(() => {
      window.location.reload();
    }).catch(e => {
      window.alert("제거 실패");
      console.log("제거 실패", e)
    });
  }

  const onClickSave = () => {
    if(!window.confirm("팝업을 저장 하시겠습니까?"))
      return

    const { fileUrl, linkUrl } = form;
    if(!fileUrl || ! linkUrl) {
      window.alert("필수값이 입력되지 않았습니다.");
      return;
    }

    const params = { fileUrl, linkUrl }

    axios.post("/api/admin/popups", params).then(() => {
      window.location.reload();
    }).catch(e => {
      window.alert("저장 실패");
      console.log("저장 실패", e)
    })
  }

  const onFileSelect = event => {
    const { files } = event.target;

    if (files.length === 0)
      return;

    const file = files[0];
    const formData = new FormData();
    formData.append("file", file);

    axios.post(`/api/upload/POPUP`, formData).then(res => {
      const url = res.data
      const next = {
        ...form,
        fileUrl: url,
      }
      setForm(next)
    }).catch(() => {
      window.alert("이미지 업로드에 실패하였습니다. 2MB 이하의 이미지(.png, .jpg)파일이 맞는지 확인해주세요. ");
    })
  }

  return (
    <div className="admin-contents">
      <div>
        <Row>
          <Col md={2} style={{
            "display": "flex",
            "flexDirection": "column",
            "justifyContent": "flex-end"}}
          >
            <PrimaryButton onClick={onClickRemove}>팝업 제거</PrimaryButton>
          </Col>
          <Col md={2} style={{
            "display": "flex",
            "flexDirection": "column",
            "justifyContent": "flex-end"}}
          >
            <PrimaryButton onClick={onClickSave}>저장</PrimaryButton>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col md={12}>
            <FormGroup row>
              <Col md={2}>
                <Label>이미지</Label>
              </Col>
              <Col md={4}>
                { form && form.fileUrl && (
                  <img src={form.fileUrl} style ={{ width: 100 }} />
                )}
                <div className="mt-3">
                  <input
                    type="file"
                    onChange={onFileSelect}
                  />
                </div>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col md={2}>
                <Label>링크URL</Label>
              </Col>
              <Col md={8}>
                <Input
                  type="text"
                  name="linkUrl"
                  placeholder="https://..."
                  value={form.linkUrl}
                  size="small"
                  onChange={(_, e) => onChange(e.target.name, e.target.value)}
                />
              </Col>
            </FormGroup>
          </Col>
        </Row>
      </div>
    </div>
  )
};

export default inject(stores => ({
  memberStore: stores.memberStore
}))(observer(AdminPopupDetail));

import React from "react";
import {Link} from "react-router-dom";


const HomeNewHeroB = ({ isClient, isInterpreter }) => {
  if(isClient){
  return (
    <>
      <section className="hero">
        <div className="inner hero-inner">
          <img src="/images/home-hero-circle-small.svg" alt="" className="circle-small" />
          <img src="/images/home-hero-circle-big.svg" alt="" className="circle-big" />
          <img src="/images/home-hero-woman.png" alt="" className="woman" />
          <div className="p-and-b">
            <div className="paragraph">
            <h1>
            {"딱- 맞는 "}<br></br>
            <em>
              통
              <span style={{margin: "0 -2px"}}>·</span>
              번역사
            </em>
            를<br/>
            빠르게 소개해드립니다
          </h1>
            </div>
            <div className="button-wrap">
            <Link to="/projects/create">
                <button className="primary-button abtest-b" id="case-b-hero">
                  프로젝트 등록
                </button>
              </Link>
              <br></br>            
              <Link to="/request-quote">
                <button className="primary-button abtest-b" id="case-b-hero">
                  견적받기
                </button>
              </Link>
            </div>
          </div>
        </div>
      </section> 
    </>
  )
}
else if(isInterpreter){
  return (
    <>
      <section className="hero">
        <div className="inner hero-inner">
          <img src="/images/home-hero-circle-small.svg" alt="" className="circle-small" />
          <img src="/images/home-hero-circle-big.svg" alt="" className="circle-big" />
          <img src="/images/home-hero-woman.png" alt="" className="woman" />
          <div className="p-and-b">
            <div className="paragraph">
              <h1>
                [내 프로필]을 <br/>등록하여<br/>                
                <em>
                  프로젝트 매칭확률을 </em>
                <br/>
                높여보세요.
              </h1>
            </div>
            <div className="button-wrap">
              <Link to="/me">
                <button className="primary-button abtest-b" id="case-b-hero">
                  내 프로필 관리
                </button>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
else{
  return (
    <>
      <section className="hero">
        <div className="inner hero-inner">
          <img src="/images/home-hero-circle-small.svg" alt="" className="circle-small" />
          <img src="/images/home-hero-circle-big.svg" alt="" className="circle-big" />
          <img src="/images/home-hero-woman.png" alt="" className="woman womannotlogin" />
          <div className="p-and-b">
            <div className="paragraph">
            <h1>
            {"딱- 맞는 "}<br></br>
            <em>
              통
              <span style={{margin: "0 -2px"}}>·</span>
              번역사
            </em>
            를<br/>
            빠르게 소개해드립니다
          </h1>
            </div>
            <div className="button-wrap">
              <Link to="/request-quote">
                <button className="primary-button abtest-b" id="case-b-hero">
                  견적받기
                </button>
              </Link>            
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
};

export default HomeNewHeroB;
import React from 'react'
import styled from 'styled-components'

const Arrow = styled.img`
  position: absolute;
  width: 10.6px;
  height: 5.3px;
  object-fit: contain;
  top: 0;
  right: 0; 
  
  @media (max-width: 768px) {
    top: 23px;
    right: 22px;
  } 
`

const Select = styled.select`
  position: relative;
  width: 443px;
  height: 50px;
  padding: 15px 20px;
  border-radius: 4px;
  border: solid 1px #dddddd;
  background-color: #ffffff;
  font-family: Montserrat;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: left;
  color: #333333;
  appearance: none;
  
  background-image: url('/images/arrow-down.svg');
  background-position: 93% 50%;
  background-repeat: no-repeat;
  background-size: auto;
   
  @media (max-width: 934px) {
    width: 100%;
    height: 60px;
    padding: 21px 21.4px 20px 20px;
  }
`

export default ({ children, ...props }) => (<Select {...props}>
  {children}
</Select>);

import {action, computed, observable} from "mobx";
import axios from "axios";
import Project from "../domains/Project";
import _ from "lodash";

class ProjectListStore {

  @observable searchProjects = undefined;
  @observable availableProjects = undefined;
  @observable myOwnProjects = undefined;
  @observable activeTab = "APPLIED";

  constructor(root) {
    this.root = root;
  }

  @action
  search = () => {
    axios.get("/api/projects").then(response => {
      const { member } = this.root.memberStore;
      const projects = response.data.map(json => new Project(json));
      var tempProjects = [];
      this.searchProjects = projects;

      projects.map(project => { // 지원자 언어, 레벨에 맞는 프로젝트 보여주기 
        if(project.serviceType == "INTERPRET") {
          if(project.interpreterLevel == "EXPERT") {
            if(project.interpretLanguage == member.languages[0].language1 && member.languages[0].status1)
              tempProjects.push(project);
            else if(project.interpretLanguage == member.languages[0].language2 && member.languages[0].status2)
              tempProjects.push(project);
          } else {
            if(project.interpretLanguage == member.languages[0].language1 || project.interpretLanguage == member.languages[0].language2)
              tempProjects.push(project);
          }
        } else if(project.serviceType == "TRANSLATE") {
          if(project.sourceLanguage == "KOREAN") {
            for(var language of project.targetLanguage.split('/')) {
              if(language == member.languages[0].language1 || language == member.languages[0].language2) {
                tempProjects.push(project);
                break;
              }
            }
          } else {
            if(project.sourceLanguage == member.languages[0].language1 || project.targetLanguage == member.languages[0].language2)
              tempProjects.push(project);
          }
        }
      })

      this.availableProjects = tempProjects;
      this.loadMyOwn();
    })

  };

  @action
  loadMyOwn = () => {
    axios.get("/api/projects/myown").then(response => {
      const projects = response.data.map(json => new Project(json));
      this.myOwnProjects = projects;
    })
  };

  @action
  setActiveTab = tab => {
    this.activeTab = tab;
  };

  @computed
  get recruitingProjects() {
    return _.filter(this.myOwnProjects, p => (p.isRecruiting))
  }

  @computed
  get inprogressProjects() {
    const inprogressProjects =  _.filter(this.myOwnProjects, p => (p.status === "PROJECT_INPROGRESS" || p.status === "RECRUIT_CLOSED"));
    return inprogressProjects;
  };

  @computed
  get appliedProjects() {

    return _.filter(this.myOwnProjects, p => {
      if(p.isCompleted)
        return false;
      if(!p.myApply)
        return false;

      return this.activeTab === "ALL" || this.activeTab === p.myApply.status
        || (this.activeTab === "APPLIED" && p.myApply && p.myApply.status === "ACCEPTED")
    });
  }

  @computed
  get completedProjects() {
    // 클라이언트일때는 myOwnProjects에서 isCompleted면 다 통과
    // 통역사 일때는 isCompleted && myApply.status == ACCEPTED
    const { member } = this.root.memberStore;
    const { isInterpreter, isClient } = member;

    if(isClient) {
      return _.filter(this.myOwnProjects, p => (p.isCompleted));
    } else if(isInterpreter) {
      return _.filter(this.myOwnProjects, p => (p.isCompleted && p.myApply && p.myApply.status === "ACCEPTED"));
    }

  }

}

export default ProjectListStore;

import React from 'react'
import styled from 'styled-components'
import Space from '../../../atoms/Space'

const ButtonContainer = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border: none;
  background-color: transparent;
  padding: 0;
  :focus {outline:0;}
`

const OuterCircle = styled.div`
  width: 21px;
  height: 21px;
  border-radius: 11px;
  padding: 4px;
  border: solid 1px #dddddd;
  background-color: #ffffff;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
const InnerCircle = styled.div`
  width: 11px;
  height: 11px;
  border-radius: 5px;
  background-color: #0069ff;
`
const Text = styled.span`
  height: 19px;
  font-family: Noto Sans KR;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: left;
  color: #333333;
`

const Radio = ({ isChecked, onChange, children }) => (
  <ButtonContainer selected={isChecked} onClick={(e) => {
    e.preventDefault();
    onChange()
  }}>
    <OuterCircle>
      {isChecked && <InnerCircle />}
    </OuterCircle>
    <Space dw={5} mw={5} />
    <Text selected={isChecked}>{children}</Text>
  </ButtonContainer>
)

export default Radio

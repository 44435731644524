import React from 'react'
import styled from 'styled-components'
import Row from '../../../atoms/Row'
import Languages from "../../../../values/Languages";


const TargetLanguageContainer = styled(Row)`
  height: auto;
  width: 443px;
`

const TargetLanguageBox = styled.div`
  display: none;
  position: relative;
  width: auto;
  height: 50px;
  margin-right: 10px;
  margin-bottom: 5px;
  padding: 16px 20px;
  border-radius: 4px;
  background-color: #f2f3f6;
  align-items: center;

  font-family: Noto Sans KR;
  font-weight: bold;
  font-size: 15px;
  color: #333333;
  text-align: left;
  line-height: 1.27;

  @media (max-width: 934px) {
    height: 45px;
    font-size: 14px;
    padding: 10px 10px 10px 20px
  }
`

const TargetLanguageText = styled.label`
  position: relative;
  font-family: Noto Sans KR;
  font-size: 15px;
  font-weight: bold;
  color: #333333;
  text-align: left;
  line-height: 1.27;
  margin: 0;
  margin-right: 15px;

  @media (max-width: 934px) {
    font-size: 13px;
  }
`

const CancelImg = styled.img`
  position: relative;
  width: 12px;
  height: 12px;
  cursor: pointer;
`



export const TLContainer = ({targetLanguage, DeleteTargetLanguage}) => {
  
  var tempTL = (targetLanguage || '').split('/');
  if(tempTL[0] == "") tempTL.shift();

  setTimeout(function() {
    if(tempTL.length > 0 && tempTL[0] != "KOREAN") {
      for(var i = 1; i <= tempTL.length; i++) {
        document.getElementById("TargetLanguage" + i).style.display = "flex";
        document.getElementById("TLText" + i).innerText = Languages[Languages.map(x => x.code).indexOf(tempTL[i-1])].kor;
      }
    }
  }, 1)
  

  return (
    <TargetLanguageContainer>
        <TargetLanguageBox id='TargetLanguage1'>
            <TargetLanguageText id="TLText1"></TargetLanguageText>
            <CancelImg src={'/images/icon-closed.svg'} value="1" onClick={DeleteTargetLanguage}></CancelImg>
        </TargetLanguageBox>
        <TargetLanguageBox id='TargetLanguage2'>
            <TargetLanguageText id="TLText2"></TargetLanguageText>
            <CancelImg src={'/images/icon-closed.svg'} value="2" onClick={DeleteTargetLanguage}></CancelImg>
        </TargetLanguageBox>
        <TargetLanguageBox id='TargetLanguage3'>
            <TargetLanguageText id="TLText3"></TargetLanguageText>
            <CancelImg src={'/images/icon-closed.svg'} value="3" onClick={DeleteTargetLanguage}></CancelImg>
        </TargetLanguageBox>
        <TargetLanguageBox id='TargetLanguage4'>
            <TargetLanguageText id="TLText4"></TargetLanguageText>
            <CancelImg src={'/images/icon-closed.svg'} value="4" onClick={DeleteTargetLanguage}></CancelImg>
        </TargetLanguageBox>
        <TargetLanguageBox id='TargetLanguage5'>
            <TargetLanguageText id="TLText5"></TargetLanguageText>
            <CancelImg src={'/images/icon-closed.svg'} value="5" onClick={DeleteTargetLanguage}></CancelImg>
        </TargetLanguageBox>
        <TargetLanguageBox id='TargetLanguage6'>
            <TargetLanguageText id="TLText6"></TargetLanguageText>
            <CancelImg src={'/images/icon-closed.svg'} value="6" onClick={DeleteTargetLanguage}></CancelImg>
        </TargetLanguageBox>
        <TargetLanguageBox id='TargetLanguage7'>
            <TargetLanguageText id="TLText7"></TargetLanguageText>
            <CancelImg src={'/images/icon-closed.svg'} value="7" onClick={DeleteTargetLanguage}></CancelImg>
        </TargetLanguageBox>
        <TargetLanguageBox id='TargetLanguage8'>
            <TargetLanguageText id="TLText8"></TargetLanguageText>
            <CancelImg src={'/images/icon-closed.svg'} value="8" onClick={DeleteTargetLanguage}></CancelImg>
        </TargetLanguageBox>
        <TargetLanguageBox id='TargetLanguage9'>
            <TargetLanguageText id="TLText9"></TargetLanguageText>
            <CancelImg src={'/images/icon-closed.svg'} value="9" onClick={DeleteTargetLanguage}></CancelImg>
        </TargetLanguageBox>
        <TargetLanguageBox id='TargetLanguage10'>
            <TargetLanguageText id="TLText10"></TargetLanguageText>
            <CancelImg src={'/images/icon-closed.svg'} value="10" onClick={DeleteTargetLanguage}></CancelImg>
        </TargetLanguageBox>
    </TargetLanguageContainer> 
)}

export default TLContainer;
import MemberStore from "./MemberStore";
import ProjectEditStore from "./ProjectEditStore";
import ProjectListStore from "./ProjectListStore";
import ProjectViewStore from "./ProjectViewStore";
import InterpreterProfileStore from "./InterpreterProfileStore";
import IndustryStore from "./IndustryStore";
import UpdateModalStore from "./UpdateModalStore";

class RootStore {
  constructor() {
    this.memberStore = new MemberStore(this);
    this.projectEditStore = new ProjectEditStore(this);
    this.projectListStore = new ProjectListStore(this);
    this.projectViewStore = new ProjectViewStore(this);
    this.interpreterProfileStore = new InterpreterProfileStore(this);
    this.industryStore = new IndustryStore(this);
    this.updateModalStore = new UpdateModalStore(this);
  }
}

export default RootStore;
import React, {useEffect, useRef, useState} from "react";
import styled from 'styled-components';
import axios from "axios";
import _ from "lodash";
import ProjectStatus from "../../../values/ProjectStatus";
import '@toast-ui/editor/dist/toastui-editor.css';
import {Editor, Viewer} from '@toast-ui/react-editor';
import {inject, observer} from "mobx-react";
import {useParams} from "react-router-dom";
import AdminForm from "../Admin/AdminForm";
import {Table} from "antd";
import {Col, Row} from "reactstrap";
import SendNotiModal from "./SendNotiModal";
import Languages from "../../../values/Languages";
import {hasBlank} from "../../../utils";
import moment from "moment";
import ProjectViewStore from "../../../stores/ProjectViewStore";
import ProjectView from "../ProjectView/ProjectView";

const { Column, } = Table;

export const numberWithCommas = (x, postfix) => {
  if(!x) return ""
  const price = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return postfix ? price + "원" : price;
};

const EditorContainer = styled.div`
  width: 800px;
  @media (max-width: 934px) {
    width: 100%;
    align-items: flex-start;
    flex-direction: column;
  }
`;

const Button = styled.button`
  width: 92px;
  height: 52px;
  border-radius: 4px;
  border: solid 1px #000000;
  font-family: Noto Sans KR;
  text-decoration: none;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 52px;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
  background-color: #cccccc;
  
  @media (max-width: 934px) {
    width: 100%;
    height: 44px;
    font-size: 16px;
    line-height: 44px;
  } 
`
const Btn = styled.button`
  width: ${props => props.dw || 0};
  height: 52px;
  border-radius: 4px;
  border: ${props => props.border || "solid 1px #0069ff"};
  background-color: ${props => props.backgroundColor || "#ffffff"};

  color: ${props => props.color || "#0069ff"};
  font-family: Noto Sans KR;
  font-size: 16px;
  font-weight: bold;

  // @media (max-width: 934px) {
  //   width: ${props => props.mw || 0};
  }
`

const AdminProject = ({memberStore, industryStore, history}) => {
  const {projectIdx: paramProjectIdx} = useParams()
  const [ sendNotiData, setSendNotiData ] = useState();
  const [ editMode, setEditMode ] = useState(false)
  const [projectData, setProjectData] = useState();
  const editorRef = useRef();

  const { id } = memberStore.member;
  if(id !== "sprofile616@naver.com" && id !== "interpreter2@naver.com") {
    window.alert("비정상적인 접근입니다.")
    memberStore.logout()
    window.location.href = "/login"
  }

  useEffect(() => {
    industryStore.load();
  }, [])

  useEffect(() => {

    if (paramProjectIdx && paramProjectIdx === "create") {
      setProjectData({})
      setEditMode(true)
    } else if (paramProjectIdx && paramProjectIdx !== "create") {
      axios.get(`/api/admin/project-update/${paramProjectIdx}`).then(res => {
        let project = res.data
        const { dueDate, applicants } = project

        if(dueDate) {
          project.dueDate = moment(project.dueDate).format("YYYY-MM-DD")
        }

        let seq = 0
        if(applicants) {
          const newApplicants = _.map(applicants, obj => {
            seq += 1
            return { ...obj, seq }
          })
          project.applicants = newApplicants
        }

        setProjectData(project)
      }).catch(error => {
        window.alert("프로젝트 정보 불러오기 실패");
        throw error
      })
    }
  }, [paramProjectIdx])

  useEffect(() => {
    if(editMode) {
      editorRef.current.getInstance().setHTML(introduce || "", true);
    }
  }, [editMode])

  if(!projectData)
    return null

  const {
    idx: projectIdx,
    price,
    translateAmount,
    translateType,
    matchingFee,
    status,
    introduce,
    serviceType,
    memo,
    applicants,
    posterUrl
  } = projectData;

  const totalCost = _.chain(applicants)
    .filter({ status: "ACCEPTED" })
    .map(apply => apply.bidAmount)
    .sum()
    .value();

  const onSubmitUpdateProject = event => {
    event.preventDefault();

    if(!window.confirm("저장하시겠습니까?")) {
      return
    }

    const { title, owner, workSchedule, translateType, translateAmount, workPlace, people, status } = projectData

    let editor = editorRef.current.getInstance();
    const markdownIntroduce = editor.getMarkdown().trim()

    const reqiredValues = [ title, owner, workSchedule, workPlace, people, markdownIntroduce ]
    if(hasBlank(reqiredValues)) {
      window.alert("필수값이 입력되지 않았습니다.")
      return
    }

    const introduce = editor.getHTML()

    const idx = projectIdx ? projectIdx : null
    const data = {
      ...projectData,
      idx,
      introduce
    }

    axios.put(`/api/admin/projects`, data).then((res) => {
      window.alert("저장되었습니다.");
      window.close()

    }).catch(error => {
      window.alert("저장 실패");
    });
  }

  const onChangeInput = (name, value) => {
    const next = {
      ...projectData,
      [name]: value
    }
    setProjectData(next)
  }

  const onConfirmNoti = (form) => {
    if(!form.target || !form.type) {
      window.alert("값을 선택해주세요.")
      return
    }

    const body = {
      ...form,
      projectIdx: projectIdx
    }
    axios.post(`/api/admin/noti`, body).then(res => {
      window.alert("발송 성공")
    })
  }

  const hire = (applyIdx, status) => {
    const body = { status }
    axios.patch(`/api/admin/projects/applies/${applyIdx}`, body).then(res => {
      const nextApplicants = _.map(applicants, apply => {
        if(apply.applyIdx !== applyIdx)
          return apply

        return {
          ...apply,
          status: status
        }
      })

      const nextProjectData = {
        ...projectData,
        applicants: nextApplicants
      }

      setProjectData(nextProjectData)
    }).catch(err => {
      console.log(err)
      window.alert("변경 실패")
    })
  }

  const onClickUpdateBidAmount = (applyIdx) => {
    const input = window.prompt("수정 할 지원 금액?");
    if(!input)
      return

    updateBidAmount(applyIdx, input)
  }

  const updateBidAmount = (applyIdx, bidAmount) => {
    axios.patch(`/api/admin/projects/applies/${applyIdx}/bid-amount?bidAmount=${bidAmount}`).then(res => {
      window.location.reload();
    }).catch(err => {
      window.alert("저장 실패")
    })
  }

  const onPosterFileSelected = event => {
    const file = event.target.files[0];

    const formData = new FormData();
    formData.append("file", file);

    axios.post(`/api/upload/PROJECT_POSTER`, formData).then(res => {
      const posterUrl = res.data
      const next = {
        ...projectData,
        posterUrl
      }
      setProjectData(next)
    });
  }

  const interpretLangueageInputs = [
    {
      label: '통역언어',
      dataIndex: 'interpretLanguage',
      type: 'select',
      options: Languages.map(lang => ({ value: lang.code, text: lang.kor })),
    },
    {
      label: '통역종류',
      dataIndex: 'interpretType',
      type: 'select',
      options: [
        { value: 'SEQ', text: '순차통역' },
        { value: 'SIMUL', text: '동시통역' },
      ],
    },
  ]

  const translateLanguageInputs = [
    {
      label: '출발언어',
      dataIndex: 'sourceLanguage',
      type: 'select',
      options: Languages.map(lang => ({ value: lang.code, text: lang.kor })),
    },
    {
      label: '도착언어',
      dataIndex: 'targetLanguage',
      type: 'select',
      options: Languages.map(lang => ({ value: lang.code, text: lang.kor })),
    },
    {
      label: '번역종류',
      dataIndex: 'translateType',
      type: 'select',
      options: [
        { value: 'DOCUMENT', text: '문서번역' },
        { value: 'VIDEO', text: '영상번역' },
        { value: 'WEBTOON', text: '웹툰번역' },
        { value: 'NOVEL', text: '소설번역' },
        { value: 'REFERENCE', text: '발표자료번역' },
      ],
      required: true,
    },  
    {
      label: '분량',
      dataIndex: 'translateAmount',
      type: 'text',
      placeholder: '장, 단어 구분없이 입력',
      required: true,
    }, 
  ]
  const isClosedRecruit = status === "RECRUIT_CLOSED";
  const languageInputs = serviceType === "INTERPRET" ? interpretLangueageInputs : translateLanguageInputs;

  const inputs = [
    {
      label: 'IDX',
      dataIndex: 'idx',
      type: 'static',
      render: undefined,
    },
    {
      label: '프로젝트명',
      dataIndex: 'title',
      type: 'text',
      required: true,
    },
    {
      label: '고객계정IDX',
      dataIndex: 'owner',
      type: 'text',
      placeholder: '관리자계정 : 2764',
      required: true,
    },
    {
      label: '일정',
      dataIndex: 'workSchedule',
      type: 'text',
      required: true,
    },
    {
      label: '장소',
      dataIndex: 'workPlace',
      type: 'text',
      required: true,
    },
    {
      label: '서비스타입',
      dataIndex: 'serviceType',
      type: 'select',
      options: [
        { value: "TRANSLATE", text: "번역", },
        { value: "INTERPRET", text: "통역", },
      ],
      required: true,
    },
    ...languageInputs,
    {
      label: '마감일',
      dataIndex: 'dueDate',
      type: 'text',
      placeholder: 'yyyy-mm-dd',
      required: true,
    },
    {
      label: '마감시간',
      dataIndex: 'dueTime',
      type: 'text',
      placeholder: 'hh:mm',
      required: true,
    },
    {
      label: '분야',
      dataIndex: 'industry',
      type: 'text',
      placeholder: '통역기타 : 29, 번역기타 : 77',
      required: true,
    },  
    {
      type: 'blank',
    },  
    {
      label: '프로젝트비용',
      dataIndex: 'price',
      type: 'text',
      required: true,
      getValueFunc: () => (numberWithCommas(price))
    },
    {
      label: '기준매칭Fee(%)',
      dataIndex: 'matchingFee',
      type: 'text',
      required: true,
    },
    {
      label: '전문가기준요율',
      type: 'static',
      getValueFunc: () => (numberWithCommas(parseInt(price * (1 - matchingFee / 100))))
    },
    {
      label: '최저지원요율',
      type: 'static',
      getValueFunc: () => (numberWithCommas(parseInt(price * (1 - matchingFee / 100) * 0.8)))
    },
    {
      label: '필요인원',
      dataIndex: 'people',
      type: 'text',
      required: true,
    },
    {
      label: '지원자격',
      dataIndex: 'interpreterLevel',
      type: 'select',
      options: [
        { value: "NORMAL", text: "NORMAL" },
        { value: "EXPERT", text: "EXPERT" },
      ]
    },
    {
      label: '최종집행비용',
      dataIndex: '',
      type: 'static',
      getValueFunc: () => (numberWithCommas(totalCost))
    },
    {
      label: '최종수익률',
      dataIndex: '',
      type: 'static',
      getValueFunc: () => (totalCost > 0 ? ((price - totalCost)/price * 100).toFixed(2) + "%" : "")
    },
    {
      label: '진행상태',
      dataIndex: 'status',
      type: 'select',
      options: ProjectStatus.map(status => ({ value: status.name, text: status.kor })),
    },
    {
      type: "blank"
    },
    {
      label: '포스터',
      expand: true,
      type: 'custom',
      render: () => (
        <div>
          <img src={posterUrl} style={{ height: '240px' }} />
          { editMode && (
            <input type="file" onChange={onPosterFileSelected} style={{ width: "200px" }} className="ml-3" />
          )}
        </div>
      )
    },
    {
      label: '상세설명',
      dataIndex: '',
      expand: true,
      type: 'custom',
      required: true,
      placeholder: '테스트',
      render: () => editMode ? (
        <EditorContainer>
          <Editor
            previewStyle="horizontal"
            height="500px"
            initialEditType="wysiwyg"
            initialValue={introduce}
            ref={editorRef}
            autofocus={false}
          />
        </EditorContainer>
      ) : (
        <Viewer
          height='500px'
          initialValue={introduce || ""}/>
      )
    },
    {
      label: '고객사',
      dataIndex: 'companyName',
      type: 'text',
      required: true,
    },
    {
      label: '세금계산서',
      dataIndex: 'taxEmail',
      type: 'text',
    },
    {
      label: '담당자명',
      dataIndex: 'name',
      type: 'text',
      required: true,
    },
    {
      label: '연락처',
      dataIndex: 'phone',
      type: 'text',
      required: true,
    },
    {
      label: '지원자',
      dataIndex: '',
      expand: true,
      type: 'custom',
      render: project => (
        <Table
          dataSource={project.applicants}
          size="small"
          pagination={{ pageSize: 5 }}
          className="mt-5"
        >
          <Column
            title="순위"
            dataIndex="seq"
            key="seq"
          />
          <Column
            title="이름"
            dataIndex="name"
            render={(_, record) => (record.interpreter.name)}
          />
          <Column
            title="통역경력"
            key="interpretCareerCount"
            render={(_, record) => (record.interpreter.interpretCareerCount)}
          />
          <Column
            title="번역경력"
            key="name"
            render={(_, record) => (record.interpreter.translateCareerCount)}
          />
          <Column
            title="프로필"
            key="price"
            render={(_, record) => (<a href={`/applicant/@${record.interpreter.profileId}/${record.applyIdx}`} target="_blank">보기</a>)}
          />
          <Column
            title="이력서"
            key="expense"
            render={(_, record) => {
              return record.interpreter.resumeUrl
                ? (
                  <a
                    href={!editMode ? record.interpreter.resumeUrl : undefined}
                    className={editMode ? "disabled" : ""}
                    target="_blank"
                  >
                    다운로드
                  </a>
                )
                : "-"
            }}
          />
          <Column
            title="지원금액"
            key="bidAmount"
            render={(_, record) => (
              <a
                className={(status === "DONE" || editMode) ? "disabled" : ""}
                onClick={status === "DONE" ? undefined : () => onClickUpdateBidAmount(record.applyIdx)}
              >
                {numberWithCommas(record.bidAmount)}
              </a>)
            }
          />
          <Column
            title="채용"
            key="status"
            render={(_, record) => {
              const { status: applyStatus } = record;

              if(status === "DONE" && applyStatus === "ACCEPTED")
                return "채용됨";

              if(status === "DONE")
                return null;

              if(applyStatus === "ACCEPTED") {
                return (<a
                  className={(status === "DONE" || editMode) ? "disabled" : ""}
                  onClick={status === "DONE" ? undefined :() => hire(record.applyIdx, 'APPLIED')}
                >
                  채용 취소
                </a>)
              }
              if(applyStatus !== "ACCEPTED") {
                return (
                  <a
                    className={(status === "DONE" || editMode) ? "disabled" : ""}
                    onClick={status === "DONE" ? undefined :() => hire(record.applyIdx, 'ACCEPTED')}
                  >
                    채용하기
                  </a>)
              }
              return null
            }}
          />
        </Table>
      )
    },
    // {
    //   label: '최종 채용',
    //   dataIndex: '',
    //   expand: true,
    //   type: 'custom',
    //   render: project => (
    //     <Table
    //       dataSource={project.applicants}
    //       size="small"
    //       pagination={{ pageSize: 5 }}
    //       className="mt-5"
    //     >
    //       <Column
    //         title="순위"
    //         dataIndex="seq"
    //         key="seq"
    //       />
    //       <Column
    //         title="이름"
    //         dataIndex="name"
    //         render={(_, record) => (record.interpreter.name)}
    //       />
    //       <Column
    //         title="통역경력"
    //         key="interpretCareerCount"
    //         render={(_, record) => (record.interpreter.interpretCareerCount)}
    //       />
    //       <Column
    //         title="번역경력"
    //         key="name"
    //         render={(_, record) => (record.interpreter.translateCareerCount)}
    //       />
    //       <Column
    //         title="프로필"
    //         key="price"
    //         render={(_, record) => (<a href={`/applicant/@${record.interpreter.profileId}/${record.applyIdx}`} target="_blank">보기</a>)}
    //       />
    //       <Column
    //         title="이력서"
    //         key="expense"
    //         render={(_, record) => {
    //           return record.interpreter.resumeUrl
    //             ? (
    //               <a
    //                 href={!editMode ? record.interpreter.resumeUrl : undefined}
    //                 className={editMode ? "disabled" : ""}
    //                 target="_blank"
    //               >
    //                 다운로드
    //               </a>
    //             )
    //             : "-"
    //         }}
    //       />
    //       <Column
    //         title="지원금액"
    //         key="bidAmount"
    //         render={(_, record) => (
    //           <a
    //             className={(status === "DONE" || editMode) ? "disabled" : ""}
    //             onClick={status === "DONE" ? undefined : () => onClickUpdateBidAmount(record.applyIdx)}
    //           >
    //             {numberWithCommas(record.bidAmount)}
    //           </a>)
    //         }
    //       />
    //       <Column
    //         title="채용"
    //         key="status"
    //         render={(_, record) => {
    //           const { status: applyStatus } = record;

    //           if(status === "DONE" && applyStatus === "ACCEPTED")
    //             return "채용됨";

    //           if(status === "DONE")
    //             return null;

    //           if(applyStatus === "ACCEPTED") {
    //             return (<a
    //               className={(status === "DONE" || editMode) ? "disabled" : ""}
    //               onClick={status === "DONE" ? undefined :() => hire(record.applyIdx, 'APPLIED')}
    //             >
    //               채용 취소
    //             </a>)
    //           }
    //           if(applyStatus !== "ACCEPTED") {
    //             return (
    //               <a
    //                 className={(status === "DONE" || editMode) ? "disabled" : ""}
    //                 onClick={status === "DONE" ? undefined :() => hire(record.applyIdx, 'ACCEPTED')}
    //               >
    //                 채용하기
    //               </a>)
    //           }
    //           return null
    //         }}
    //       />
    //     </Table>
    //   )
    // },
    {
      label: '비고',
      dataIndex: '',
      expand: true,
      type: 'custom',
      render: project => (
        <textarea
          name="memo"
          value={memo}
          className="text-contents pa2"
          style={{ width: "100%", height: "150px", border: editMode ? "1px solid #ddd" : "none" }}
          onChange={e => onChangeInput(e.target.name, e.target.value)}
        />
      )
    },
  ]

  return (
    <div className="admin-contents">
      <Row>
        { editMode && (
          <>
            <Col md={2} style={{
              "display": "flex",
              "flexDirection": "column",
              "justifyContent": "flex-end"}}>
              <Button onClick={onSubmitUpdateProject} style={{width: "100%"}}>저장</Button>
            </Col>            
          </>
        )}
        { !editMode && (
          <Col md={2} style={{
            "display": "flex",
            "flexDirection": "column",
            "justifyContent": "flex-end"}}>
            <Button onClick={() => setEditMode(true)} style={{width: "100%"}}>수정</Button>
          </Col>
        )}
        { !editMode && isClosedRecruit && (
          <Col  md={{ offset: 5 }}>
            <Btn dw={'360px'} mw={'220px'} backgroundColor={"#0069ff"} color={"#ffffff"} border={"none"} onClick={ProjectView.inprogressProject}>프로젝트 시작</Btn>
          </Col>
        )} 
        { !editMode && !isClosedRecruit && (<Col md={{ size: 2, offset: 8 }} style={{
          "display": "flex",
          "flexDirection": "column",
          "justifyContent": "flex-end"}}>
          <Button onClick={() => setSendNotiData({})} style={{width: "100%"}}>일괄전송</Button>
        </Col>
        )}
      </Row>
      ※통역↔번역 변경할 경우 알림톡 발송안됨※
      <Row noGutters>
        <AdminForm
          data={projectData}
          editMode={editMode}
          inputs={inputs}
          onChangeInput={onChangeInput}
        />
      </Row>
      <SendNotiModal
        title={"알림톡 발송"}
        submitText={"발송"}
        isOpen={!!sendNotiData}
        toggle={() => setSendNotiData(undefined)}
        onConfirm={onConfirmNoti}
      />
    </div>
  )
};

export default inject(stores => ({
  memberStore: stores.memberStore,
  industryStore: stores.industryStore,
}))(observer(AdminProject));

import React from 'react'
import styled from 'styled-components'

const Image1 = styled.img`
  position: absolute;
  width: 297px;
  height: 550px;
  right: 0;
  top: 529px;
  object-fit: contain;
  
  @media (max-width: 934px) {
    display: none;
  }
`
const Image2 = styled.img`
  position: absolute;
  width: 293px;
  height: 329px;
  top: 71px;
  left: 0;
  object-fit: contain;
  
  @media (max-width: 934px) {
    display: none;
  }
`

export default () => (
  <>
    <Image1
      src="/images/back-project-edit-1.png"
      srcSet="/images/back-project-edit-1@2x.png 2x, /images/back-project-edit-1@3x.png 3x"
    />
    <Image2
      src="/images/back-project-edit-2.png"
      srcSet="/images/back-project-edit-2@2x.png 2x, /images/back-project-edit-2@3x.png 3x"
    />
  </>
)


import React, {useContext, useEffect, useRef} from "react";
import {Link} from "react-router-dom";
import classNames from "classnames";
import {FadeContext} from "../../../context/fadeContext";


const HomeNewSystem = ({ typeC }) => {
  const ref = useRef(null);
  const {addRef, fadeInfo} = useContext(FadeContext);

  useEffect(() => {
    if(ref && ref.current) {
      addRef(ref);
    }
  }, [ref]);
  return (
    <div ref={ref} className={classNames('fade', {show: fadeInfo.get(ref)})}>
      <section className={`wable-system ${typeC ? "wable-system-c" : ""}`}>
        <div className="inner">
          <div className="section-title">
            {"숙련된 전문가들의 "}
            <br/>
            믿을 수 있는 탄탄한 경험
          </div>
          <div className="cards">
            <div className="box">
              <div className="card-title">
                예상 견적 즉시 확인
              </div>
              <div className="image-wrap">
                <div>
                  <img
                    src="/images/home-wable-system-1.png"
                    srcSet="/images/home-wable-system-1@2x.png 2x, /images/home-wable-system-1@3x.png 3x"
                  />
                </div>
              </div>
              <div className="card-desc">
                간단한 정보 입력만으로<br/>
                예상 견적 즉시 확인
              </div>
              <Link to="/request-quote">
                <button className="text-button">
                  지금 확인하기 →
                </button>
              </Link>
            </div>
            <div className="box">
              <div className="card-title">
                최적의 통·번역사 채택
              </div>
              <div className="image-wrap">
                <img
                  src="/images/home-wable-system-2.png"
                  srcSet="/images/home-wable-system-2@2x.png 2x, /images/home-wable-system-2@3x.png 3x"
                />
              </div>
              <div className="card-desc">
                다양한 분야별 전문가들을 한 눈에 비교,<br/>
                테스트 번역 시스템을 통한 신중한 선택
              </div>
            </div>
            <div className="box">
              <div className="card-title">
                검증된 전문 통역사 인재풀
              </div>
              <div className="image-wrap">
                <img
                  src="/images/home-wable-system-3.png"
                  srcSet="/images/home-wable-system-3@2x.png 2x, /images/home-wable-system-3@3x.png 3x"
                />
              </div>
              <div className="card-desc">
                통번역대학원 출신으로만 구성된<br/>
                전문 통역사 인재풀
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
};

export default HomeNewSystem;
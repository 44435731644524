import React, {useState} from "react";
import "./ProjectApplyModal.scss";
import Modal from './Modal';
import styled from 'styled-components';
import {numberWithCommas} from "../../../utils";

const Label = styled.label`
  height: 19px;
  font-family: Noto Sans KR;
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.27;
  letter-spacing: normal;
  margin: 0;
  text-align: left;
  color: #666666;
  
  @media (max-width: 934px) {
  }
  
`

const ContentLabel = styled.label`
  width: auto;
  font-family: Montserrat;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.27;
  margin: 0;
  color: #333333;
  
  @media (max-width: 934px) {
  }
`

const PriceLabel = styled.span`
  width: auto;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.27;
  margin: 0;
  color: #333333;
  
  @media (max-width: 934px) {
  }
`

const SubLabel = styled.span`
  width: auto;
  font-family: Montserrat;
  opacity: 0.5;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  margin: 0;
  line-height: 1.27;
  color: #333333;
  
  @media (max-width: 934px) {
  }
`

const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  
  @media (max-width: 934px) {
    height: 60px;
  }
`


const Col = styled.div`
  position: relative;
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: stretch;
  flex-wrap: wrap;
  background-color: #ffffff;
  
  @media (max-width: 934px) {
  }
`

const ModalRowCol = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: flex-start;

  @media (max-width: 934px) {
    flex-direction: column;
  }
`


const InputDiv = styled.div`
  position: relative;
  display: flex;
  width: 204px;

  align-items: center;

  @media (max-width: 934px) {
  }
`
const InputMaskDiv = styled.div`
  position: relative;
  display: flex;
  width: 204px;

  align-items: center;

  @media (max-width: 934px) {
    position: absolute;
    width: 254px;
    right: 20px;
  }
`

const Space = styled.div`
  width: ${props => props.dw || 0}px;
  height: ${props => props.dh || 0}px;
  
  @media (max-width: 768px) {
    width: ${props => props.mw || 0}px;
    height: ${props => props.mh || 0}px;
  }
`

const InputComponent = styled.input`
  width: ${props => props.dw || '100%'};
  height: 50px;
  padding: 15px 24px;
  border-radius: 4px;
  border: solid 1px #dddddd;
  
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 18px;
  letter-spacing: normal;
  text-align: left;
  color: #333333;
  
  :focus {
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.08);
    border: solid 2px #0069ff;
    opacity: 1;  
    outline: 0;
  }
  ::placeholder {
    opacity: 0.5;
  }
  
  @media (max-width: 934px) {
    width: ${props => props.mw || '100%'};
    height: 60px;
    padding: 20px 21px;
  }
`

export default (props) => {
  const [tempBidAmount, setTempBidAmount] = useState("");
  const [tempPrice, setTempPrice] = useState(0);

  const onChangeBidAmount = value => {
    var tempValue = value;
    tempValue = tempValue.replace(/[^0-9]/g,'');   // 입력값이 숫자가 아니면 공백
    tempValue = tempValue.replace(/,/g,'');          // ,값 공백처리

    setTempPrice(parseInt(tempValue * (1 - 0.033)));
    setTempBidAmount(numberWithCommas(tempValue));

    props.applyRequest.bidAmount = numberWithCommas(tempValue);
  };

  const projectPrice = parseInt(props.project.price * (1-props.project.matchingFee/100));
  const projectMinPrice = parseInt(props.project.price * (1-props.project.matchingFee/100)*0.8);
  const people = props.project.people;

  return (
    <Modal
      title={"업무지원"}
      renderChildren={(setRef) => (
        <>
          <Space dh={25} mh={10} />
          <Row>
            <Label>업무</Label>
            <ContentLabel>{props.project.title}</ContentLabel>
          </Row>
          <Space dh={20} mh={10} />
          <Row>
            <Label>기준요율</Label>
            <ContentLabel><PriceLabel>{numberWithCommas(projectPrice/people)}</PriceLabel>원</ContentLabel>
          </Row>
          <Space dh={20} mh={10} />
          <Row>
            <Label>최저요율</Label>
            <ContentLabel><PriceLabel>{numberWithCommas(projectMinPrice/people)}</PriceLabel>원</ContentLabel>            
          </Row>
          <SubLabel>※최저 요율보다 낮은 금액으로 지원할 수 없습니다.</SubLabel>
          <Space dh={20} mh={10} />
          <Row>
            <Label>지원 요율</Label>
              <InputDiv>
                <InputComponent
                  type="text"
                  name="bidAmount"
                  size="small"
                  onChange={e => onChangeBidAmount(e.target.value)}
                  placeholder={props.applyRequest.bidAmount}
                  ref={setRef}
                  value={tempBidAmount}
                />
                <Space dw={5} mw={5} />
                <ContentLabel>원</ContentLabel>
              </InputDiv>
          </Row>
          <Space dh={20} mh={10} />
          <Row>
            <Label>실수령액</Label>
            <Col>
            <ContentLabel><PriceLabel>{numberWithCommas(tempPrice)}</PriceLabel>원</ContentLabel>
            <SubLabel>※ 원천징수(3.3%)제외 금액</SubLabel>
            </Col>
          </Row>

          <Space dh={30} mh={10} />

        </>
      )}
    {...props} />
  )
};


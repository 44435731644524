import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import React, {useContext, useEffect, useRef} from "react";
import classNames from "classnames";
import {FadeContext} from "../../../context/fadeContext";

const wablePortfolio = [
  { title: "글로벌 임원 회의 통역", desc: " ", image: "/images/portfolio_kia.jpg", href: "https://www.kia.com/" },
  { title: "클라우드 업체 개발회의 및 대표회의 통역", desc: " ", image: "/images/portfolio_bespin.jpg", href: "https://www.bespinglobal.com/" },
  { title: "물류시스템 교육 통역/번역", desc: " ", image: "/images/portfolio_lx.jpg", href: "https://www.lxpantos.com/" },
  { title: "연세대학교 의공학 강의/세미나 통번역", desc: " ", image: "/images/portfolio_yonsei.jpg", href: "http://yonsei.ac.kr/" },
  { title: "건설분야 기술제안영상 번역 및 자막삽입 작업 ", desc: " ", image: "/images/portfolio_dl.jpg", href:"https://www.dlconstruction.co.kr/"},
  { title: "의료AI 서비스 교육자료 번역 및 자막삽입", desc: " ", image: "/images/portfolio_lunit.jpg", href: "https://www.lunit.io/ko" },    
  { title: "부동산 리츠 운용사 사업보고서", desc: " ", image: "/images/portfolio_realestate.jpg", href: "" },
  { title: "의학분야 강연 스크립트 번역", desc: " ", image: "/images/portfolio_medical.jpg", href: "" },  
];



const HomeSection5 = () => {
  const ref = useRef(null);
  const {addRef, fadeInfo} = useContext(FadeContext);

  useEffect(() => {
    if(ref && ref.current) {
      addRef(ref);
    }
  }, [ref]);

  const drawNews = list => {
    return list.map((news, idx) => {
      if(news.href) {
        return (
          <div className="news-card" key={idx}>
            <a
              href={news.href}
              rel="noopener noreferrer"
              target="_blank"
            >
              {drawNewsInner(news)}
            </a>
          </div>
        )
      } else {
        return (
          <div className="news-card" key={idx}>
            {drawNewsInner(news)}
          </div>
        )
      }
    })
  };


  const drawNewsInner = item => {
    return (
      <div>
        <img src={item.image} alt={item.title} />
        <div className="card-title">
          {item.title}
        </div>
        <div className="card-desc">
          {item.desc}
        </div>
      </div>
    )
  };


 
  return (
    <div ref={ref} className={classNames('fade', {show: fadeInfo.get(ref)})}>
      <section className="inner wable-news">
        <div className="inner news-wrap">
          <div className="title">와블의 주요 포트폴리오</div>
          <div className="news-card-wrap">
            {drawNews(wablePortfolio)}
          </div>
        </div>              
      </section>                
    </div>
  )      
      
};

export default HomeSection5;

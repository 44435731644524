import {action, observable} from "mobx";
import axios from "axios";
import _ from "lodash";

class IndustryStore {
  @observable interpretIndustries = undefined;
  @observable translateIndustries = undefined;
  @observable allIndustries = undefined;

  constructor(root) {
    this.root = root;
  }

  @action
  load = () => {
    return axios.get(`/api/industries`).then(response => {
      const data = response.data;

      this.allIndustries = data;
      this.translateIndustries = _.filter(data, p => p.industryType === "TRANSLATE");
      this.interpretIndustries = _.filter(data, p => p.industryType === "INTERPRET");
    });
  }

}

export default IndustryStore;

import React from 'react'
import styled from 'styled-components'
import AddButton from './AddButton';
import Space from '../../../atoms/Space';
import moment from 'moment';

const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
`
const Col = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0;
`
const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #dddddd;
`
const Container = styled.div`
  width: 100%;
  padding: 30px 30px 20px 30px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  
  @media (max-width: 934px) {
    padding: 30px 20px 20px 20px;
  }
`
const Title = styled.div`
  font-family: Noto Sans KR;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #333333;
`

const ItemTitle = styled.div`
  font-family: Noto Sans KR;
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.27;
  letter-spacing: normal;
  text-align: left;
  color: #0069ff;
`
const ItemPeriod = styled.div`
  opacity: 0.5;
  font-family: Noto Sans KR;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: left;
  color: #333333;
  padding-top: 4px;
`
const ItemDesc = styled.div`
  font-family: Noto Sans KR;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: left;
  color: #333333;
  padding-top: 4px;
`

export default ({ canDelete, canEdit, title, onClickAdd, items = [], onClickEdit }) => {

  return (
  <Container>
    <Row>
      <Col>
        <Title>{title}</Title>
      </Col>
      {canEdit && (<AddButton onClick={onClickAdd} />)}
    </Row>
    <Space dh={10} mh={10} />
    <Divider />
    {
      items.length === 0 && (
        <>
          <Space mh={10} dh={21} />
          <Title>등록 안됨</Title>
          <Space mh={10} dh={21} />
        </>
      )
    }
    {
      items.map((company, idx) => {
        const title= company.name;
        const period=`${moment(company.startDate).format("YYYY-MM")} ~ `
          + `${company.endDate ? moment(company.endDate).format("YYYY-MM") : ""}`;
        return(
          <>
            {idx > 0 && (<Divider />)}
            <Row style={{ paddingTop: 10, paddingBottom: 8 }}>
              <Col>
                <ItemTitle>{title}</ItemTitle>
                <ItemPeriod>{period}</ItemPeriod>
              </Col>
              {
                canDelete && (
                  <Col style={{ justifyContent: 'center', paddingLeft: 8, paddingRight: 10 }}>
                  </Col>
                )
              }
            </Row>
          </>
      )}
      )
    }
  </Container>
)}
import React, {useEffect, useState} from 'react';
import Label from './Label';
import Select from './Select';
import Space from '../../../atoms/Space';
import Input from './Input';
import InputMask from 'react-input-mask';
import Languages from "../../../../values/Languages";
import styled from 'styled-components'
import Modal from 'react-modal';
import "./Modal.scss";
import {inject, observer} from 'mobx-react';
import _ from "lodash";


export const KorLanguages = {
  "ENGLISH": "영어",
  "KOREAN": "한국어",  
  "CHINESE": "중국어",
  "JAPANESE": "일본어",
  "GERMAN": "독일어",
  "RUSSIAN": "러시아어",
  "VIETNAMESE": "베트남어",
  "SPANISH": "스페인어",
  "SWEDISH": "스웨덴어",
  "ARABIC": "아랍어",
  "ITALIAN": "이탈리아어",
  "THAI": "태국어",
  "TURKISH": "터키어",
  "FRENCH": "프랑스어",
  "PORTGUESE": "포르투갈어",
  "HINDI": "힌디어",
  "INDONESIAN": "인도네시아어",
  "OTHER": "기타언어",
  };

const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  
  @media (max-width: 934px) {
    height: 60px;
  }
`

const Col = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: stretch;
  flex-wrap: wrap;
  background-color: #ffffff;
  
  @media (max-width: 934px) {
  }
`

const ModalRowCol = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: flex-start;

  @media (max-width: 934px) {
    flex-direction: column;
  }
`

const InputNameDiv = styled.div`
  position: relative;
  width: 90%;

  @media (max-width: 934px) {
    width: 85%;
  }
`

const SubmitButton = styled.button`
    position: absolute;
    top: 5px;
    right: 5px;
    width: 76px;
    height: 40px;
    border-radius: 4px;
    background-color: #333333;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 35px;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;

    @media (max-width: 934px) {
      height: 50px;
    }
`

const SubText = styled.label`
  position: relative;
  font-family: Noto Sans KR;
  font-size: 15px;
  color: #666666;
  text-align: left;
  line-height: 1.27;
  margin: 15px 10px;

  @media (max-width: 934px) {
    font-size: 14px;
    margin: 10px 10px 0px 0px;
  }
`


const EtcDiv = styled.div`
  position: relative;
  width: 322px;

  @media (max-width: 934px) {
    margin-bottom: 10px;
    width: 100%;
  }
`

const InputMaskDiv = styled.div`
  position: relative;
  width: 165px;

  @media (max-width: 934px) {
    width: 300px;
  }
`

const SelectedButton = styled.img`
  width: 36px;
  height: 36px;

  cursor: pointer;
  
  @media (max-width: 934px) {
  } 
`
const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  z-index: 9999;
  
  @media (max-width: 934px) {

  }
`
const BottonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const Bottom = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  
  @media (max-width: 934px) {
    justify-content: space-between;
  }
`

const CloseButton = styled.img`
  position: absolute;
  top: 0px;
  right: 0px;
  width: 58px;
  height: 58px;
  object-fit: contain;
`

const SaveButton = styled.button`
  width: 92px;
  height: 52px;
  line-height: 52px;
  font-family: Noto Sans KR;
  text-decoration: none;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  background-color: #0069ff;
  border-radius: 4px;
  border: none;
  :focus {
    border: none;
    outline: 0;
  }
  
  @media (max-width: 934px) {
    width: 140px;
    font-size: 16px;
    height: 48px;
  } 
`
const CancelButton = styled(SaveButton)`
  color: #999999;
  background-color: #eeeeee;
`

const DeleteButton = styled(SaveButton)`
  color: #ffffff;
  background-color: #aaaaaa;
`

const Title = styled.div`
  font-family: Noto Sans KR;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #333333;
`
const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #dddddd;
`

const IndustryCNTR = styled(Row)`
  display: flex;
  height: auto;
  width: 443px;
`

const IndustryBox = styled.div`
  position: relative;
  width: auto;
  height: 50px;
  margin-right: 10px;
  margin-bottom: 5px;
  padding: 16px 20px;
  border-radius: 4px;
  background-color: #f2f3f6;

  font-family: Noto Sans KR;
  font-weight: bold;
  font-size: 15px;
  color: #333333;
  text-align: left;
  line-height: 1.27;

  @media (max-width: 934px) {
    height: 45px;
    font-size: 14px;
    padding: 13px 20px;
  }
`

const IndustryText = styled.label`
  position: relative;
  font-family: Noto Sans KR;
  font-size: 15px;
  font-weight: bold;
  color: #333333;
  text-align: left;
  line-height: 1.27;
  margin: 0;

  @media (max-width: 934px) {
    font-size: 13px;
  }
`

const TargetLanguageContainer = styled(Row)`
  height: auto;
  width: 443px;
`

const TargetLanguageBox = styled.div`

  position: relative;
  width: auto;
  height: 50px;
  margin-right: 10px;
  margin-bottom: 5px;
  padding: 16px 20px;
  border-radius: 4px;
  background-color: #f2f3f6;
  align-items: center;

  font-family: Noto Sans KR;
  font-weight: bold;
  font-size: 15px;
  color: #333333;
  text-align: left;
  line-height: 1.27;

  @media (max-width: 934px) {
    height: 45px;
    font-size: 14px;
    padding: 10px 10px 10px 20px
  }
`

const TargetLanguageText = styled.label`
  position: relative;
  font-family: Noto Sans KR;
  font-size: 15px;
  font-weight: bold;
  color: #333333;
  text-align: left;
  line-height: 1.27;
  margin: 0;
  margin-right: 15px;

  @media (max-width: 934px) {
    font-size: 13px;
  }
`

const CancelImg = styled.img`
  position: relative;
  width: 12px;
  height: 12px;
  top: -2px;
  left: 11px;
  cursor: pointer;
`


const TranslateCareerUpdateModal = ({isOpen, industries, toggle, onConfirm, onDelete, updateModalStore}) => {
  const [selected, setSelected] = useState(false);
  const { translateIdx, translateCareer } = updateModalStore;
  const [translate, setTranslate] = useState({...translateCareer});
  const [ targetLanguageList, setTargetLanguageList] = useState((translateCareer.targetLanguage.split("/")[0] === "" ? [] : translateCareer.targetLanguage.split("/"))||[]);
  const [industryList, setIndustryList] = useState((translateCareer.industry.split(",")[0] === "" ? [] : translateCareer.industry.split(","))||[]);

  const modaltoggle = () => {
    clearInputs();
    toggle();
  };

  const clearInputs = () => {
    setTranslate({});
  }

  const onChangeInput = (e) => {
    const { value, name } = e.target; // 우선 e.target 에서 name 과 value 를 추출
    setTranslate({
      ...translate, // 기존의 input 객체를 복사한 뒤
      [name]: value // name 키를 가진 값을 value 로 설정
    });
  };

  const onChangeSelect = (e) => {
    const { value, name } = e.target; // 우선 e.target 에서 name 과 value 를 추출
    setTranslate({
      ...translate, // 기존의 input 객체를 복사한 뒤
      [name]: value // name 키를 가진 값을 value 로 설정
    });
    if (name==="sourceLanguage" && value!=="KOREAN") {
      setTargetLanguageList(["KOREAN"]);
    }
    else if (name==="sourceLanguage" && value==="KOREAN") {
      setTargetLanguageList([]);
    }
  };

  
  const onClickSelected = (e) => {
    setTranslate({
      ...translate, // 기존의 input 객체를 복사한 뒤
      "selectedCareer": !e.target.selected // name 키를 가진 값을 value 로 설정
    });
    setSelected(!e.target.selected);
  };

  const onChangeTargetLanguage = (e) => {
    const {value} = e.target;
    setTargetLanguageList((tl)=>[...tl, value]);
  };

  const onDeleteTargetLanguage = (value) => {
    setTargetLanguageList(targetLanguageList.filter(language => language !== value));
  };
  
  const onChangeIndustry = (e) => {
    const {value} = e.target;
    setIndustryList([...industryList, value]);
  }

  const onDeleteIndustry =  (value) => {
    setIndustryList(industryList.filter(industry => industry !== value));
  }

  useEffect(()=> {
    const newIndustry = industryList.join(",");
    setTranslate({
      ...translate, // 기존의 input 객체를 복사한 뒤
      "industry": newIndustry // name 키를 가진 값을 value 로 설정
    });
  },[industryList]);

  useEffect(()=>{
    const newTargetLang = targetLanguageList.join("/");
    setTranslate({
      ...translate,
      "targetLanguage" : newTargetLang
    })
  },[targetLanguageList]);



  return (
    <Modal
    isOpen={isOpen}
    preventScroll={true}
    shouldFocusAfterRender={false}
    onRequestClose={toggle}
    className="Modal"
    overlayClassName="Overlay"
    >
    <Container>
      <CloseButton src={"/images/icon-close-profile-modal.svg"} onClick={modaltoggle} />
      <Space mh={12} />
      <Title>번역 업무 수정</Title>
      <Space dh={16} mh={16} />
      <Divider />
      <Space dh={16} mh={16} />
        <Space mh={14.5} />
        <Label>업무 제목</Label>
        <Row style={{justifyContent: "space-between"}}>
          <InputNameDiv>
            <Input
              name="name"
              style={{ width: '100%'}}
              value={translate.name}
              onChange={onChangeInput}
            />
          </InputNameDiv>
          <SelectedButton 
            name="selectedCareer"
            src={selected ? "/images/btn-pin-selected.svg" : "/images/btn-pin-unselected.svg"}
            selected={selected}
            onClick={onClickSelected}
          >
          </SelectedButton>
        </Row>
        <Space dh={20} mh={20} />
        <Label>고객사</Label>
        <Input
          name="company"
          style={{ width: '100%' }}
          value={translate.company}
          onChange={onChangeInput}
        />
        <Space dh={20} mh={20} />
        <Label>출발어</Label>
        <Select
          name="sourceLanguage"
          value={translate.sourceLanguage}
          onChange={onChangeSelect}
        >
          <option value="" disabled selected hidden>언어를 선택해주세요.</option>
          {
            Languages.map(item => (
              <option value={item.code} key={item.code}>{item.kor}</option>
            ))
          }
        </Select>
        <Space dh={20} mh={20} />
        <Label>도착어</Label>
        <Select
          id="TargetLanguage"
          name="targetLanguage"
          value={translate.targetLanguage}
          onChange={onChangeTargetLanguage}
          disabled = {(translate.sourceLanguage != "KOREAN" || translate.targetLanguage.split('/').length == 10) ? true : false}
        >
          <option value="" disabled selected>언어를 선택해주세요.</option> 
          {
            Languages.map(item => (
              <option id={item.id} value={item.code} key={item.code}>{item.kor}</option>
            ))
          }
        </Select>
        <Space dh={10} mh={10} />
        { translate.sourceLanguage == "KOREAN" && (
          <TargetLanguageContainer>
            {targetLanguageList.map((language,idx)=>{
                return (
                  <TargetLanguageBox>
                      <TargetLanguageText>{KorLanguages[language]}</TargetLanguageText>
                      <CancelImg src={'/images/icon-closed.svg'} value={language} onClick={()=>onDeleteTargetLanguage(language)}></CancelImg>
                  </TargetLanguageBox>
                );
            })}
          </TargetLanguageContainer>
        )}
        <Space dh={20} mh={20} />
        <Label>관련분야</Label>
        <ModalRowCol>
          <Select
            id="industry"
            name="industry"
            value={translate.industry}
            onChange={onChangeIndustry}
            disabled={industryList.length === 3 ? true : false}
          >
            <option value="" disabled selected>관련분야</option>
            {
              industries && industries.map(key => (
                <option
                  key={key.idx}
                  value={key.idx}
                >
                  {key.industryName}
                </option>
              ))}
          </Select>
          <Space dw={10} mh={10}/>
        </ModalRowCol>
        <Space dh={10}/>
        <IndustryCNTR id="IndustryContainer">
          {
            industryList.map((idx) => {
              const industry =  _.find(industries, i => (i.idx == idx))
              return (
                <IndustryBox id='industry'>
                    <IndustryText id="industryText">{industry && industry.industryName}</IndustryText>
                    <CancelImg src={'/images/icon-closed.svg'} value={idx} onClick={()=>onDeleteIndustry(idx)}></CancelImg>
                </IndustryBox>
              )
            })
          }
        </IndustryCNTR>  
        <Space dh={20} mh={20} />
        <Label>업무 날짜</Label>
        <ModalRowCol>
          <InputMaskDiv>
            <InputMask
              mask="9999-99"
              maskPlaceholder="yyyy-mm"
              value={translate.startDate}
              onChange={onChangeInput}
              children={<Input
                type="text"
                placeholder={"ex. 2021-02"}
                name="startDate"
              />}
              style={{
                width: '100%'
              }}
            />
          </InputMaskDiv>
          <SubText>연도, 월 6자리 숫자로 입력해주세요.</SubText>
        </ModalRowCol>
        <Space mh={26} />
        <Space dh={24} mh={37} />
        <BottonContainer>
          <DeleteButton onClick={() => onDelete(translateIdx)}>삭제</DeleteButton>
          <Bottom>
            <CancelButton onClick={modaltoggle}>취소</CancelButton>
            <Space dw={20} mw={0} />
            <SaveButton onClick={() => onConfirm(translateIdx, translate)}>저장</SaveButton>
          </Bottom>
        </BottonContainer>
        <Space mh={23} />
    </Container>
    </Modal>
  )
};

export default inject("updateModalStore")(observer(TranslateCareerUpdateModal));
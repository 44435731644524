import React, {useEffect, useState} from "react";
import _ from "lodash";

import {Button} from "../ProjectEdit/components";
import {Col, Row} from "reactstrap";
import Input from "../Admin/components/Input";
import axios from "axios";
import { Table, } from 'antd';
import {memberTypeKorean} from "../../../utils";
import {inject, observer} from "mobx-react";

const { Column, } = Table;

const AdminMemberList = ({memberStore, history}) => {
  const [ searchForm, setSearchForm ] = useState({})
  const [ results, setResults ] = useState([]);

  const { id } = memberStore.member;
  if(id !== "sprofile616@naver.com" && id !== "interpreter2@naver.com") {
    window.alert("비정상적인 접근입니다.")
    memberStore.logout()
    window.location.href = "/login"
  }

  useEffect(() => {
    search()
  }, [])

  const onChange = (event) => {
    const { name, value } = event.target;
    const next = {
      ...searchForm,
      [name]: value
    }

    setSearchForm(next)
  };

  const search = () => {
    const params = _.pickBy(searchForm, (value) => (!!value));

    axios.get(`/api/admin/members`, { params }).then(res => {
      const { items } = res.data;
      setResults(items);
    })
  }

  const onClickRow = (record, rowIndex) => {
    const { idx: memberIdx } = record
    window.open(`/admin/members/${memberIdx}?popup=true`,`member-${memberIdx}`,'width=1000,height=' + window.screen.height);
  }


  return (
    <div className="admin-contents">
      <div>
        <Row>
          <Col md={10}>
            <Row>
              <Col md={2}>
                IDX
              </Col>
              <Col md={4}>
                <Input
                  name="idx"
                  value={searchForm.idx || ""}
                  onChange={onChange}
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={2}>
                이름
              </Col>
              <Col md={4}>
                <Input
                  name="name"
                  value={searchForm.name || ""}
                  onChange={onChange}
                />
              </Col>
              <Col md={2}>
                아이디
              </Col>
              <Col md={4}>
                <Input
                  name="id"
                  value={searchForm.id || ""}
                  onChange={onChange}
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={2}>
                전화번호
              </Col>
              <Col md={4}>
                <Input
                  name="phone"
                  value={searchForm.phone}
                  onChange={onChange}
                />
              </Col>
              <Col md={2}>
                회원유형
              </Col>
              <Col md={4}>
                <select
                  name={"type"}
                  value={searchForm.type}
                  onChange={onChange}
                  className="tp-select tp-select--small"
                >
                  <option value="">전체</option>
                  <option value="INTERPRETER">통/번역사</option>
                  <option value="CLIENT">고객사</option>
                </select>
              </Col>
            </Row>
          </Col>
          <Col md={2} style={{
            "display": "flex",
            "flexDirection": "column",
            "justifyContent": "flex-end"}}>
            <Button onClick={search} style={{width: "100%"}}>조회</Button>
          </Col>
        </Row>
      </div>
      <Table
        dataSource={results}
        size="small"
        pagination={{ defaultPageSize: 15 }}
        className="mt-5"
        onRow={(record, rowIndex) => {
          return {
            onClick: () => onClickRow(record, rowIndex),
          };
        }}
      >
        <Column title="IDX" dataIndex="idx" key="idx" />
        <Column title="이름" dataIndex="name" key="name" />
        <Column
          title="회원타입"
          key="" INTERPRETER
          render={(_, record) => memberTypeKorean(record.type) }
        />
        <Column title="아이디" dataIndex="email" key="" />
        <Column title="연락처" dataIndex="phone" key="" />
        <Column
          width="200px"
          title="계좌번호"
          key="bank"
          render={(_, record) => (record.bankUrl || "")}
        />
      </Table>
    </div>
  )
};

export default inject(stores => ({
  memberStore: stores.memberStore
}))(observer(AdminMemberList));

import React, {useState} from 'react';
import {inject, observer} from "mobx-react";
import "./Header.scss";
import {Collapse, Nav, Navbar, NavItem} from "reactstrap";
import {Link} from 'react-router-dom';
import {Icon} from "evergreen-ui";
import CircleBorderButton from "../atoms/CircleBorderButton";
import TertiaryButton from "../atoms/TertiaryButton";

const Header = ({ memberStore, location }) => {
  const [isOpen, setIsOpen] = useState(false);
  const close = () => {
    setIsOpen(false);
  };
  let canEdit = false;
  if (memberStore.member) {
    canEdit = true;
  }
  
  if (location.pathname.indexOf("@") >= 0) {
    const id = location.pathname.replace("/@", "");
    if (memberStore.member) {
      canEdit = memberStore.member.profileId === id;
    }
  }
  return (
    <div className="Header fixed">
      {isOpen && (
        <div className="blinder" onClick={close} />
      )}
      <div className="headerinner">
        <Navbar color="white" light expand="lg">
          <div className="mr5">
            <div className="bi">
              <a href="/" className="ga-gnb-bi">
                <img src="/images/wable-bi.png" srcSet="/images/wable-bi.png, /images/wable-bi@2x.png 2x" />
              </a>
            </div>
          </div>
          <div className="only-mobile">
            <div className="flex align-items-center">
              {!memberStore.member && (
                <Nav navbar>
                  <NavItem>
                    <Link to="/join">
                      <TertiaryButton
                        onClick={close}
                        small
                        className="ga-gnb-join-for-mobile"
                      >
                        이용하기
                      </TertiaryButton>
                    </Link>
                  </NavItem>
                </Nav>
              )}
              <div
                onClick={() => setIsOpen(!isOpen)}
                style={{ padding: "8px 16px" }}
              >
                <img
                  src="/images/hamburger.svg"
                  alt="메뉴"
                  width="24px"
                  height="24px"
                />
              </div>
            </div>
          </div>
          <Collapse isOpen={isOpen} navbar>
            <Nav navbar>
              <Link to="/about" onClick={close}>
                <CircleBorderButton
                  active={location.pathname === "/about"}
                  className="ga-gnb-aboutinterpret"
                >
                  전문 통역
                </CircleBorderButton>
              </Link>
              <Link to="/about-translate" onClick={close}>
                <CircleBorderButton
                  active={location.pathname === "/about-translate"}
                  className="ga-gnb-abouttranslate"
                >
                  기업전문 번역
                </CircleBorderButton>
              </Link>
              {/* <Link to="/about-hire" onClick={close}>
                <CircleBorderButton
                  active={location.pathname === "/about-hire"}
                  className="ga-gnb-abouthire"
                >
                  전문가 상주서비스
                </CircleBorderButton>
              </Link> */}
              <Link to="/pricing" onClick={close}>
                <CircleBorderButton
                  active={location.pathname === "/pricing"}
                  className="ga-gnb-pricing"
                >
                  편의서비스
                </CircleBorderButton>
              </Link>
            </Nav>
            {canEdit  && (
              <Nav className="ml-auto" navbar>
                <Link to="/projects/recruits" onClick={close}>
                  <div className="ml2">
                    <CircleBorderButton
                      active={location.pathname === "/projects/recruits"}
                      className="ga-gnb-projects"
                    >
                      <span style={{ position: 'relative', top: '-2px', marginRight: '2px' }}>
                        <Icon icon="projects" size={12} />
                      </span>
                      {" 내 프로젝트"}
                    </CircleBorderButton>
                  </div>
                </Link>
                {memberStore.member.type === "INTERPRETER" && (
                  <Link
                    to="/me"
                    onClick={close}
                  >
                    <div className="ml2">
                      <CircleBorderButton
                        active={location.pathname === "/me"}
                        className="ga-gnb-me"
                      >
                        <span style={{ position: 'relative', top: '-2px', marginRight: '2px' }}>
                          <Icon icon="mugshot" size={12} />
                        </span>
                        {" 내 프로필"}
                      </CircleBorderButton>
                    </div>
                  </Link>
                )}
                <Link to="/settings" onClick={close}>
                  <div className="ml2">
                    <CircleBorderButton
                      active={location.pathname === "/settings"}
                      className="ga-gnb-setting"
                    >
                      <span style={{ position: 'relative', top: '-2px', marginRight: '2px' }}>
                        <Icon icon="cog" size={12} />
                      </span>
                      {" 계정 설정"}
                    </CircleBorderButton>
                  </div>
                </Link>
              </Nav>
            )}
            {!memberStore.member && (
              <Nav className="ml-auto" navbar>
                <NavItem>
                  <div className="only-pc">
                    <Link to="/join">
                      <TertiaryButton
                        onClick={close}
                        className="ga-gnb-join-for-pc"
                      >
                        이용하기
                      </TertiaryButton>
                    </Link>

                  </div>
                </NavItem>
              </Nav>
            )}
          </Collapse>
        </Navbar>
      </div>
    </div>
  )
};

export default inject(stores => ({
  memberStore: stores.memberStore
}))(observer(Header));

import React, { useState } from "react";
import Modal from '../AdminIndustryList/Modal';
import {Col, FormGroup, Label} from "reactstrap";


export default (props) => {
  const [ form, setForm ] = useState({
    target: '',
    type: '',
  })

  const onChange = (name, value) => {
    const next = {
      ...form,
      [name]: value
    }
    setForm(next)
  }

  return (
    <Modal
      renderChildren={(setRef) => (
        <>
          <div style={{ width: "100%" }}>
            <FormGroup row>
              <Label md={4}>대상</Label>
              <Col md={8}>
                <select
                  name={"target"}
                  value={form.target}
                  onChange={(e) => onChange(e.target.name, e.target.value)}
                  className="tp-select tp-select--small"
                >
                  <option value="">---</option>
                  <option value="LANGUAGE">해당 언어 전체 전문가</option>
                  <option value="APPLICANTS">지원자 전체</option>
                  <option value="APPLICANTS_WITHOUT_ACCEPTED">미채용자 전체</option>
                  <option value="ACCEPTED">채용자</option>
                </select>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label md={4}>발송 템플릿</Label>
              <Col md={8}>
                <select
                  name={"type"}
                  value={form.type}
                  onChange={(e) => onChange(e.target.name, e.target.value)}
                  className="tp-select tp-select--small"
                >
                  <option value="">---</option>
                  <option value="REGISTERED">업무 등록 알림</option>
                  <option value="CANCELED">프로젝트 취소</option>
                  <option value="FAIL">미채용 알림</option>
                  <option value="SUCCESS">채용 알림</option>
                </select>
              </Col>
            </FormGroup>
          </div>
        </>
      )}
      {...props}
      onConfirm={() => props.onConfirm(form)}
    />
  )
};
import React from 'react'
import styled from 'styled-components'

const TabContainer = styled.div`
  display: none;
  
  @media (max-width: 934px) {
     display: flex;
     width: 100%;
     height: 52px;
     margin-bottom: 20px;
     flex-direction: row;
     justify-content: flex-start;
     align-items: stretch;
     border-radius: 4px;
     background-color: #e6eaf2;
  }
`

const TabButton = styled.button`
  flex: 1;
  font-family: Noto Sans KR;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: center;
  color: ${props => props.active ? '#ffffff' : '#999999'};
  border: none;
  border-radius: 4px;
  background-color: ${props => props.active ? '#0069ff' : 'transparent'};
  :focus {
    outline:0;
    border: none;
  }
`

export default ({ activeTab, onClickTab, tabs }) =>
  (<TabContainer>
    <TabButton active={!activeTab} onClick={() => onClickTab(false)}>
      {tabs[0]}
    </TabButton>
    <TabButton active={activeTab} onClick={() => onClickTab(true)}>
      {tabs[1]}
    </TabButton>
  </TabContainer>)

import React from "react";
import {Link} from "react-router-dom";


const HomeNewLastSection = ({ isClient, isInterpreter }) => {

  if (isClient){  
        return (
          <div>
            <section className="one-last-thing">
              <div className="inner">
                <div className="yanadoo">
                  믿고 업무를 맡기실 수 있는<br/>
                  와블의 검증된 전문가를 만나실 수 있습니다.
                </div>
                <Link to="/projects/create">
                  <button>
                    프로젝트 등록 →
                  </button>
                </Link>
              </div>
            </section>
          </div>
        )
      }
      else if (isInterpreter){  
        return (
          <div>
            <section className="one-last-thing">
              <div className="inner">
                <div className="yanadoo">
                  고객사에 신뢰를 주는<br/>
                  와블의 검증된 전문가가 될 수 있습니다.
                </div>
                <Link to="/me">
                  <button>
                    내 프로필 관리 →
                  </button>
                </Link>
              </div>
            </section>
          </div>
        )
      }
      else {  
        return (
          <div>
            <section className="one-last-thing">
              <div className="inner">
                <div className="yanadoo">
                  전문 통번역사 매칭 플랫폼 '와블'과 함께<br/>
                  성공적인 비즈니스를 이루어 보세요.
                </div>
                <Link to="/join">
                  <button>
                    이용 하기 →
                  </button>
                </Link>
              </div>
            </section>
          </div>
        )
      }
};

export default HomeNewLastSection;
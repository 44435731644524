import React from 'react'
import styled from 'styled-components'

const Col = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: stretch;
  flex-wrap: wrap;
  background-color: #ffffff;
  
  @media (max-width: 934px) {
  }
`

export default Col;


import React from "react";
import "./KakaoFloating.scss";

const KakaoFloating = () => {
  return (
    <div className="KakaoFloating">
      <a
        href="http://pf.kakao.com/_GRnsT/chat"
        rel="noopener noreferrer"
        target="_blank"
      >
        <img
          src="https://developers.kakao.com/assets/img/about/logos/plusfriend/consult_small_yellow_pc_2X.png"
          alt="플러스친구 1:1 채팅 버튼"
          title="플러스친구 1:1 채팅 버튼"
        />
      </a>
    </div>
  )
};

export default KakaoFloating;

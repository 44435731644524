import styled from "styled-components";

const Button = styled.button`
  width: 92px;
  height: 40px;
  border-radius: 4px;
  border: solid 1px #000000;
  font-family: Noto Sans KR;
  text-decoration: none;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 40px;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
  background-color: #cccccc;
  
  @media (max-width: 934px) {
    width: 100%;
    height: 44px;
    font-size: 16px;
    line-height: 44px;
  } 
`

export const PrimaryButton = styled.button`
  width: 100%;
  height: 40px;
  border-radius: 4px;
  background-color: #0069ff;
  border-width: 0;
  font-family: Noto Sans KR;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 40px;
  letter-spacing: normal;
  text-align: center;
  align-self: center;
  text-decoration: none;
  color: #ffffff; 
`


export default Button;
import React from 'react'
import styled from 'styled-components'

const format = (arg) => Number.isFinite(arg) ? `${arg}px` : arg

const Space = styled.div`
  width: ${props => format(props.dw) || 0};
  height: ${props => format(props.dh) || 0};
  
  @media (max-width: 768px) {
    width: ${props => format(props.mw) || 0};
    height: ${props => format(props.mh) || 0};
  }
`

export default Space;

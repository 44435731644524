import React from 'react'
import styled from 'styled-components'

const AddButton = styled.button`
  width: 67px;
  height: 36px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-family: Noto Sans KR;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 36px;
  letter-spacing: normal;
  text-align: center;
  color: #0069ff;
  border-radius: 4px;
  border: solid 1px #0069ff;
  background-color: #ffffff;
  padding: 0 10px; 
  :focus {outline:0;}

  @media (max-width: 934px) {
    padding: 0 10px;
  }
`
const AddImage = styled.img`
  width: 12.1px;
  height: 12.1px;
  object-fit: contain;
`
const AddTitle = styled.span`
  padding-left: 2px;
`

export default (props) => (
  <AddButton {...props}>
    <AddImage src={'/images/icon-plus-blue.svg'} />
    <AddTitle>{'추가'}</AddTitle>
  </AddButton>
)

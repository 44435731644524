import React from 'react'
import Space from '../../../atoms/Space'
import styled from 'styled-components'
import * as Utils from "../../../../utils"

const ProfileImg = styled.img`
  width: 115px;
  height: 115px;
  border-radius: 58px;
  object-fit: cover;
  padding: 2px;
  border: 1px solid #EEEEEE;
  
  @media (max-width: 934px) {
    width: 93px;
    height: 93px;
  }
`

const ProfileImageButton = styled.button`
  width: 115px;
  height: 115px;
  object-fit: contain;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: none;
  :focus {
    border: none;
    background: none;
    outline: 0;
  }
  margin-top: 60px;
  background: #ffffff;
  
  @media (max-width: 934px) {
    width: 93px;
    height: 93px;
    border-radius: 47px;
    margin-top: 30px;
  }
`

export const ProfileImage = ({ src }) => (
  <ProfileImageButton>
    <ProfileImg src={src || '/images/empty_profile.png'} />
  </ProfileImageButton>
)


export const ProfileName = styled.div`
  font-family: Noto Sans KR;
  height: 27px;
  font-size: 22px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 27px;
  letter-spacing: normal;
  text-align: center;
  color: #333333;
  margin-top: 20px;

  @media (max-width: 934px) {
    font-size: 20px;
    line-height: 24px;
  } 
`

export const ProfileJob = styled.div`
  opacity: 0.5;
  font-family: Noto Sans KR;
  font-size: 17px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;
  letter-spacing: normal;
  text-align: center;
  color: #333333;
  margin-top: 5px;
  margin-bottom: 10px;
 
  @media (max-width: 934px) {
    font-size: 16px;
    line-height: 19px;
    margin-top: 1px;
  } 
`

export const ProfileLanguages = styled.div`
  width: 260px;
  height: 39px;
  padding: 0px 14px;
  border-radius: 20px;
  background-color: #e4f0ff;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  line-height: 39px;
  cursor: pointer;
 
  @media (max-width: 934px) {
    width: 280px;
  } 
`
export const ProfileLanguageImage = styled.img`
  width: 18.2px;
  height: 18.2px;
  object-fit: contain;
  
  @media (max-width: 934px) {
  }
`
export const ProfileLanguageText = styled.span`
  margin-left: 7px;
  font-family: Noto Sans KR;
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.27;
  letter-spacing: normal;
  text-align: left;
  color: #0069ff;
 
  @media (max-width: 934px) {
    font-size: 16px;
    line-height: 19px;
  } 
`

export const ProfileComment = styled.div`
  width: 258px;
  font-family: Noto Sans KR;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: -0.3px;
  margin: 10px 10px 0px;
  text-align: left;
  color: #333333;
  overflow-wrap: break-word;

  overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 10;
	-webkit-box-orient: vertical;
	word-wrap:break-word;

 
  @media (max-width: 934px) {
    width: 300px;
    font-size: 16px;
    line-height: 25px;
    letter-spacing: -0.32px;
    margin: 10px 0px;
  } 
`

export const ProfileDivider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #dddddd;
`

const ProfileTitle = styled.div`
  font-family: Noto Sans KR;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: left;
  color: #333333;

  @media (max-width: 934px) {
  }
`

const ProfileRecords = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 25px 35px 28px;

  @media (max-width: 934px) {
    padding: 30px 55px 26px;
  }
`

const ProfileRecordTitle = styled.div`
  opacity: 0.5;
  font-family: Noto Sans KR;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: center;
  color: #333333;
  
  @media (max-width: 934px) {
  }
`

const ProfileRecordValue = styled.span`
  font-family: Montserrat;
  font-size: 28px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: center;
  color: #333333;
  
  @media (max-width: 934px) {
  }
`

const ProfileRecordValueUnit = styled.span`
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: center;
  color: #333333;
  
  @media (max-width: 934px) {
  }
`

const ProfileCareers = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0px 10px 10px 10px;

  @media (max-width: 934px) {
    padding: 0px 40px 20px 40px;
  }
`

const ProfileCareerCircle = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  align-items: flex-start;
  margin: 0px 0px 10px;
  padding: 11.7px 12.1px 12.3px 12.1px;
  background-color: #e4f0ff; 
  background-size: 60%;
  background-repeat: no-repeat;
  background-position: 50%;
  background-image: url('${(props) => props.imgUrl}');
  
  @media (max-width: 934px) {
    width: 60px;
    height: 60px;
    border-radius: 50%;
  }
`

const ProfileCareerTitle = styled.div`
  font-family: Noto Sans KR;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: left;
  margin: 0px 0px 2px;
  color: #333333;
  max-width: 188px;
  word-wrap: break-word;

  @media (max-width: 934px) {
  }
`

const ProfileCareerValue = styled.span`
  font-family: Noto Sans KR;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: center;
  color: #333333;
  
  @media (max-width: 934px) {
  }
`

const ProfileIntroduceButton = styled.button`
  width: 258px;
  height: 30px;
  margin: 9px 1px 7px;
  border-radius: 4px;
  border: solid 1px #dddddd;
  background-color: #ffffff;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  :focus {outline:0;}  
`

const ProfileIntroduceButtonValue = styled.span`
  font-family: Noto Sans KR;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
  text-align: center;
  color: #666666;
`

const Icon = styled.img`
  width: 8.7px;
  height: 4.3px;
  margin: 5.2px 0 6.5px 5.5px;
  object-fit: contain;
`

const ProfileIntroduceOpen = () => {
  if(document.getElementById('ProfileComment').style.display == 'block') {
    document.getElementById('ProfileComment').style.display = '-webkit-box';
    document.getElementById('introduceBtn').innerText = "펼치기"
    document.getElementById('introduceImg').style.transform = 'rotate(0deg)';
  } else if(document.getElementById('ProfileComment').style.display == '-webkit-box') {
    document.getElementById('ProfileComment').style.display = 'block';
    document.getElementById('introduceBtn').innerText = "접기"
    document.getElementById('introduceImg').style.transform = 'rotate(180deg)';
  }
};

export const ProfileRecordSection = ({ projectCareersLength, translateCareersLength }) => (
  <ProfileRecords>
    <Col style={{ alignItems: 'center' }}>
      <div>
        <ProfileRecordValue>{projectCareersLength}</ProfileRecordValue>
        <ProfileRecordValueUnit>{'회'}</ProfileRecordValueUnit>
      </div>
      <ProfileRecordTitle>{'통역 업무경력'}</ProfileRecordTitle>
    </Col>
    <Col style={{ alignItems: 'center' }}>
      <div>
        <ProfileRecordValue>{translateCareersLength}</ProfileRecordValue>
        <ProfileRecordValueUnit>{'회'}</ProfileRecordValueUnit>
      </div>
      <ProfileRecordTitle>{'번역 업무경력'}</ProfileRecordTitle>
    </Col>
  </ProfileRecords>
)

export const ProfileCareerSection = ({ ProfileCareerMap, Industries, industryStore }) => {
const keys = [ ...ProfileCareerMap.keys() ];
const values = [...ProfileCareerMap.values()];

  return (
    <>
      <Space dh={10} mh={10} />
      <Row>
        <ProfileTitle>{'분야별 경력 TOP3'}</ProfileTitle>
      </Row>
      <Space dh={10} mh={20} />
      <ProfileCareers>
        { [0, 1, 2].map(i => (
          <Row style={{ alignItems: 'center', padding: '0px 10px 5px 10px' }}>
            <ProfileCareerCircle imgUrl="https://wable-public.s3.ap-northeast-2.amazonaws.com/industry-icon/31-b952513c8035e58ba3a1a552bb003276.png" />
            <Space dw={10} mw={10} />
            <Col>
              <ProfileCareerTitle>{keys[i] ? Utils.industryKorean(keys[i]) : "-"}</ProfileCareerTitle>
              <ProfileCareerValue>{(values[i] || 0) + '회'}</ProfileCareerValue>
            </Col>
          </Row>
        ))}
      </ProfileCareers>
    </>
  )
}

export const ProfileIntroduceSection = ({ ProfileIntroduceContent }) => (
  <>
    <Space dh={10.5} mh={10.5} />
    <Row>
      <ProfileTitle>{'자기소개'}</ProfileTitle>
    </Row>
    <ProfileComment id='ProfileComment' style={{display: '-webkit-box', whiteSpace: 'pre-line'}}>{ProfileIntroduceContent}</ProfileComment>
    <ProfileIntroduceButton onClick={ProfileIntroduceOpen}>
      <ProfileIntroduceButtonValue id="introduceBtn">{'펼치기'}</ProfileIntroduceButtonValue>
      <Icon src="/images/icon-arrowdown.svg" id="introduceImg"/>
    </ProfileIntroduceButton>
    <Space dh={10.5} mh={10.5} />
  </>
)

const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 10px 19px;
  
  @media (max-width: 934px) {
    padding: 10px 20px
  }
`

const Col = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: stretch;
  flex-wrap: wrap;
  background-color: #ffffff;
  
  @media (max-width: 934px) {
  }
`

const ContactInfoText = styled.p`
  display:inline-block;
  opacity: 0.5;
  font-family: Noto Sans KR;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 19px;
  letter-spacing: normal;
  text-align: left;
  color: #333333; 
  white-space: normal;
  
  @media (max-width: 934px) {
    font-size: 14px;
  }
`

const ContactEmailAddress = styled.p`
  display:inline-block;
  opacity: 0.5;
  font-family: Noto Sans KR;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 19px;
  letter-spacing: normal;
  text-align: left;
  color: #333333; 
  white-space: normal;
  
  @media (max-width: 934px) {
    font-size: 14px;
  }
`

const ContactProfileLink = styled.a`
  width: 170px;
  word-break: break-all;
  opacity: 0.5;
  font-family: Noto Sans KR;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 19px;
  letter-spacing: normal;
  text-align: left;
  color: #333333; 
  white-space: normal;
  
  @media (max-width: 934px) {
    font-size: 14px;
  }
`

const ChangeProfileLinkButton = styled.button`
  position: absolute;
  right: 0px;
  width: 40px;
  height: 20px;
  border-radius: 4px;
  border: solid 1px #dddddd;
  background-color: #ffffff;
  padding: 0;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  :focus {outline:0;}
`

const EditProfileLinkButtonValue = styled.span`
  font-family: Noto Sans KR;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  vertical-align: top;
  text-align: center;
  color: #666666;
`

const ContactPhoneNumber = styled(ContactInfoText)`
  color: #0069ff;
  opacity: 1;
  margin-bottom: 4px;
`

const formatPhone = (phone) => `${phone.slice(0, 3)}-${phone.slice(3, -4)}-${phone.slice(-4)}`

export const ContactInfoSection = ({ me, phone, email, resumeUrl, onClickAdd }) => (
  <>
    <Space dh={10} mh={10} />
    <Row>
      <Space dw={11} />
      <Row style={{ width: 23, justifyContent: 'center', marginRight: 12, padding: 0 }}>
        <img style={{
          width: 13,
          height: 21.7,
        }}
          src={'/images/icon-smartphone.svg'} />
      </Row>
      <Col style={{ flex: 1 }}>
        {
          phone && (
            <>
              <ContactPhoneNumber>{formatPhone(phone)}</ContactPhoneNumber>
              { me && ( <ContactInfoText style={{ fontSize: 13 }}>{"채용시 고객사에 노출 됨"}</ContactInfoText> )}
            </>
          )
        }
        {
          !phone && (
            <>
              <ContactPhoneNumber>{'채용 확정 후 공개'}</ContactPhoneNumber>
            </>
          )
        }
      </Col>
    </Row>
    <Row>
      <Space dw={11} />
      <Row style={{ width: 23, justifyContent: 'center', marginLeft: 1, marginRight: 14, padding: 0 }}>
        <img style={{
          width: 20,
          height: 20,
          }}
        src={'/images/icon-email.svg'} />
      </Row>
      <Col style={{ flex: 1 }}>
        {
          email && (
            <>
              <ContactEmailAddress>{email}</ContactEmailAddress>
              { me && ( <ContactInfoText style={{ fontSize: 13 }}>{"채용시 고객사에 노출 됨"}</ContactInfoText> )}
            </>
          )
        }
        {
          !email && (
            <>
              <ContactPhoneNumber>{'채용 확정 후 공개'}</ContactPhoneNumber>
            </>
          )
        }
      </Col>
    </Row>
    {
      resumeUrl && (
        <Row>
          <Space dw={11} />
          <Row style={{ width: 23, justifyContent: 'center', marginRight: 12, padding: 0 }}>
            <img style={{
              width: 23,
              height: 20.6,
              }}
            src={'/images/icon-web.svg'} />
          </Row>
          <Col style={{ flex: 1 }}>
            <Row style={{ padding: '0' }}>
              <ContactProfileLink href={me ? window.location.href : resumeUrl}>{resumeUrl}</ContactProfileLink>
            </Row>
          </Col>
        </Row>
      )
    }
    <Space dh={7.6} mh={15.5} />
  </>
)

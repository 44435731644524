import React from 'react'
import styled from 'styled-components'

const Explain = styled.p`
  font-family: Montserrat;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  text-align: left;
  color: #333333;

  @media (max-width: 934px) {
    font-size: 14px;
  }
`

export default Explain;

import {observable, set} from "mobx";

class Member {

  @observable idx;
  @observable type;
  @observable status;
  @observable id;
  @observable email;
  @observable profileId;
  @observable name;
  @observable phone;
  @observable gender;
  @observable rating;
  @observable photoUrl;
  @observable companyName;
  @observable companyType;
  @observable companyNumber;
  @observable taxEmail;
  @observable notiAd;
  @observable notiEmail;
  @observable notiKakao;
  @observable certificationUrl;
  @observable idCardUrl;
  @observable bankUrl;
  @observable createDate;
  @observable updateDate;
  @observable lastLoginDate;
  @observable languages;
  @observable certifications;
  @observable educations;
  @observable companyCareers;
  @observable projectCareers;
  @observable translateCareers;
  @observable fields;

  constructor(props) {
    set(this, props);

    this.languages = this.languages || [];
    this.certifications = this.certifications || [];
    this.educations = this.educations || [];
    this.companyCareers = this.companyCareers || [];
    this.projectCareers = this.projectCareers || [];
    this.translateCareers = this.translateCareers || [];
    this.fields = this.fields || [];
  }

  get isInterpreter() {
    return this.type && this.type === "INTERPRETER"
  }

  get isClient() {
    return this.type && this.type === "CLIENT"
  }
}

export default Member;
import React from "react";
import "./Footer.scss";

const Footer = () => (
  <div className="Footer">
    <div className="inner">
      <div className="info company-info">
        <span>© 2023. muybien Co.,Ltd. All Rights Reserved.</span>
      </div>
      <div className="info">
        <ul>
          <li>회사명: 무이비엔(주)</li>
          {/* <li>대표: 김재선</li> */}
          <li>주소: 서울특별시 영등포구 당산로41길 11, 4층</li>
          <li>사업자등록번호: 688-87-03206</li>
          {/*<li>통신판매업등록번호: 제2020-서울마포-3634호</li>*/}
        </ul>
      <div className="info">
        <ul>
          <li>
            대표번호: 070-8064-2554 (오전 9시 ~ 오후 7시)
          </li>
          <li>
            카카오톡: @wable
          </li>
          <li>
            이메일: cs@wable.io
          </li>
        </ul>
      </div>
      </div>
      <div className="info">
        <a
          className="color-dark-grey"
          href="https://www.notion.so/6756045d65eb494fa3832c50aeea3e83"
          rel="noopener noreferrer"
          target="_blank"
        >
          서비스 이용약관
        </a>
        {" "}
        <a
          className="color-dark-grey"
          href="https://www.notion.so/6d9c10d0223c4509863479c2daae399d"
          rel="noopener noreferrer"
          target="_blank"
        >
          개인정보보호정책
        </a>
      </div>
      <div className="bi">
        <img src="/images/wable-bi.png" srcSet="/images/wable-bi.png, /images/wable-bi@2x.png 2x" />
      </div>
    </div>
  </div>
);

export default Footer;
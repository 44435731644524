import React, {useEffect, useMemo, useState} from "react";
import axios from "axios";
import _ from "lodash";
import {Table,} from 'antd';
import {industryStatusKorean, serviceTypeKorean} from "../../../utils";
import {Col, Row} from "reactstrap";
import Button from "../Admin/components/Button";
import IndustryEditModal from "./IndustryEditModal";
import Radio from "../InterpreterProfile/components/Radio";
import {inject, observer} from "mobx-react";

const { Column, } = Table;

const AdminIndustryList = ({memberStore, history}) => {
  const [ results, setResults ] = useState([]);
  const [ selectedServiceType, setSelectedServiceType ] = useState("ALL")
  const [ selectedIndustry, setSelectedIndustry ] = useState(undefined)

  const { id } = memberStore.member;
  if(id !== "sprofile616@naver.com" && id !== "interpreter2@naver.com") {
    window.alert("비정상적인 접근입니다.")
    memberStore.logout()
    window.location.href = "/login"
  }

  useEffect(() => {
    load()
  }, []);

  const displayIndustries = useMemo(() => {
    if(selectedServiceType === "ALL")
      return results;

    return _.filter(results, { industryType: selectedServiceType })
  }, [results, selectedServiceType])

  const load = () => {
    axios.get(`/api/admin/industries`).then(res => {
      const items = res.data;
      setResults(items);
    })
  }

  const onAddClick = () => {
    setSelectedIndustry({})
  }

  const onClickSave = (form) => {
    axios.post(`/api/admin/industries`, form).then(() => {
      setSelectedIndustry(undefined)
      setTimeout(() => {
        window.alert("저장되었습니다.")
        load()
      }, 1)
    }).catch((err) => {
      console.log(err)
      window.alert("저장 실패")
    })
  }

  const onClickRow = (record) => {
    setSelectedIndustry(record)
  }

  return (
    <>
      <div className="admin-contents">
        <Row>
          <Col md={10}>
            <Row>
              <Col md={4} style={{ display: "flex", "justifyContent": "space-between" }}>
                <Radio
                  isChecked={selectedServiceType === "ALL"}
                  onChange={_ => setSelectedServiceType("ALL")}
                >
                  전체
                </Radio>
                <Radio
                  isChecked={selectedServiceType === "INTERPRET"}
                  onChange={_ => setSelectedServiceType("INTERPRET")}
                >
                  통역
                </Radio>
                <Radio
                  isChecked={selectedServiceType === "TRANSLATE"}
                  onChange={_ => setSelectedServiceType("TRANSLATE")}
                >
                  번역
                </Radio>
              </Col>
            </Row>
          </Col>
          <Col md={2} style={{
            "display": "flex",
            "flexDirection": "column",
            "justifyContent": "flex-end"}}>
            <Button onClick={onAddClick} style={{width: "100%"}}>분야 추가</Button>
          </Col>
        </Row>
        <Table
          dataSource={displayIndustries}
          size="small"
          pagination={{ pageSize: 15 }}
          className="mt-5"
          onRow={(record, rowIndex) => {
            return {
              onClick: () => onClickRow(record, rowIndex),
            };
          }}
        >
          <Column title="" dataIndex="idx" key="idx" />
          <Column
            title="분야종류"
            key="industryType"
            render={(_, record) => serviceTypeKorean(record.industryType)}
          />
          <Column title="분야" dataIndex="industryName" key="industryName" />
          <Column title="영문" dataIndex="industryKey" key="industryKey" />
          <Column
            title="이미지"
            key="imgUrl"
            render={(_, record) => (<img src={record.imgUrl} style={{ width: "2rem" }} />)}
          />
          <Column
            title="상태"
            key="status"
            render={(_, record) => industryStatusKorean(record.status)}
          />
        </Table>
      </div>
      { selectedIndustry && (
        <IndustryEditModal
          title={"분야 추가"}
          isOpen={true}
          industry={selectedIndustry}
          toggle={() => setSelectedIndustry(undefined)}
          onClickSave={onClickSave}
        />
      )}
    </>
  )
};

export default inject(stores => ({
  memberStore: stores.memberStore
}))(observer(AdminIndustryList));

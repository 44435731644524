import React from "react";
import "./CircleBorderButton.scss";

const CircleBorderButton = ({children, active, style, className}) => {
  return (
    <div
      className={`CircleBorderButton ${className || ""} ${active ? " active" : ""}`}
      style={style}
    >
      {children}
    </div>
  )
};

export default CircleBorderButton;
import React, {useEffect, useState} from "react";
import axios from "axios";
import _ from "lodash";
import * as Utils from "../../../utils";
import * as Util from "../../../utils";
import MajorCareerSection from './components/MajorCareerSection';
import CareerSection2 from './components/CareerSection2';
import EducationSection from './components/EducationSection';
import CompanyCareerSection from './components/CompanyCareerSection';
import moment from 'moment';
import {
  CareerBox,
  CareerContainer,
  ContactInfoSection,
  Container,
  ProfileCareerSection,
  ProfileContainer,
  ProfileDivider,
  ProfileImage,
  ProfileIntroduceSection,
  ProfileJob,
  ProfileLanguageImage,
  ProfileLanguages,
  ProfileLanguageText,
  ProfileName,
  ProfileRecordSection,
  ShowAllButton,
  Wrapper
} from "./components";
import {inject, observer} from "mobx-react";
import {endpoint} from "../../../App";

const ProfileViewOnly = (props) => {

  const [interpreter, setInterpreter] = useState({
    certifications: [],
    companyCareers: [],
    companyName: null,
    educations: [],
    email: "",
    id: "",
    idx: null,
    introduce: "",
    languages: [],
    name: "",
    phone: "",
    photoUrl: "",
    profileId: "",
    projectCareers: [],
    status: "",
    translateCareers: [],
    type: "",
  });
  const [showAllTranslate, setShowAllTranslate] = useState(false);
  const [showAllInterpret, setShowAllInterpret] = useState(false);
  const [showAllCertification, setShowAllCertification] = useState(false);
  const defaultSize = 5;
  const [summaryInterpret, setSummaryInterpret] = useState([]);
  const [summaryTranslate, setSummaryTranslate] = useState([]);
  const [summaryCertification, setSummaryCertification] = useState([]);
  const [summaryMajorCareer, setSummaryMajorCareer] = useState([]);
  const [CareerMapSorted, setCareerMapSorted] = useState([]);
  const [languages, setLanguages] = useState([]);

  const host = `${endpoint}profile`

  useEffect(async ()=> {
    props.industryStore.load();

    let  profileId  = props.match.params.profileId;
    if (profileId && _.startsWith(profileId, "@")) {
      profileId = profileId.replace("@", "");
    }
    await axios.get(`/api/interpreters/profile/${profileId}`).then(res => {
      const obj = res.data;
      setInterpreter(obj);
    });
  },[]);

  useEffect(()=>{
    setSummaryInterpret(interpreter.projectCareers && (showAllInterpret ? interpreter.projectCareers : _.take(interpreter.projectCareers, defaultSize)));
  },[showAllInterpret]);

  useEffect(()=>{
    setSummaryTranslate(interpreter.translateCareers && (showAllTranslate ? interpreter.translateCareers : _.take(interpreter.translateCareers, defaultSize)));
  },[showAllTranslate]);

  useEffect(()=>{
    setSummaryCertification(interpreter.certifications && (showAllCertification ? interpreter.certifications : _.take(interpreter.certifications, defaultSize)))
  },[showAllCertification]);


  useEffect(()=> {
    
    setSummaryInterpret(interpreter.projectCareers && (showAllInterpret ? interpreter.projectCareers : _.take(interpreter.projectCareers, defaultSize)))
    
    setSummaryTranslate(interpreter.translateCareers && (showAllTranslate ? interpreter.translateCareers : _.take(interpreter.translateCareers, defaultSize)));
    
    setSummaryCertification(interpreter.certifications && (showAllCertification ? interpreter.certifications : _.take(interpreter.certifications, defaultSize)))

    const MajorInterpretCareer = interpreter.projectCareers.filter(item => item.selectedCareer == true);
    const MajorTranslateCareer = interpreter.translateCareers.filter(item => item.selectedCareer == true);
    setSummaryMajorCareer(MajorInterpretCareer.concat(MajorTranslateCareer));

    // 분야별 경력 TOP3 변수 정의
    const CareerElement = [];
  
    for(let i = 0; i < interpreter.projectCareers.length; i++) {
      if(interpreter.projectCareers[i].industry) {
        for(var item of interpreter.projectCareers[i].industry.split(','))
          CareerElement.push(item);
      }
    }
    for(let i = 0; i < interpreter.translateCareers.length; i++) {
      if(interpreter.translateCareers[i].industry) {
        for(var item of interpreter.translateCareers[i].industry.split(','))
          CareerElement.push(item);
      }
    }
  
    const CareerMap = CareerElement.reduce((accu,curr)=> {
      accu.set(curr, (accu.get(curr)||0) +1) ;
      return accu;
    },new Map());

    setCareerMapSorted(new Map([...CareerMap.entries()].sort((a, b) => b[1] - a[1])));

    const lang = {...interpreter.languages[0]};
    lang.native && setLanguages((languages) => [...languages, lang.native]);
    lang.language1 && setLanguages((languages) => [...languages, lang.language1]);
    lang.language2 && setLanguages((languages) => [ ...languages, lang.language2]);

  }, [interpreter])

  const industryToKorean = (industryIdx) => {
    const industries = props.industryStore.allIndustries;
    const industry =  _.find(industries, i => (i.idx == industryIdx))
    return industry && industry.industryName || ""
  }


  return(
    <Container>
      <Wrapper>
        {/* <Tab
          activeTab={this.modal.tab.get()}
          onClickTab={this.flipTab}
          tabs={['프로필', '업무경력']} /> */}
        <ProfileContainer active={true}>
          <ProfileImage
            src={interpreter.photoUrl}
          />
          <ProfileName>{interpreter.name}</ProfileName>
          <ProfileJob>{interpreter.languages.length >= 0 ?
            `통번역사` : '등록 안됨'
          }</ProfileJob>
          {
            interpreter.languages.length > 0 && (
              <ProfileLanguages>
                <ProfileLanguageImage src={'/images/chat.svg'} />
                <ProfileLanguageText>
                  {
                    languages.map(lang => Utils.languageKorean(lang)).join(', ')
                  }
                </ProfileLanguageText>
              </ProfileLanguages>
            )
          }
          <ProfileRecordSection
            projectCareersLength={interpreter.projectCareers ? interpreter.projectCareers.length : 0}
            translateCareersLength={interpreter.translateCareers ? interpreter.translateCareers.length : 0} />
          <ProfileDivider />
          <ProfileCareerSection
            industryStore={props.industryStore}
            ProfileCareerMap={CareerMapSorted}
          />
          <ProfileDivider />
          <ContactInfoSection 
            phone={interpreter.phone}
            email={interpreter.email}
            resumeUrl={`${host}/@${interpreter.profileId}`}
            />
          <ProfileDivider />
          <ProfileIntroduceSection
            ProfileIntroduceContent={interpreter.introduce}/>
        </ProfileContainer>
        <CareerContainer active={true}>
          <CareerBox>
            <MajorCareerSection
              title={"Major Career"}
              items={summaryMajorCareer.map(project => ({
                id: project.idx,
                title: project.name,
                type: project.interpretType != undefined ? "INTERPRET" : "TRANSLATE",
                period: `${moment(project.startDate).format("YYYY-MM")}`,
                company: project.company,
                language: project.interpretType != undefined ? `${Utils.languageKorean(project.language)}` : project.targetLanguage.split('/').map(item => Util.languageKorean(item)).join('\n'),
                industry: project.industry.split(',').map(item => Util.industryKorean(item)).join(', '),
                jobType: project.interpretType != undefined ? Util.interpretTypeKorean(project.interpretType) : '',
              }))}
            />
          </CareerBox>
          <CareerBox>
            <EducationSection
              title={"Education"}
              items={interpreter.educations}
            />
          </CareerBox>
          <CareerBox>
            <CompanyCareerSection
              title={"In-house Career"}
              items={interpreter.companyCareers}
            />
          </CareerBox>
          <CareerBox>
            <CareerSection2
              title={"Work Experience - Interpretation"}
              icon={"/images/headphones-black.svg"}
              desc={"통역 업무"}
              count={interpreter.projectCareers.length}
              items={summaryInterpret.map(project => ({
                id: project.idx,
                title: project.name,
                period: `${moment(project.startDate).format("YYYY-MM")}`,
                company: project.company,
                language: Utils.languageKorean(project.language),
                industry: project.industry.split(',').map(item => industryToKorean(item)).join(', '),
                jobType: Util.interpretTypeKorean(project.interpretType),
              }))}
            />
            {
              interpreter.projectCareers.length > defaultSize && (
                <ShowAllButton
                  showAll={showAllInterpret}
                  onClick={(value)=>{setShowAllInterpret(value)}}
                />
              )
            }
          </CareerBox>
          <CareerBox>
            <CareerSection2
              title={"Work Experience - Translation"}
              icon={"/images/translate-black.svg"}
              desc={"번역 업무"}
              count={interpreter.translateCareers.length}
              items={summaryTranslate.map(project => ({
                id: project.idx,
                title: project.name,
                period: `${moment(project.startDate).format("YYYY-MM")}`,
                company: project.company,
                language: project.targetLanguage.split('/').map(item => Util.languageKorean(item)).join('\n'),
                industry: project.industry.split(',').map(item => industryToKorean(item)).join(', '),
                jobType: '',
              }))}
            />
            {
              interpreter.translateCareers.length > defaultSize && (
                <ShowAllButton
                  showAll={showAllTranslate}
                  onClick={(value)=>{setShowAllTranslate(value)}} 
                />
              )
            }
          </CareerBox>
          <CareerBox>
            <CareerSection2
              title={"Certificate"}
              icon={"/images/translate-black.svg"}
              desc={"자격증"}
              count={interpreter.certifications.length}
              items={summaryCertification.map(project => ({
                id: project.idx,
                title: project.name,
                period: `${moment(project.startDate).format("YYYY-MM")}`,
                company: project.company,
                language: `${Utils.languageKorean(project.language)}`,
                industry: project.industry ? project.industry.split(',').map(item => industryToKorean(item)).join(', ') : industryToKorean(project.industry),
                jobType: '',
              }))}
            />
            {
              interpreter.certifications.length > defaultSize && (
                <ShowAllButton
                  showAll={showAllCertification}
                  onClick={(value)=>{setShowAllCertification(value)}} 
                />
              )
            }
          </CareerBox>
        </CareerContainer>
      </Wrapper>
    </Container>
  )
};

export default inject("industryStore")(observer(ProfileViewOnly));
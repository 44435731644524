import React from 'react'
import styled from 'styled-components'
import Row from '../../../atoms/Row'
import Industries from "../../../../values/Industries";
import _ from "lodash";

const IndustryCNTR = styled(Row)`
  height: auto;
  width: 443px;
`

const IndustryBox = styled.div`
  display: none;
  position: relative;
  width: auto;
  height: 50px;
  margin-right: 10px;
  margin-bottom: 5px;
  padding: 16px 20px;
  border-radius: 4px;
  background-color: #f2f3f6;
  align-items: center;

  font-family: Noto Sans KR;
  font-weight: bold;
  font-size: 15px;
  color: #333333;
  text-align: left;
  line-height: 1.27;

  @media (max-width: 934px) {
    height: 45px;
    font-size: 14px;
    padding: 13px 20px;
  }
`

const IndustryText = styled.label`
  position: relative;
  font-family: Noto Sans KR;
  font-size: 15px;
  font-weight: bold;
  color: #333333;
  text-align: left;
  line-height: 1.27;
  margin: 0;
  margin-right: 15px;


  @media (max-width: 934px) {
    font-size: 13px;
  }
`

const CancelImg = styled.img`
  position: relative;
  width: 12px;
  height: 12px;
  cursor: pointer;
`


export const IndustryContainer = ({industries, industry, DeleteIndustry}) => {

  var tempIndustry = (industry || '').split(',');
  if(tempIndustry[0] == "") tempIndustry.shift();

  setTimeout(function() {
    if(tempIndustry.length > 0) {
      for(var i = 1; i <= tempIndustry.length; i++) {
        const idx = tempIndustry[i-1];
        const industry = _.find(industries, i => (i.idx == idx))
        document.getElementById("Industry" + i).style.display = "flex";
        document.getElementById("IndustryText" + i).innerText = industry.industryName;
      }
    }
  }, 1)

  return (
    <IndustryCNTR id="IndustryConatiner">
        <IndustryBox id='Industry1'>
            <IndustryText id="IndustryText1"></IndustryText>
            <CancelImg src={'/images/icon-closed.svg'} value="1" onClick={DeleteIndustry}></CancelImg>
        </IndustryBox>
        <IndustryBox id='Industry2'>
            <IndustryText id="IndustryText2"></IndustryText>
            <CancelImg src={'/images/icon-closed.svg'} value="2" onClick={DeleteIndustry}></CancelImg>
        </IndustryBox>
        <IndustryBox id='Industry3'>
            <IndustryText id="IndustryText3"></IndustryText>
            <CancelImg src={'/images/icon-closed.svg'} value="3" onClick={DeleteIndustry}></CancelImg>
        </IndustryBox>
    </IndustryCNTR> 
)}

export default IndustryContainer;
import React from "react";
import {Col, Row} from "reactstrap";
import Input from "./components/Input";

const AdminForm = ({ data, inputs, onChangeInput, editMode }) => {

  const renderFormItem = (inputData, index) => {
    return (
      <>
        <Col md={2} className="mt-3">
          { inputData.label }
          { inputData.required && (
            <span style={{ color: "red", fontWeight: "bold" }}> *</span>
          )}
        </Col>
        <Col md={inputData.expand ? 10 : 4} className="mt-3">
          {renderInput(inputData, editMode, index)}
        </Col>
      </>
    )
  }

  const renderInput = (inputData, editMode, index) => {
    const { type } = inputData;
    switch (type) {
      case "text": return renderTextInput(inputData, editMode)
      case "select": return renderSelectInput(inputData, editMode)
      case "static": return renderStaticInput(inputData, editMode)
      case "custom": return renderCustomInput(inputData, editMode, index)
      case "blank": return renderBlank()
      default: return null
    }
  }

  const renderStaticInput= (inputData, editMode) => {
    const value = inputData.getValueFunc
      ? inputData.getValueFunc(data)
      : data[inputData.dataIndex]
    return (
      <Input
        name={inputData.dataIndex}
        value={ value }
        readOnly
      />)
  }

  const renderTextInput = (inputData, editMode) => {
    const value = (!editMode && inputData.getValueFunc)
      ? inputData.getValueFunc(data)
      : data[inputData.dataIndex]
    return (
      <Input
        name={inputData.dataIndex}
        value={value}
        readOnly={!editMode}
        placeholder={inputData.placeholder}
        onChange={(e) => {
          const { name, value } = e.target;
          onChangeInput(name, value)
        }}
      />
    )
  }

  const renderSelectInput = (inputData, editMode) => {
    return (
      <select
        name={inputData.dataIndex}
        value={data[inputData.dataIndex]}
        className={"tp-select tp-select--small" + (editMode ? "" : " tp-select-readonly")}
        disabled={!editMode}
        onChange={(e) => {
          const { name, value } = e.target;
          onChangeInput(name, value)
        }}
      >
        <option value="">---</option>
        { inputData.options.map(option => (
          <option key={option.value} value={option.value}>{option.text}</option>
        ))}
      </select>
    )
  }

  const renderCustomInput = (inputData, editMode, index) => {
    const { render } = inputData;
    return render(data, editMode, index)
  }

  const renderBlank = () => {
    return null
  }

  return (
    <Row className="mt-3">
      { inputs.map((inputData, i) => renderFormItem(inputData, i)) }
    </Row>
  )
}

export default AdminForm;
import React from "react";
import {useHistory, useLocation} from "react-router-dom";
import {inject, observer} from "mobx-react"

const NO_NEED_TO_LOGIN = {
  '/': true,
  '/login': true,
  '/join': true,
  '/find-account': true,
  '/welcome': true,
  '/about': true,
  '/about-translate': true,
  '/about-hire': true,
  '/pricing': true,
  '/businessenglish': true,
  '/request-quote': true,
};

const AuthDaemon = ({memberStore}) => {
  const location = useLocation();
  const history = useHistory();
  const { member, logout } = memberStore;
  React.useEffect(() => {
    if(location.pathname.split("/")[1] === "profile") {
      return;
    }
    if(NO_NEED_TO_LOGIN[location.pathname])
      return;

    if(location.pathname.startsWith("/@"))
      return;

    if (member === null) {
      window.location.href = `/login?redirect=${location.pathname}`;
    }
  }, [location, member]);

  return null;
};

export default inject(stores => ({
  memberStore: stores.memberStore
}))(observer(AuthDaemon));

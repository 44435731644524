export const INTERPRETE_ETC = [
  { name: "바우처 이용 방식", desc: "장기간동안 지속적으로 서비스를 이용하길 원하는 경우, 미리 이용대금을 충전하고 차감하며 서비스를 이용할 수 있습니다.\n * 대량우선결제시, 추가 할인이 적용됩니다.\n * 잔액분에 대하여 환불 가능" },
  { name: "장비대여", desc: "통역사매칭은 물론, 부스설치와 오디오장비 대여까지 한번에 진행하실 수 있습니다. \n 한번에 의뢰부터 정산까지 가능하여 편리하게 업무를 준비하실 수 있습니다."},  
  
];

export const TRANSLATE_ETC = [
    { name: "영상자막", desc: "영상 번역의 경우, 자막 편집까지 한번에 진행하실 수 있습니다.\n 한번에 의뢰부터 정산까지 가능하여 편리하게 업무를 준비하실 수 있습니다.\n * 견적은 별도 문의" },
  { name: "웹툰식자", desc: "웹툰 번역의 경우, 식자 작업까지 한번에 진행하실 수 있습니다.\n 한번에 의뢰부터 정산까지 가능하여 편리하게 업무를 준비하실 수 있습니다.\n * 견적은 별도 문의" },
  
];

export const EXTRA_ETC = [
  { name: "바우처 이용 방식", desc: "장기간동안 지속적으로 서비스를 이용하길 원하는 경우, 미리 이용대금을 충전하고 차감하며 서비스를 이용할 수 있습니다.\n * 대량우선결제시, 추가 할인이 적용됩니다.\n * 잔액분에 대하여 환불 가능" },
  { name: "장비대여", desc: "통역사매칭은 물론, 부스설치와 오디오장비 대여까지 한번에 진행하실 수 있습니다. \n 한번에 의뢰부터 정산까지 가능하여 편리하게 업무를 준비하실 수 있습니다."},
  { name: "영상자막", desc: "영상 번역의 경우, 자막 편집까지 한번에 진행하실 수 있습니다.\n 한번에 의뢰부터 정산까지 가능하여 편리하게 업무를 준비하실 수 있습니다.\n * 견적은 별도 문의" },
  { name: "웹툰식자", desc: "웹툰 번역의 경우, 식자 작업까지 한번에 진행하실 수 있습니다.\n 한번에 의뢰부터 정산까지 가능하여 편리하게 업무를 준비하실 수 있습니다.\n * 견적은 별도 문의" },
  
];

export const PRICING_ETC = [
  { name: "통역시간", desc: "중식 1시간을 제외한 모든 시간(휴식 시간 포함)을 포함하며, 처음 시작부터 끝나는 시간을 말합니다.\n * 점심시간에도 통역할 경우, 통역 시간에 포함\n * 중식은 최대 1시간까지 제외 가능" },
  { name: "리허설", desc: "회의 당일 리허설을 하면 리허설부터 통역 시간으로 산정됩니다. \n회의 당일이 아닌 경우는 통역료와 같은 요율이 적용됩니다."},
  { name: "취소 보상비", desc: "통역 계약 체결 후 취소 시에는 취소 보상비가 청구되며, 4~7일 전 30%, 2~3일 전 40%, 전날 취소 시 50%, 당일 취소 시 100% 청구됩니다." },
  { name: "MC 진행", desc: "통역 비용 외 500,000원/일(vat별도)의 비용이 추가됩니다." },
  { name: "사전회의료", desc: "1인 1시간당 ￦100,000(vat별도)이 청구되며, 서울과 정부 과천청사 외의 지역은 사전회의료와 별도로 국내 및 해외 이동보상비가 추가로 청구됩니다.\n회의자료는 주최측에서 일주일 전에 통역사에게 E-mail, 택배서비스 등으로 전달하여야 합니다. 통역 당일 진행되는 사전회의는 통역시간에 포함됩니다." },
  { name: "녹음과 방송", desc: "통역내용의 녹음과 방송은 사전에 통역사와 합의할 경우에만 가능합니다.\n녹음시에는 통역료의 50% (열람용 자료로 사용시 100%), 방송시에는 100%를 추가합니다." }
];

export const HIRE_ETC = [
  { name: "근무시간", desc: "중식 1시간을 제외한 모든 시간(휴식 시간 포함)을 포함하며, 사내 규정에 따릅니다. 자율출퇴근 혹은 선택근로제의 경우 조율이 가능합니다." },
  { name: "사내복지", desc: "회의 당일 리허설을 하면 리허설부터 통역 시간으로 산정됩니다. \n회의 당일이 아닌 경우는 통역료와 같은 요율이 적용됩니다."},
  { name: "취소 보상비", desc: "통역 계약 체결 후 취소 시에는 취소 보상비가 청구되며, 4~7일 전 30%, 2~3일 전 40%, 전날 취소 시 50%, 당일 취소 시 100% 청구됩니다." },
  { name: "MC 진행", desc: "통역 비용 외 500,000원/일(vat별도)의 비용이 추가됩니다." },
  { name: "사전회의료", desc: "1인 1시간당 ￦100,000(vat별도)이 청구되며, 서울과 정부 과천청사 외의 지역은 사전회의료와 별도로 국내 및 해외 이동보상비가 추가로 청구됩니다.\n회의자료는 주최측에서 일주일 전에 통역사에게 E-mail, 택배서비스 등으로 전달하여야 합니다. 통역 당일 진행되는 사전회의는 통역시간에 포함됩니다." },
  { name: "녹음과 방송", desc: "통역내용의 녹음과 방송은 사전에 통역사와 합의할 경우에만 가능합니다.\n녹음시에는 통역료의 50% (열람용 자료로 사용시 100%), 방송시에는 100%를 추가합니다." }
];

export const TRANSLATEPRICE_ETC = [
  { name: "난이도", desc: "해당 분야에 대한 전공지식이 필요한 수준의 난이도 높은 번역의 경우, 번역 요율이 조정될 수 있습니다." },
  { name: "목적", desc: "단순참고용, 내부공유용, 외부공유용, 출판 및 판매용 등 목적에 따라 요율이 조정될 수 있습니다. \n * 중요도가 낮은 문서의 경우, 낮은 요율로 진행되기 위함입니다."},
  { name: "마감기한", desc: "긴급한 번역 요청의 경우, 할증이 붙을 수 있습니다." },
  
];

export const BUSINESSENGLISH_ETC = [
  { name: "통역시간", desc: "중식 1시간을 제외한 모든 시간(휴식 시간 포함)을 포함하며, 처음 시작부터 끝나는 시간을 말합니다.\n * 점심시간에도 통역할 경우, 통역 시간에 포함\n * 중식은 최대 1시간까지 제외 가능" },
  { name: "리허설", desc: "회의 당일 리허설을 하면 리허설부터 통역 시간으로 산정됩니다. \n회의 당일이 아닌 경우는 통역료와 같은 요율이 적용됩니다."},
  { name: "취소 보상비", desc: "통역 계약 체결 후 취소 시에는 취소 보상비가 청구되며, 4~7일 전 30%, 2~3일 전 40%, 전날 취소 시 50%, 당일 취소 시 100% 청구됩니다." },
  { name: "MC 진행", desc: "통역 비용 외 500,000원/일(vat별도)의 비용이 추가됩니다." },
  { name: "사전회의료", desc: "1인 1시간당 ￦100,000(vat별도)이 청구되며, 서울과 정부 과천청사 외의 지역은 사전회의료와 별도로 국내 및 해외 이동보상비가 추가로 청구됩니다.\n회의자료는 주최측에서 일주일 전에 통역사에게 E-mail, 택배서비스 등으로 전달하여야 합니다. 통역 당일 진행되는 사전회의는 통역시간에 포함됩니다." },
  { name: "녹음과 방송", desc: "통역내용의 녹음과 방송은 사전에 통역사와 합의할 경우에만 가능합니다.\n녹음시에는 통역료의 50% (열람용 자료로 사용시 100%), 방송시에는 100%를 추가합니다." }
];
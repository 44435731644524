import React, {useEffect, useState} from 'react';

export const FadeContext = React.createContext({
  refList: [],
  addRef: ()=>{},
  fadeInfo: new Map(),
});


export function FadeProvider ({children}) {
  const [refList, setRefList] = useState([]);
  const [fadeInfo, setFadeInfo] = useState(new Map());
  const [scrollY, setScrollY] = useState(0);
  const [height, setHeight] = useState(0);

  const addRef = (ref) =>{
    setRefList(list => [...list, ref]);
  }

  const isUnderViewport = (element, offset = 50) => {
    const {top} = element.getBoundingClientRect();

    return top > height - offset;
  }

  useEffect(() => {
    refList.forEach((ref) => {
      if (isUnderViewport(ref.current)) {
        setFadeInfo(prev => new Map([...prev, [ref, false]]));
      } else {
        setFadeInfo(prev => new Map([...prev, [ref, true]]));
      }
    })
  }, [scrollY])

  const handleScroll = () => {
    setScrollY(window.scrollY)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    setHeight(window.innerHeight);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    }
  }, []);

  
  return <FadeContext.Provider value={{refList, addRef, fadeInfo}}>
    {children}
  </FadeContext.Provider>
}

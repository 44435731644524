import React from 'react'
import styled from 'styled-components'

const Label = styled.label`
  width: 125px;
  height: auto;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  text-align: left;
  color: #333333;
  
  margin-bottom: 0px;

  @media (max-width: 934px) {
    font-size: 16px;
    margin-right: 5px;
  }
`

export default Label;

